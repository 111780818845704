import React, {useState, useContext} from 'react'
import {useHistory} from "react-router-dom"
import styled from "styled-components"
import {FaPencilAlt} from "react-icons/fa"
import UserService from "../../services/userService"
import {handleErr} from "../../helpers/handleErr"
import { PractitionerDashboardContext } from './PractitionerDashboardContext'

export const InfoDiv = styled.div`
display: flex;
overflow: hidden;
justify-content: space-between;
background: white;
border-radius: 10px;
margin-bottom: 10px;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

`
export const PersonalP = styled.p`
font-size: 18px;
padding: 0.5rem;
width: 85%; 
`
export const PersonalLabel = styled.h1`
font-size: 18px;
display: inline;
margin-bottom: 5px;
margin-left: 4px;
margin-bottom: 4px;
`


export const EditIcon = styled(FaPencilAlt)`
color: black;
margin-top: 10px;
margin-right: 10px;
opacity: 0;

${InfoDiv}:hover & {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

&:hover {
    cursor: pointer;
}

`

export const FormInput = styled.input`
border:none;
height: 100%; 
outline: none;
margin-left: 5px;
background: #f3f3f3;
font-size: 18px;
border-radius: 10px;
padding-left: 10px;

`

export const Button = styled.button`
outline: none;
border: none;
padding: 0.3rem;
border-radius: 10px;
color: ${props => props.save ? 'white' : 'black'};
background: ${props => props.save ? '#63a9ff' : '#f8f8f8'};
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

&:hover {
    cursor: pointer;
}
`

export const BtnWrap = styled.div`
align-items: center;
display: flex;
width: 100px;
justify-content: space-evenly;
`


export const Error = styled.p`
font-size: 16px;
margin-bottom: 1rem;

color: red;

`

export const PersonalInfoCard = ({title, name, txt, immutable, children, isValid, maxLength}) => {
    const [value, setValue] = useState(txt);
    const [editing, setEditing] = useState(false);
    const [msg, setMsg] = useState('')
    const {getPracInfo, setLoading} = useContext(PractitionerDashboardContext)

    const handleChange = (e) => {
        setValue(e.target.value)
    }
    const history = useHistory();

    const handleSubmit = (e) => {
        setMsg("")
        if (isValid(value) === true) {
            UserService.pracEditLink({
                name: name,
                value: value
             })
             .then(res=>{
                 console.log(res)
                 setLoading(false);
                 if (res.data.message === "Success") {
                     setEditing(false);
                     getPracInfo()
                 }
                 else if (res.statusCode === 404) {
                    setMsg("Invalid Entry")
                 }
             })
             .catch(err=>{setLoading(false); let message=handleErr(err); history.push({pathname: '/serverError', state: {message: message}})})
        }
        else {
            setMsg("Invalid Entry")
        }
    }


    return(
        <>
    <PersonalLabel>{title}</PersonalLabel>
    <InfoDiv>
    <PersonalP>
    {editing && children ? 
    React.cloneElement(children, { value: value, onChange: (e) => {handleChange(e); setMsg("")}})
    : null
    }
    {editing && !children ? <FormInput maxLength={maxLength} value={value} onChange={(e) => {handleChange(e); setMsg("")}}></FormInput> 
    : null }
    {!editing && value}

    </PersonalP>
    {!immutable && !editing ? <EditIcon onClick={()=> setEditing(true)}/> : ""}
    {!immutable && editing ? <BtnWrap>
        {value !== txt && <Button save={true} onClick={handleSubmit}>Save</Button> }
        <Button save={false} onClick={()=> {setEditing(false); setValue(txt); setMsg("")}}>Cancel</Button> 
        </BtnWrap>: "" }
    </InfoDiv>
    <Error>{msg}</Error>
    </>
    )



}
