export const MAX_NAME_LENGTH = 30;
export const MAX_DESC_LENGTH = 50;
export const MAX_NUM_LENGTH = 50;
export const MID_NUM_LENGTH = 10;

export const AHPRA_LENGTH = 13;
export const PHONE_LENGTH = 15;
export const MAX_EMAIL_LENGTH = 40;
export const MID_NAME_LENGTH = 20;

