import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { PractitionerDashboardContext } from "./PractitionerDashboardContext";
import { MiniLoader } from "../Misc/MiniLoader";
import { Storage, Auth } from "aws-amplify";
import { FaCaretDown, FaCaretUp, FaDownload} from "react-icons/fa";
import { handleErr } from "../../helpers/handleErr";
import UserService from "../../services/userService";
import Dropzone from "../Forms/ReactDropZoneNoFormik";
import form from "./REQ_NSW_HEALTH_FORMS.pdf";

export const State = ({ name }) => {
  let { pracData, getPracInfo } = useContext(PractitionerDashboardContext);
  let history = useHistory();
  const [hideExtras, setHideExtras] = useState(true);
  const [pending, setPending] = useState(false);
  const [identityId, setIdentityID] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [prefix, setPrefix] = useState("");
  const [uploading, setUploading] = useState(false);

  const getPresignedUrl = () => {
    
    Storage.get(prefix)
      .then((data) => {
        window.open(data);
      })
      .catch((err) => {
        setErrorMessage(
          "Could not download uploaded health form, please try again."
        );
      });
  };

  async function saveFile(file) {
    setPending(true);
    Storage.put(prefix, file)
      .then((data) => {
        Storage.get(prefix).then((data) => {
          UserService.uploadHealthFormLink({
            email: pracData.email,
            identityID: identityId,
          })
            .then((res) => {
              getPracInfo();
              setPending(false);
              setErrorMessage("");
              setUploading(false);
              setHideExtras(true);
            })
            .catch((err) => {
              console.log(err);
              setPending(false);
              setErrorMessage("Problem uploading file. Please try again.");
            });
        });
      })
      .catch((err) => {
        console.log(err)
        setPending(false);
        setErrorMessage("Problem uploading file. Please try again.");
      });
  }

  useEffect(() => {
    setPending(true);
    Auth.currentUserCredentials()
      .then((data) => {
        setIdentityID(data.identityId);
        const prefix = `practitioners/${data.identityId}/healthForms/${name}-health-form.pdf`;
        setPrefix(prefix);
        setPending(false);
      })
      .catch((err) => {
        setPending(false);
        let message = handleErr(err);
        history.push({ pathname: "/serverError", state: { message: message } });
      });
  }, []);

  return (
    <Container>
      {pending ? (
        <MiniLoader color={"#63a9ff"} />
      ) : (
        <>
          <Header>
            <TextH1 style={{ marginLeft: "-14px" }}>State</TextH1>
            <TextH1>Status</TextH1>
            {pracData.completedForms === "2" && <TextH1>Form</TextH1>}
          </Header>

          <Body>
            <Section>
              <TextH2>{name}</TextH2>
            </Section>

            <Section>
              {pracData.completedForms === "2" ? (
                <TextH2 color="green">Approved</TextH2>
              ) : (
                ""
              )}
              {pracData.completedForms === "1" ? (
                <TextH2 color="orange">Pending approval</TextH2>
              ) : (
                ""
              )}
              {pracData.completedForms === "0" ? (
                <TextH2 color="red">Not submitted</TextH2>
              ) : (
                ""
              )}
            </Section>

            {pracData.completedForms === "2"&&
                        <Section>
                        <LinkBtn  style={{marginTop:'-4px'}}onClick={getPresignedUrl} download>
                          <FaDownload size={20}/>
                          </LinkBtn>
                        </Section>
}



          </Body>

          {pracData.completedForms !== "2" && 

          <ExtrasWrap open={hideExtras}>
            <LinkBtn
                onClick={() => window.open(form)}
                download
              >
                <BtnText>Download original {name} Health Form</BtnText>
              </LinkBtn>

            {pracData.completedForms !== "0" ? (
              <LinkBtn onClick={getPresignedUrl} download>
                <BtnText>Download uploaded health form</BtnText>
              </LinkBtn>
            ) : (
              ""
            )}

            {pracData.completedForms !== "2" && !uploading && (
              <>
                <LinkBtn onClick={() => setUploading(true)} htmlFor="file">
                  Upload/Reupload NSW Health Form
                </LinkBtn>
              </>
            )}

            {uploading && <Dropzone saveFile={(file) => saveFile(file[0])} />}

            <Message err={true}>{errorMessage}</Message>
           </ExtrasWrap> }

          {pracData.completedForms !== "2" && hideExtras && (
            <OpenIcon onClick={() => setHideExtras(false)} />)}

          {pracData.completedForms !== "2" && !hideExtras && 
            <CloseIcon
              onClick={() => {
                setHideExtras(true);
                setUploading(false);
              }}
            />} 
            




        </>
      )}



    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  cursor: pointer;
  background-color: #f8f8f8;
  transition: all ease-in-out 300ms;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
`;

const TextH1 = styled.h1`
  font-size: 20px;
`;
const TextH2 = styled.p`
  font-size: 18px;
  color: ${(props) => props.color};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
`;

const Body = styled.div`
display: flex;
align-items: center;
justify-content: space-around;
flex-direction: row;
width: 100%;
`;

const Message = styled.h1`
  font-size: 0.8rem;
  color: ${(props) => (props.err ? "red" : "green")};
  margin-top: 3px;
`;

const BtnText = styled.span`
  color: navy;
`;

const Section = styled.div`
  border-radius: 20px;
  height: 50px;
  display: flex;
  margin-left: 5px;
  align-items: center;
  text-align: center;
`;

const LinkBtn = styled.button`
  padding: 10px 10px;
  background: none;
  margin-top: 10px;
  border-radius: 10px;
  outline: none;
  border: none;
  font-weight: bold;
  color: navy;
  &:hover {
    cursor: pointer;
    opacity: 0.4;
    transition: opacity 0.2s ease-in-out;
  }
`;
const ExtrasWrap = styled.div`
  overflow: hidden;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: ${(props) => (props.open ? "0px" : "200px")};
  transition: height 0.3s ease-in;
`;
const OpenIcon = styled(FaCaretDown)`
  &:hover {
    opacity: 0.4;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
  }
`;

const CloseIcon = styled(FaCaretUp)`
  &:hover {
    opacity: 0.4;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
  }
`;
