//import {Auth} from "aws-amplify"

const authHeader = async () => {
    //let session = await Auth.currentSession() 
    let res = JSON.parse(localStorage.getItem("user"));
    try {
      //let accessToken = session.accessToken.jwtToken
      if (res) {
        return {'Content-Type': 'application/json'}
      }
      return {'Content-Type': 'application/json'}
    }
    catch {
      return new Error("No user logged in")
    }
  }

  export default authHeader
