import React, {useContext} from "react"
import styled from 'styled-components'
import {Nav} from "../Dashboards/Nav"
import {PractitionerDashboardContext} from "./PractitionerDashboardContext"
import {MainLoader} from "../Misc/Loader"
export const Container = styled.div`
position: relative;
overflow: hidden;
margin-left: ${({ isOpen }) => (isOpen ? "16rem" : "0")};
transition: margin-left 0.2s ease-in-out;
`

export const Main = (props) => {
    let {loading} = useContext(PractitionerDashboardContext);
    if (loading) {
        return <MainLoader />
    }
    return (
    <Container isOpen={props.isOpen}>
        <Nav isOpen={props.isOpen} toggle={props.toggle}/>
        {props.children}
    </Container>
    )
} 