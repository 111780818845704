import React, {useContext, useState, useEffect} from "react";
import { PractitionerDashboardContext } from "./PractitionerDashboardContext";
import styled from "styled-components"
import {PersonalInfoCard} from "./PersonalInfoCard"
import {useHistory} from "react-router-dom"
import UserService from "../../services/userService"
import {handleErr} from "../../helpers/handleErr"

export const ProfProfile = () => {
    const {pracData} = useContext(PractitionerDashboardContext)
    const [qual, setQual] = useState([])
    let history = useHistory();

    useEffect(() => {
        window.scrollTo(0,0)
        UserService.pracGetQualLink(pracData.practitionerID)
        .then(res=>{
                setQual(res.data)
        })
        .catch(err=>{let message = handleErr(err); history.push({pathname: '/serverError', state: {message: message}})
    })}, [])

    return (
    <Container>
        <Body>
        <PersonalH1>Your professional profile</PersonalH1>
        <br></br>
        <p>Note: This information will be viewable by the corresponding healthcare provider
            whenever you apply for a vacancy.
        </p>
        <br></br>
        <Wrapper>
        <PersonalInfoCard 
        txt={pracData.ahpra}
        title="AHPRA Number"
        name="ahpra"
        immutable={true}
        />
        
        <PersonalInfoCard 
        txt={pracData.primaryMedDegree}
        title="Primary Medical Degree"
        name="primaryMedDegree"
        immutable={true}
        />
        
        <PersonalInfoCard 
        txt={pracData.university}
        title="University"
        name="university"
        immutable={true}
        />
        
        <PersonalInfoCard 
        txt={pracData.countryOfStudy}
        title="Country of Study"
        name="countryOfStudy"
        immutable={true}
        />

        <PersonalInfoCard 
        txt={pracData.areaOfTraining}
        title="Specialty"
        name="specialty"
        immutable={true}
        />
        
        <PersonalInfoCard 
        txt={pracData.postGradYear}
        title="Post Graduation Year"
        name="postGradYear"
        immutable={true}

        />

        {pracData.collegeName && 
        <PersonalInfoCard 
        txt={pracData.collegeName}
        title="Medical College"
        name="collegeName"
        immutable={true}
        />

        }

{pracData.collegeYearStart && 
        <PersonalInfoCard 
        txt={pracData.collegeYearStart}
        title="Medical College Commencement Year"
        name="collegeYearStart"
        immutable={true}
        />
        }

        </Wrapper>
        <Wrapper>
        <PersonalH2>Further Medical Qualifications</PersonalH2>

        {!qual || !qual.length ? 
        <p> None</p> : qual.map((item, index) => 
                <p key={index}>{item.qualificationName}</p>
         )}
        </Wrapper>
        <p style={{marginBottom: "15px"}}>To modify any of the above details, please email the selectamed admin. 
        </p>
        </Body></Container>)
}


export const Container = styled.div`
padding: 0 2rem;
display: flex;
flex-direction: column;

`

export const Header = styled.div`
margin-bottom: 10px;
`

export const VacancyWrapper = styled.div`
background: blue;
`

export const Body = styled.div`
display: flex;
flex-direction: column;
justify-content: space-evenly;
margin-bottom: 10px;
background: #f3f3f3;
border-radius: 10px;
padding: 1rem;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

`

export const PersonalH1 = styled.h1`
font-size: 24px;
color: navy;
`
export const PersonalH2 = styled.h1`
font-size: 20px;
margin-bottom: 10px;
padding-left: 4px;

`


export const BtnText = styled.p`
font-size: 16px;
`

export const BackBtn = styled.button`
color: navy;
outline: none;
border: none;
background: none;
margin-bottom: 10px;

&:hover {
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.25s ease-in;
}
`

export const ResMessage = styled.p`
color: navy;

`

export const Wrapper = styled.div`
background: #f8f8f8;
padding: 1rem;
margin-bottom: 10px;
border-radius: 10px;
`



export const FormButton = styled.button`
font-size: 15px;
width: 150px;
height: 40px;
border-radius: 10px;
border: none;
margin-top: 5px;
margin-right: 5px;
margin-bottom: 5px;
padding: 5px;
color: white;
background-color: #63a9ff;
outline:none;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
&:hover{
 cursor: pointer;
 opacity: 0.5;
 transition: opacity 0.2s ease-in-out;
}

`;

export const InfoDiv = styled.div`
display: flex;
overflow: hidden;
justify-content: space-between;
background: white;
border-radius: 10px;
margin-bottom: 10px;

`

export const MsgContainer = styled.div`
align-items: center;
display: flex;
flex-direction: column;
padding-left: 50px;
`

export const FormSelect = styled.select`
  font-size: 15px;
  margin-bottom: 10px;
  height: 35px;
  padding: 0;
`;
