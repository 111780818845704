import React from "react"
import styled from 'styled-components'
import {State} from "./State"

const Container = styled.div`
background: #f8f8f8;
border-radius: 20px;

`
export const StateForm = ({completed, currUser}) => {
    return(
    <Container>
    <State name="NSW" completed={completed} currUser={currUser}></State>
    </Container>
    )
}