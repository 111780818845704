import React, { useState } from "react";
import { MiniLoader } from "../Misc/MiniLoader";
import { processInputForBackend } from "../../processPractitionerInput";
import { useHistory } from "react-router-dom";
import UserService from "../../services/userService"
import {handleErr} from "../../helpers/handleErr"
import {
  FormButton,
  FormSection
} from "./SignUpElements";
import {SummaryTemplate} from "./SummaryTemplate"

const Submit = ({ info }) => {

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("")
  const history = useHistory();

  const submit = () => {
   setErr("")
   let data = processInputForBackend(info);
   sendData(data);
  };

  const sendData = (data) => {
   // setLoading(true);
    UserService.pracFileUploadLink(data.formData)
    .then(res => {
      console.log("Res: ",res)
      UserService.newPracAccountReqLink(data.fields)
      .then(res => {
        console.log("Res: ",res)
        setLoading(false);
        if (res.data.statusCode === 200 && res.data.message === "Successfully sent mail") {
          history.push({ pathname: '/success',state: {message:'Thank you. Your information has been sent to the Select-A-Med admin.'}})
        }
     else {
       setLoading(false);
     setErr(res.data.message)
    }
  })
  .catch((err) => {
  setLoading(false);
    throw new Error(err)
  })
  .catch((err) => {setLoading(false) ; 
    console.log(err)
     setLoading(false);
let message = handleErr(err); 
history.push({pathname: '/serverError', state: {message: message}})
  })
})
  }

  return (

    <FormSection>
    <SummaryTemplate info={info} loading={loading}/>
    <FormButton className={loading && 'disabled'} onClick={(e) => {e.preventDefault(); submit()}}> {loading ? <MiniLoader /> : "Submit"} </FormButton>
    {err && <div style={{color:"red"}}>{err}</div>}
    </FormSection>
  );
};

export default Submit;
