import React from "react";
import { MiniLoader } from './MiniLoader';
import styled from "styled-components";
export const MainLoader = () => {
  return (
    <LoaderDiv>
      <MiniLoader color={"#63a9ff"}/>
    </LoaderDiv>
  );
};

const LoaderDiv = styled.div`
  background: #f3f3f3;
  opacity: 0.3;
  height: 100%;
  left: 0;
  position: fixed;
  align-items: center;
  display: flex;
  justify-content: space-around;

  top: 0;
  width: 100%;
  z-index: 101;
`
