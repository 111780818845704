import React, {useContext, useEffect, useState} from "react"
import {ProviderDashboardContext} from "../ProviderDashboardContext"
import {useHistory} from "react-router-dom"
import {handleErr} from "../../../helpers/handleErr"
import UserService from "../../../services/userService"
import {FaArrowLeft} from "react-icons/fa"
import styled from "styled-components"
import {PractitionerOverviewCard} from "./PractitionerOverviewCard"

export const BackBtn = styled.button`
outline: none;
border: none;
color: navy;
margin-bottom: 30px;
background: none;
font-size: 18px;
font-weight: bold;

&:hover {
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.25s ease-in;
}
`

export const BackIcon = styled(FaArrowLeft)`
padding-top: 5px;
padding-right: 5px;
`

export const Container = styled.div`
padding: 1rem 2rem;

`

export const ShowPractitioners = (props) => {
    let history = useHistory();

    let {setLoading} = useContext(ProviderDashboardContext)
    let vacancyID = history.location.state.vacancyID;
    let vacancyInfo = history.location.state.vacancyInfo;

    const [practitionerInfo, setPractitionerInfo] = useState("")

    const levels = ["JMO", "RMO", "VMO", "CMO", "SMO", "Registrar", "Fellow", "Consultant"]

    const goBack = () => {
        history.goBack();
    }

    useEffect(() => {
        setLoading(true);
        // get pracs applied to vacancy
        UserService.getPracsThatApplied(vacancyID)
        .then(res=>{
            setLoading(false);
            if (!res.data) throw new Error("500: Internal Server Error - Please Try Again Later")
            setPractitionerInfo(res.data)
        })
        .catch(err=>{
                setLoading(false);
                let message = handleErr(err);
                history.push({
                    pathname: '/serverError',
                    state: {message: message}
                })
            })
        }, [])

    if (!practitionerInfo || !vacancyInfo) {
        return <Container></Container>
    }

    else {
        let practitionersApplied =
         practitionerInfo.map((practitioner, idx) => <PractitionerOverviewCard 
         vacancyID = {vacancyID} key={idx} practitioner={practitioner} vacancyID={vacancyID}
         />)
        return (
        <Container>
        <BackBtn onClick={goBack}><BackIcon />Back to vacancies </BackBtn>
        <br></br>
        <h2 style={{marginBottom: "10px"}}>Applicants for {vacancyInfo.specialty} {levels[vacancyInfo.trainingLevel-1]}</h2> 
        {practitionerInfo.length === 0 ? 
        <><br></br>
        <p> No practitioners have applied for this vacancy yet. </p></>: null }
        {practitionersApplied}
        </Container>)
    }
}




