import React from "react";
import {
  SidebarContainer,
  SidebarMenu,
  SidebarLink,
  SidebarWrapper,
  SideBtnWrap,
  SidebarRoute,
} from "./SidebarElement";

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen}>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink onClick={toggle} to="practitioners">
            For practitioners
          </SidebarLink>
          <SidebarLink onClick={toggle} to="providers">
            For providers
          </SidebarLink>
          <SidebarLink onClick={toggle} to="specialties">
            Specialties
          </SidebarLink>
          <SidebarLink onClick={toggle} to="register">
            Register
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
      <SideBtnWrap>
        <SidebarRoute to="/signin">Practitioner/Provider Login</SidebarRoute>
      </SideBtnWrap>
    </SidebarContainer>
  );
};

export default Sidebar;
