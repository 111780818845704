import Obj1Image from "../../images/obj1.svg";
import Obj2Image from "../../images/obj2.svg";
import Obj3Image from "../../images/obj3.svg";
import Obj4Image from "../../images/obj4.svg";

export const homeObjOne = {
  id: "practitioners",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "For practitioners",
  headline: "Find vacancies that are relevant to you.",
  description:
    "For Doctors, Nurses, and Allied Health Professionals. Set your availabilities to be matched with vacancies relevant to your expertise. Choose your payment method and get compensated promptly.",
  imgStart: true,
  img: Obj1Image,
  alt: "Doctor illustration",
  dark: false,
  primary: true,
  darkText: true,
  hasBtn: false,
};

export const homeObjTwo = {
  id: "practitioners",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "For practitioners",
  headline: "No more repetitive paperwork.",
  description:
    "Upload the required documents to verify your credentials. Fill out forms for health service providers once, and once only.",
  buttonLabel: "Register as a practitioner",
  imgStart: false,
  img: Obj2Image,
  alt: "Doctor illustration",
  dest: "/practitionersignup",
  dark: false,
  primary: true,
  darkText: true,
  hasBtn: true,
};

export const homeObjThree = {
  id: "providers",
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "For providers",
  headline: "Find candidates that meet your requirements.",
  description:
    "Advertise a vacancy and review all suitable applicants. You'll be able to liase directly with medical staff to inform your choice of hire.",
  imgStart: true,
  img: Obj3Image,
  alt: "Doctor illustration",
  dark: false,
  primary: true,
  darkText: true,
  hasBtn: false,
};

export const homeObjFour = {
  id: "providers",
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "For providers",
  headline: "Quickly get verified as a health district or medical practice.",
  description:
    "Provide basic details and contact information for your practice or region to begin matching with candidates. ",
  buttonLabel: "Register as a Provider",
  imgStart: false,
  img: Obj4Image,
  alt: "Doctor illustration",
  dark: false,
  primary: true,
  darkText: true,
  dest: "/providersignup",
  hasBtn: true,
};
