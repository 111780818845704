import React from "react"

import {Container, MessageWrap, MessageContent, MessageButton, MessageText, BtnText} from "./MessageElements.js"

export const ErrorMessage = (props) => {

    if (!props.history.location.state) {
        props.history.push("/");
    }


    return (
        <Container>
            <MessageWrap>
                <MessageContent>
                <MessageText>      
      {props.history.location.state && props.history.location.state.message}
      </MessageText>
                <MessageButton onClick={() => props.history.push("/")}>
                    <BtnText>Return to home page</BtnText>
                </MessageButton>
                </MessageContent>
            </MessageWrap>
        </Container>


    )


}