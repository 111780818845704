import React, { useState, useContext, useEffect} from "react";
import {
  FormH2,
  FormSection,
  FormP,
  InfoCard,
  SubFormButton,
  FormButton,
  ErrorMsg,
  ArrowForward,
  BtnWrap,
  Blurb,
} from "../../SignUpElements";
import { Formik } from "formik";
import * as yup from "yup";
import { StepContext } from "../../StepContext";
import { EmpInfoCard } from "./EmpInfoCard";
import { EmpForm } from "./EmpForm";
import { docSchema } from "../../../../supportedFileSettings.js";
import styled from "styled-components"
import FormikErrorFocus from 'formik-error-focus'
import Dropzone from "../../../Forms/ReactDropZone"

const EmploymentHistory = ({ workInfo, resume, submitHandler }) => {
  const newInput = {
    employer: "",
    position: "",
    empName: {
      firstName: "",
      lastName: "",
    },
    empContactNum: "",
    empContactEmail: "",
    startDate: "",
    ongoing: "",
    endDate: "",
  };

  const [adding, setAdding] = useState(false);
  const [editInfo, setEditInfo] = useState(newInput);
  const [empNum, setEmpNum] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [isMounted, setIsMounted] = useState(false);

  useEffect(()=> {
    window.scrollTo(0, 0)
    setIsMounted(true);
    return () => setIsMounted(false);
  }, [isMounted])


  const context = useContext(StepContext);
  const [saved, setSaved] = useState(false);

  const back = (e) => {
    e.preventDefault();
    if (!saved) {
      context.backStepAlert();
    }
  };

  const deleteHandler = (index) => {
    if (empNum === 0) {
      setErrorMsg("You must have at least one record in your employment history.");
      return;
    }
    let newInfo = workInfo.splice(index, 1);
    submitHandler({ workInfo: newInfo });
    setEmpNum((prev) => prev - 1);
  };

  return (
    <FormSection id="employmentHistory">
      <FormH2>3. Past Employment</FormH2>

      <Blurb>

      <FormP style={{fontWeight:"bold"}}>
        Please add details of your current/previous employment, from most to
        least recent.
      </FormP>


      </Blurb>

      <HistoryWrapper>

        {Array.isArray(workInfo) &&
          workInfo.length > 0 &&
          workInfo.map((work, index) => (
            <InfoCard key={index}>
              <EmpInfoCard
                work={work}
                index={index}
                setAdding={setAdding}
                deleteHandler={deleteHandler}
                setEditInfo={setEditInfo}
                setEmpNum={setEmpNum}
                errorMsg={errorMsg}
              />
                  </InfoCard>

          ))}

{!adding && (
  <div style={{textAlign:"center"}}>
        <SubFormButton
          onClick={() => {
            setEditInfo(newInput);
            setAdding(!adding);
            setEmpNum((prev) => prev + 1);
            setErrorMsg("");
          }}
        >
          Add to employment history...
        </SubFormButton>
        </div>
      )}


        </HistoryWrapper>


      <BtnWrap>
      {adding && (
        <EmpForm
          submitHandler={submitHandler}
          workInfo={workInfo}
          setAdding={setAdding}
          inputInfo={editInfo}
          empNum={empNum}
        />
      )}
           
      </BtnWrap>
      <br></br>


       <Formik
        initialValues={{ resume: resume }}
        validationSchema={yup.object().shape({
          resume: docSchema("resume"),
        })}
        onSubmit={(values, errors) => {
          if (workInfo.length === 0) {
            setErrorMsg(
              "Please ensure you have provided an employment history."
            );
          } else {
            submitHandler(values);
            setSaved(true);

            if (context.reachedSubmit) {
              context.setStep(context.REVIEW_STEP);
            } else {
              context.increment();
            }
          }
        }}
      >
        {({
          handleSubmit,
          errors,
          dirty,
          touched,
        }) => (
<>
          <FormSection>


            <Dropzone name="resume" required={true} title="Resume"/>
            {errors.resume && touched.resume && (
              <ErrorMsg className="error">{errors.resume}</ErrorMsg>
            )}
            <ErrorMsg>{errorMsg !== "You must have at least one record in your employment history." && errorMsg}</ErrorMsg>
            {!context.reachedSubmit ? (
              <FormButton type="button" onClick={(handleSubmit)}>
                Save and Continue <ArrowForward />{" "}
              </FormButton>
            ) : (
              <FormButton type="button" onClick={handleSubmit}>
                Return to Summary Page
              </FormButton>
            )}
            {!context.reachedSubmit && (
              <FormButton
                onClick={(e) => (dirty ? back(e) : context.decrement())}
              >
                Back
              </FormButton>
            )}
             <FormikErrorFocus
            offset={0}
            align={'middle'}
            focusDelay={100}
            ease={'out-bounce'}
            duration={200}
          />
          </FormSection>
</>
        )}
      </Formik>
    </FormSection>
  );
};

const HistoryWrapper = styled.div`
padding: 0.5rem;
border-radius: 10px;
align-items: center;


`

export default EmploymentHistory;
