import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import UserService from "../../services/userService";
import { handleErr } from "../../helpers/handleErr";
import { MiniLoader } from "../Misc/MiniLoader";
import {ProviderDashboardContext} from "./ProviderDashboardContext"
const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0.5rem;
  border-radius: 10px;
  background: #f8f8f8;
  justify-content: space-evenly;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all ease-in-out 300ms;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 20px;


  }

`;

const Subtitle = styled.div`
  font-size: 0.8rem;
  color: black;
  margin-top: 2px;
`;

const Practitioner = styled.div`
  width: 33%;
  text-align: center;
  align-items: center;
`;
const PropertyStreet = styled.div`
  font-size: 1rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Action = styled.button`
  outline: none;
  text-align: center;
  border: none;
  background: ${(props) => (props.markCompleted ? "green" : "#63a9ff")};
  color: white;
  padding: 5px;
  border-radius: 10px;
  white-space: nowrap;
  text-align: center;
  &:hover {
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;
  }
  @media screen and (max-width: 700px) {
    margin-bottom: 3px;
  }
`;

const Text = styled.h1`
font-size: 0.9rem;
text-transform: uppercase;
font-weight: 500;
color: black;
text-align: center;
width: 33%;
align-items: center;
`

const InfoWrapper = styled.div`
display: flex;
width: auto;
flex-direction: column;
justify-content: space-evenly;
@media screen and (max-width: 800px) {
  flex-direction: column;
  width: 290px;
  height: 120px;
  background: white;
  border-radius: 10px;
  margin-bottom: 2px;
  margin-left: 2px;
  margin-top: 2px;
  align-items: center;
}





`


export const Vacancy = ({ data, action }) => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 800);

  React.useEffect(() => {
      const handleResizeWindow = () => setDesktop(window.innerWidth > 800);
       // subscribe to window resize event "onComponentDidMount"
       window.addEventListener("resize", handleResizeWindow);
       return () => {
         // unsubscribe "onComponentDestroy"
         window.removeEventListener("resize", handleResizeWindow);
       };
     }, []);


  const history = useHistory();
  
  let {getData} = useContext(ProviderDashboardContext);

  const [loading, setLoading] = useState(false);

  const showApplicants = () => {
    history.push({
      pathname: "/providerDashboard/unfilledVacancies/practitioners",
      state: {vacancyID: data.vacancyID, vacancyInfo: data}
  })
}

  const handleShow = () => {
    history.push({
      pathname: "/providerDashboard/unfilledVacancies/view",
    state: {vacancyID: data.vacancyID}
    })
  }

  const confirmVacancyAsCompleted = () => {
    setLoading(true);
    UserService.markVacancyAsCompletedLink({
      providerID: data.ProviderID,
      vacancyID: data.vacancyID,
    })
      .then((res) => {
        if (!res.data)
          throw new Error(
            "500: Internal Server Error - Please Try Again Later"
          );
          getData();
          setLoading(false);

      })
      .catch((err) => {
        setLoading(false);
        let message = handleErr(err);
        history.push({ pathname: "/serverError", state: { message: message } });
      });
  };

  const handleMarkCompleted = () => {
    confirmAlert({
      title: "Confirm completion",
      message:
        "Would you like to mark this vacancy as completed? This will send a time sheet to the select-a-med admin.",
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmVacancyAsCompleted(),
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };

  const levels = [
    "JMO",
    "RMO",
    "VMO",
    "CMO",
    "SMO",
    "Registrar",
    "Fellow",
    "Consultant",
  ];

  if (loading) {

    return (
    <ContentWrapper>
            <Container>

      <MiniLoader
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignSelf: "center",
        }}
        color="#69a3ff"
      />
          </Container>

    </ContentWrapper>
  ) }

  else {

    return(
      <ContentWrapper>
      <Container>
        <InfoWrapper>
        {!isDesktop && <Text style={{float: "left"}}>Specialty</Text>}
        <Practitioner>
          <PropertyStreet>{levels[data.trainingLevel - 1]}</PropertyStreet>
          <Subtitle>{data.specialty}</Subtitle>
        </Practitioner>
        </InfoWrapper>
        <InfoWrapper >

        {!isDesktop && <Text>Dates</Text>}


          <Subtitle>{data.startDate} - {data.endDate}</Subtitle>

        </InfoWrapper>


        <InfoWrapper>

              {!isDesktop && <Text>ACTIONS</Text>}

        <Practitioner>
          <Action onClick={handleShow}>More Info</Action>{" "}
        </Practitioner>
        </InfoWrapper>

        { (action === "viewPrac" || action === "markCompleted") && 

        <InfoWrapper>
        {!isDesktop && <Text>Applicants</Text>}

        {action === "viewPrac" ? (
          <Practitioner>
            <Action onClick={showApplicants}>View Applicants</Action>{" "}
          </Practitioner>
        ) : null}
        {action === "markCompleted" ? (
          <Practitioner>
            <Action markCompleted={true} onClick={handleMarkCompleted}>
              Complete
            </Action>
          </Practitioner>
        ) : null}
        </InfoWrapper>

  }



      </Container>
    </ContentWrapper>


    )



  }

};
export default Vacancy;
