
import React from "react";
import {FaCaretRight} from "react-icons/fa"
import styled from "styled-components"
import {useHistory} from "react-router-dom";
import {InfoSection,
H1, Paragraph} from "./PractitionerElements"

export const PractitionerOverviewCard = ({practitioner, vacancyID}) => {
    let history = useHistory();

    return(
        <InfoSection> 
        <TextDiv>
        <H1>{practitioner.title} {practitioner.firstname} {practitioner.lastname}</H1>
 <Paragraph>{`${practitioner.prefAreaOfWork}`}</Paragraph>
        <Paragraph>{`${practitioner.postGradYear} years postgraduation`}</Paragraph>
        <br></br>
        </TextDiv>
        <ButtonDiv>
            <FaCaretRight size={20} onClick={()=>
                history.push({
                    pathname: "/providerDashboard/unfilledVacancies/practitioners/viewOne",
                    state: {practitioner, vacancyID}})}/>
        </ButtonDiv>
        </InfoSection>
       )
       }

const TextDiv = styled.div`
width: 95%; 
`

const ButtonDiv = styled.div`
width: 5%; 
display: flex;
justify-content: flex-end;
align-items: center;

&:hover {
    cursor: pointer;
    opacity: 0.2;
    transition: opacity 0.2s ease-in-out;
}
`