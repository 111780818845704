import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons/lib";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
  NavBtnWrapper,
  MobileIcon,
} from "./NavbarElements";
import { animateScroll as scroll } from "react-scroll";
import HamburgerMenu from "react-hamburger-menu";

const Navbar = ({ toggle, isOpen }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const [isMounted, setIsMounted] = useState(true);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else setScrollNav(false);
  };

  useEffect(() => {
    setIsMounted(true);
    if (isMounted) {
      window.addEventListener("scroll", changeNav);
    }
    return () => {
      setIsMounted(false);
      window.removeEventListener("scroll", changeNav);
    };
  }, [isMounted]);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo onClick={toggleHome} to="/">
              <img alt="select-a-med" className="logo logobig" src="sam-logo.png"></img>
            </NavLogo>
            <MobileIcon>
              <HamburgerMenu
                isOpen={isOpen}
                menuClicked={toggle}
                height={20}
                strokeWidth={3}
                borderRadius={2}
              />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks
                  smooth={true}
                  duration={500}
                  spy={true}
                  eact="true"
                  offset={-80}
                  to="practitioners"
                  activeClass="active"
                >
                  For practitioners
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  smooth={true}
                  duration={500}
                  spy={true}
                  eact="true"
                  offset={-80}
                  to="providers"
                  activeClass="active"
                >
                  For healthcare providers
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  smooth={true}
                  duration={500}
                  spy={true}
                  eact="true"
                  offset={-80}
                  activeClass="active"
                  to="specialties"
                >
                  Specialties
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  smooth={true}
                  duration={500}
                  spy={true}
                  eact="true"
                  offset={-80}
                  activeClass="active"
                  to="register"
                >
                  Register
                </NavLinks>
              </NavItem>
            </NavMenu>
            <NavBtnWrapper>
              <NavBtn>
                <NavBtnLink to="/signin">
                  Practitioner/Provider Login
                </NavBtnLink>
              </NavBtn>
            </NavBtnWrapper>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
