export const processInputForBackend = (info, currUser, password) => {
  const formData = new FormData();
  formData.append("currUser", info.personalInfo.email);
  formData.append("files", info.qualifications.insurance.insuranceDoc, "insurance-certificate");
  formData.append("files", info.qualifications.passport, "passport");
  formData.append("files", info.qualifications.drivers, "drivers-license");
  formData.append("files", info.qualifications.AHPRA.AHPRADoc, "AHPRA-certificate");
  formData.append(
    "files",
    info.qualifications.primaryMedDegree.degreeDoc,
    "medical-degree"
  );
  formData.append("files", info.resume, "resume");

  let otherQualificationsNoFiles = [];
  info.qualifications.otherQualifications.map((qual, i) => {
    formData.append("files", qual.qualificationDoc, `qualificationDoc${i + 1}`);
    otherQualificationsNoFiles.push({
      "qualificationName": qual.qualificationName,
  })
  return null;
}

  );

  const swapDateAndMonth = (date) => {
    if (date === "") return;
    let day = date.substring(0,2)
    let month = date.substring(3, 5)
    let year = date.substring(6,11)
    let newDate = month + "/" + day + "/" + year
    return (newDate)
  }

  let personalInfoCopy = JSON.parse(JSON.stringify(info.personalInfo))
  personalInfoCopy.DOB = swapDateAndMonth(info.personalInfo.DOB)
  personalInfoCopy.visaExpiry = swapDateAndMonth(info.personalInfo.visaExpiry)

  let workInfoCopy = JSON.parse(JSON.stringify(info.workInfo))
 
  workInfoCopy.map((workInfo, index) => {
    workInfoCopy[index].startDate = swapDateAndMonth(workInfo.startDate)
    workInfoCopy[index].endDate = swapDateAndMonth(workInfo.endDate)
 })


  let fields = {
    personalInfo: personalInfoCopy,
    qualifications: {
      AHPRA: info.qualifications.AHPRA.AHPRANum,
      insurance: {providerName: info.qualifications.insurance.providerName},
      primaryMedDegree: {
        degreeName: info.qualifications.primaryMedDegree.degreeName,
        uni: info.qualifications.primaryMedDegree.uni
      },
      areaOfTraining: info.qualifications.areaOfTraining,
      otherQualifications: otherQualificationsNoFiles,
      postGradYear: info.qualifications.postGradYear,
      college: info.qualifications.college
    },
    workInfo: workInfoCopy,
    refs: info.refs,
    workPref: info.workPref,
  };
 
  return { formData, fields };
};
