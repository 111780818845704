import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";

export const Container = styled.div`
  min-height: 692px;
  min-width: 300px;
  @media screen and (max-width: 400px) {
    padding-top: 20px;
  }
`;


export const MessageWrap = styled.div`
  height: 100%;
  padding: 30px;
  background: white;
  @media screen and (max-width: 400px) {
    height: 500px;
  }
`;

export const MessageContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 480px) {
    padding: 10px;
  }
`;

export const MessageButton = styled.button`
  background: #63a9ff;
  padding: 16px 0;
  width: 250px;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 20px;

  &:hover {
    opacity: 0.8;
    transition: opacity 0.25s ease-in;
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    background: grey;
    opacity: 0.8;
  }
`;

export const MessageText = styled.p`
  text-align: center;
  margin-bottom: 24px;
  color: black;
  font-size: 18px;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
  position: relative;
  top: 3px;
`;
export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;

export const Nav = styled.nav`
  height: 80px;
  font-size: 1rem;
  z-index: 10;
  width: 900px;
  margin: 0 auto;

  @media screen and (max-width: 600px) {
    transition: 0.8 all ease;
  }
`;

export const FormSubSection = styled.div`
  border-bottom: 1px solid grey;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  max-width: 1100px;
  padding-top: 30px;
`;

export const ErrorMsg = styled.span`
  font-size: 15px;
  color: red;
  margin-bottom: 10px;
`;

export const FormContainer = styled.div`
  border-bottom: 1px solid gray;
`;
export const FormSelect = styled.select`
  font-size: 15px;
  margin-bottom: 10px;
  display: inline-block;
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export const NavItem = styled.li`
  height: 80px;
  @media screen and (max-width: 768px) {
    height: 120px;
  }
`;

export const NavLinks = styled(LinkS)`
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.completed {
    border-bottom: 3px solid #63a9ff;
  }
`;

export const NavBtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 30%;
  margin-left: 50px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: #63a9ff;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`;

export const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 70%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const BtnText = styled.p`
  color: white;
`;