import * as yup from "yup";

const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
];

const MAX_FILE_SIZE = 1 * 1024 * 1024;

const docSchema = (doc) => {
  return yup
    .mixed()
    .required("A file upload is required. ")
    .test(doc, "Upload failed: invalid format", (value) => {
      return value && SUPPORTED_FORMATS.includes(value.type);
    })
    .test('fileSize', "File size must be less than 1MB",
     value => value && value.size <= MAX_FILE_SIZE);
};

export { SUPPORTED_FORMATS, docSchema };
