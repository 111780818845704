import React from "react";
import { Formik, Field } from "formik";
import {
  FormSection,
  ErrorMsg,
  FormLabel,
  FormInput,
  SubFormButton,
} from "../../SignUpElements";
import * as yup from "yup";
import * as moment from "moment";
import {MAX_EMAIL_LENGTH, MAX_NAME_LENGTH, MAX_DESC_LENGTH}  from "../../../../helpers/characterLimits"; 
import FormikErrorFocus from 'formik-error-focus'
import {ReactDatePicker} from "../../../Misc/ReactDatePicker"

const phoneRegExp = /^0{1}(2|4|3|7|8){1}[0-9]{8}$/;

const dateParser = (date) => {
  if (date.length) return date;
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;
  
  return day + '/' + month + '/' + year;
}


const parseTheDate = (date) => {
  var parts = date.split("/");
  var dt = new Date(parseInt(parts[2], 10),
                parseInt(parts[1], 10) - 1,
                parseInt(parts[0], 10));
  console.log(dt)
  return dt

}

const empSchema = yup.object().shape({
  employer: yup.string().required("Please enter the name of an employer"),
  position: yup.string().required("Please provide a position."),
  empName: yup.object().shape({
    firstName: yup.string().required("Please provide a first name."),
    lastName: yup.string().required("Please provide a last name."),
  }),
  empContactNum: yup
    .string()
    .matches(phoneRegExp, "Invalid phone number")
    .required("Please provide a contact phone number"),
  empContactEmail: yup
  .string()
  .email("Please provide a valid email address")
  .required("Please provide this supervisor's email address."),
  startDate: yup
    .date()
    .required("Please enter a start date.")
    .test(
      "startDate",
      "Invalid start- must refer to past/current employment",
      (value) => {
        return moment().diff(moment(value), "days") > 0;
      }
    ),
  ongoing: yup
    .string()
    .required("Please indicate whether this work is ongoing."),
  endDate: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .when("ongoing", {
      is: (name) => name === "no",
      then: yup
        .date()
        .min(yup.ref("startDate"), "End date must be after start date.")
        .max(
          moment(),
          "If this employment ends in the future, please press yes to the above"
        )
        .required("Please enter an end date."),
    }),
});

export const EmpForm = ({
  setAdding,
  inputInfo,
  empNum,
  workInfo,
  submitHandler
}) => {
  const initialValues = {
    employer: inputInfo.employer,
    position: inputInfo.position,
    empName: {
      firstName: inputInfo.empName.firstName,
      lastName: inputInfo.empName.lastName,
    },
    empContactNum: inputInfo.empContactNum,
    empContactEmail: inputInfo.empContactEmail,
    startDate: inputInfo.startDate ? parseTheDate(inputInfo.startDate) : "",
    ongoing: inputInfo.ongoing,
    endDate: inputInfo.endDate ? parseTheDate(inputInfo.endDate) : "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={empSchema}
      onSubmit={(values) => {
        console.log(values)

        let valuesCpy = JSON.parse(JSON.stringify(values))
        valuesCpy.startDate = dateParser(values.startDate)
        if (values.endDate) {
          valuesCpy.endDate = dateParser(values.endDate)
        }
        let newInfo = []

       if (!Array.isArray(workInfo) || workInfo.length === 0) {
          newInfo = [valuesCpy];
        } else {
          if (empNum + 1 > workInfo.length) {
            newInfo = [...workInfo, valuesCpy];
          } else {
            newInfo = workInfo.map((info, index) => {
              return index === empNum ? valuesCpy : info;
            });
          }
        }


       submitHandler({ workInfo: newInfo });
       setAdding(false); 
      }}
    >
      {({ values, errors, touched, setFieldValue, handleSubmit }) => (
        <FormSection>
          <FormLabel htmlFor="employer">
            Employer<span className="required">*</span>
          </FormLabel>
          <Field name="employer" className="formInput" maxLength={MAX_DESC_LENGTH} />
          {errors.employer && touched.employer && (
            <ErrorMsg>{errors.employer}</ErrorMsg>
          )}

          <FormLabel htmlFor="position">
            Position<span className="required">*</span>
          </FormLabel>
          <Field name="position" className="formInput" maxLength={MAX_DESC_LENGTH} />
          {errors.position && touched.position && (
            <ErrorMsg>{errors.position}</ErrorMsg>
          )}

          <FormLabel htmlFor="empName.firstName">
            Supervisor First Name<span className="required">*</span>
          </FormLabel>
          <Field name="empName.firstName" className="formInput" maxLength={MAX_NAME_LENGTH}/>
          {errors.empName && touched.empName && (
            <ErrorMsg>{errors.empName.firstName}</ErrorMsg>
          )}

          <FormLabel htmlFor="empName.lastName">
            Supervisor Last Name<span className="required">*</span>
          </FormLabel>
          <Field name="empName.lastName" className="formInput" maxLength={MAX_NAME_LENGTH}/>
          {errors.empName && touched.empName && (
            <ErrorMsg>{errors.empName.lastName}</ErrorMsg>
          )}

          <FormLabel htmlFor="empContactNum">
            Supervisor Contact Phone Number<span className="required">*</span>
          </FormLabel>
          <Field name="empContactNum" className="formInput" />
          {errors.empContactNum && touched.empContactNum && (
            <ErrorMsg>{errors.empContactNum}</ErrorMsg>
          )}
          
          <FormLabel htmlFor="empContactEmail">
            Supervisor Email<span className="required">*</span>
          </FormLabel>
          <Field name="empContactEmail" className="formInput"maxLength={MAX_EMAIL_LENGTH} />
          {errors.empContactEmail && touched.empContactEmail && (
            <ErrorMsg>{errors.empContactEmail}</ErrorMsg>
          )}

          <FormLabel htmlFor="startDate">
            Start Date<span className="required">*</span>
          </FormLabel>

          <ReactDatePicker name="startDate" />
          {errors.startDate && touched.startDate && (
            <ErrorMsg>{errors.startDate}</ErrorMsg>
          )}

          <br></br>

          <FormLabel>
            Is this work ongoing? <span className="required">*</span>
          </FormLabel>

          <FormLabel>Yes</FormLabel>
          <FormInput
            type="radio"
            id="yes"
            name="ongoing"
            value="yes"
            onChange={(value) => {setFieldValue("ongoing", "yes"); setFieldValue("endDate", "")}}
            checked={values.ongoing === "yes"}
          />
          <FormLabel>No</FormLabel>
          <FormInput
            type="radio"
            id="no"
            name="ongoing"
            value="no"
            onChange={(value) => {setFieldValue("ongoing", "no")}}
            checked={values.ongoing === "no"}
          />

          {errors.ongoing && touched.ongoing && (
            <ErrorMsg>{errors.ongoing}</ErrorMsg>
          )}

          {values.ongoing === "no" && (
            <>
              <FormLabel htmlFor="endDate">
                End Date <span className="required">*</span>
              </FormLabel>
              <ReactDatePicker name="endDate" />
          {errors.endDate && touched.endDate && (
            <ErrorMsg>{errors.endDate}</ErrorMsg>
          
          )}




            </>
          )}
          <br></br>


          <SubFormButton
            type="button"
            onClick={(val) => {
              handleSubmit(val);
            }}
          >
            Save
          </SubFormButton>
          <SubFormButton
            type="button"
            onClick={(val) => {
              setAdding(false);
            }}
          >
            Cancel
          </SubFormButton>
          <FormikErrorFocus
            offset={0}
            align={'middle'}
            focusDelay={100}
            ease={'out-bounce'}
            duration={200}
          />



        </FormSection>
      )}
    </Formik>
  );
};

