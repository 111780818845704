import React, { useState, useContext, useEffect } from "react";
import { SessionContext } from "../Misc/SessionContext";
import { useHistory } from "react-router-dom";
import UserService from "../../services/userService";
import { handleErr } from "../../helpers/handleErr";

const ProviderDashboardContext = React.createContext();

const ProviderDashboardProvider = (props) => {
  const session = useContext(SessionContext);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noPracVacancies, setNoPracVacancies] = useState([]);
  const [uncompletedVacancies, setUncompletedVacancies] = useState([]);
  const [pastVacancies, setPastVacancies] = useState([]);
  const [cognitoID, setCognitoID] = useState("");

  let history = useHistory();

  const getUnfilledVacancies = () => {
    UserService.getUnfilledVacanciesLink(cognitoID)
      .then((res) => {
        if (!res.data)
          throw new Error(
            "500: Internal Server Error - Please Try Again Later"
          );
        setNoPracVacancies(res.data);
      })
      .catch((err) => {
        let message = handleErr(err);
        history.push({ pathname: "/serverError", state: { message: message } });
      });
  };

  const getUncompletedVacancies = () => {
    UserService.getUncompletedVacanciesLink(cognitoID)
      .then((res) => {
        setLoading(false);
        if (!res.data)
          throw new Error(
            "500: Internal Server Error - Please Try Again Later"
          );

        setUncompletedVacancies(res.data);
      })
      .catch((err) => {
        let message = handleErr(err);
        history.push({ pathname: "/serverError", state: { message: message } });
      });
  };
  const getPastVacancies = () => {
    UserService.getCompletedVacanciesLink(cognitoID)
      .then((res) => {
        if (!res.data)
          throw new Error(
            "500: Internal Server Error - Please Try Again Later"
          );
        setPastVacancies(res.data);
      })
      .catch((err) => {
        let message = handleErr(err);
        history.push({ pathname: "/serverError", state: { message: message } });
      });
  };

  // get all different types of vacancies.
  const getData = () => {
    UserService.getProviderInfoLink()
      .then((res) => {
        console.log(res)

        if (res && res.data) {
          let userData = res.data;
          UserService.getUnfilledVacanciesLink()
            .then((res) => {
              console.log(res)

              let noPrac = [];
              if (res.data) noPrac = res.data;
              else
                throw new Error(
                  "500: Internal Server Error - Please Try Again Later"
                );
              UserService.getUncompletedVacanciesLink()
                .then((res) => {
                  console.log(res)

                  let uncomp = [];
                  if (res.data) uncomp = res.data;
                  else
                    throw new Error(
                      "500: Internal Server Error - Please Try Again Later"
                    );
                  UserService.getCompletedVacanciesLink()
                    .then((res) => {
                      let past = [];
                      if (res.data) past = res.data;
                      
                      else
                        throw new Error(
                          "500: Internal Server Error - Please Try Again Later"
                        );
                      setUserData(userData);
                      setNoPracVacancies(noPrac);
                      setUncompletedVacancies(uncomp);
                      setPastVacancies(past);
                    })
                    .catch((error) => {
                      console.log(error)

                      let message = handleErr(error);
                      history.push({
                        pathname: "/serverError",
                        state: { message: message },
                      });
                    });
                })
                .catch((error) => {
                  console.log(error)
                  let message = handleErr(error);
                  history.push({
                    pathname: "/serverError",
                    state: { message: message },
                  });
                });
            })
            .catch((error) => {
              console.log(error)

              let message = handleErr(error);
              history.push({
                pathname: "/serverError",
                state: { message: message },
              });
            });
        } else {
          throw new Error(
            "Sorry, the Select-a-Med Server is currently offline. Please try again later."
          );
        }
      })
      .catch((err) => {
        console.log(err)

        let message = handleErr(err);
        history.push({ pathname: "/serverError", state: { message: message } });
      });
  };

  useEffect(() => {
    session
      .getLoggedInUser()
      .then((res) => {
        setCognitoID(res);
        getData();
      })
      .catch((err) => {
        console.log(err);
        let message = handleErr(err);
        history.push({ pathname: "/serverError", state: { message: message } });
      });
  }, []);

  return (
    <ProviderDashboardContext.Provider
      value={{
        userData: userData,
        getData: getData,
        pastVacancies: pastVacancies,
        uncompletedVacancies: uncompletedVacancies,
        noPracVacancies: noPracVacancies,
        setLoading: setLoading,
        loading: loading,
        getUnfilledVacancies: getUnfilledVacancies,
        getUncompletedVacancies: getUncompletedVacancies,
        getPastVacancies: getPastVacancies,
      }}
    >
      {" "}
      {props.children}
    </ProviderDashboardContext.Provider>
  );
};

export { ProviderDashboardProvider, ProviderDashboardContext };
