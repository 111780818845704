import React from 'react'
import styled from 'styled-components'
import {StateForm} from "./StateForm"

const SearchH1 = styled.h1`
padding-left: 25px;
font-size: 22px;
margin-bottom: 10px;
`

const FormSection = styled.div`
padding-left: 10px;
margin-top: 20px;
`

const Container = styled.div`
padding: 0 2rem; 
`
export const Forms = ({completed, currUser}) =>  {
    
    return(
        <Container>
        <SearchH1>Health District Forms</SearchH1>
        <FormSection>
        <StateForm completed={completed} currUser={currUser}/>
        </FormSection>
        </Container>
    )


}     