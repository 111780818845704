import React from "react";
import AmbulanceIcon from "../../images/ambulance.png";
import InjectionIcon from "../../images/injection.svg";
import ICUIcon from "../../images/hospital-bed.svg";
import OtherIcon from "../../images/medicine.svg";
import WomanIcon from "../../images/woman.svg";
import PsychIcon from "../../images/mental-health.svg";
import PaedIcon from "../../images/child.svg";
import GeneralIcon from "../../images/stethoscope.svg";
import {
  SpecialtyContainer,
  SpecialtyWrapper,
  SpecialtyCard,
  SpecialtyH1,
  SpecialtyIcon,
  SpecialtyP,
  TextWrapper,
} from "./SpecialtyElements";
const Specialties = () => {
  return (
    <SpecialtyContainer id="specialties">
      <TextWrapper>
        <SpecialtyH1>
          Advertise or accept vacancies across a variety of specialties.
        </SpecialtyH1>
      </TextWrapper>
      <SpecialtyWrapper>
        <SpecialtyCard>
          <SpecialtyIcon src={AmbulanceIcon} />
          <SpecialtyP>Emergency</SpecialtyP>
        </SpecialtyCard>
        <SpecialtyCard>
          <SpecialtyIcon src={InjectionIcon} />
          <SpecialtyP>Anasthetics</SpecialtyP>
        </SpecialtyCard>
        <SpecialtyCard>
          <SpecialtyIcon src={ICUIcon} />
          <SpecialtyP>ICU/CCU</SpecialtyP>
        </SpecialtyCard>
        <SpecialtyCard>
          <SpecialtyIcon src={GeneralIcon} />
          <SpecialtyP>General Practice</SpecialtyP>
        </SpecialtyCard>
        <SpecialtyCard>
          <SpecialtyIcon src={WomanIcon} />
          <SpecialtyP>OB/GYN</SpecialtyP>
        </SpecialtyCard>
        <SpecialtyCard>
          <SpecialtyIcon src={PsychIcon} />
          <SpecialtyP>Psychiatry</SpecialtyP>
        </SpecialtyCard>
        <SpecialtyCard>
          <SpecialtyIcon src={PaedIcon} />
          <SpecialtyP>Paediatrics</SpecialtyP>
        </SpecialtyCard>
        <SpecialtyCard>
          <SpecialtyIcon src={OtherIcon} />
          <SpecialtyP>Other</SpecialtyP>
        </SpecialtyCard>
      </SpecialtyWrapper>
    </SpecialtyContainer>
  );
};

export default Specialties;
