import React, { useState, useContext } from "react";
import { Container, FormWrap, Wrapper, FormContent} from "./SignUpElements";
import BasicInfo from "./Steps/BasicInfo";
import Qualifications from "./Steps/EmploymentHistory/Qualifications";
import Referees from "./Steps/Referees";
import SignUpNav from "./SignUpNav";
import EmploymentHistory from "./Steps/EmploymentHistory";
import Submit from "./Submit";
import WorkPref from "./Steps/WorkPref";
import { StepContext } from "./StepContext";
import {SignInAndRegHeader} from "../SignInAndRegHeader"
import {parseTheDate} from "../../helpers"
import {init} from "./DummyInitData";

const PractitionerSignUp = () => {
  const context = useContext(StepContext);
  const [info, setInfo] = useState(init);

  const submitHandler = (newInfo) => {
    let key = Object.keys(newInfo)[0];
    let updatedInfo = { ...info, [key]: newInfo[key] };
    setInfo(updatedInfo);
  };

  const getStep = (step) => {
    switch (step) {
      case 1:
        return (
          <BasicInfo
            submitHandler={submitHandler}
            title={(info.personalInfo && info.personalInfo.title) || ""}
            firstName={(info.personalInfo && info.personalInfo.firstName) || ""}
            lastName={(info.personalInfo && info.personalInfo.lastName) || ""}
            DOB={(info.personalInfo && parseTheDate(info.personalInfo.DOB)) || ""}
            homePhone={(info.personalInfo && info.personalInfo.homePhone) || ""}
            workPhone={(info.personalInfo && info.personalInfo.workPhone) || ""}
            mobile={(info.personalInfo && info.personalInfo.mobile) || ""}
            email={(info.personalInfo && info.personalInfo.email) || ""}
            address={
              (info.personalInfo && info.personalInfo.address) || {
                unitNumber: "",
                streetNumber: "",
                streetName: "",
                suburb: "",
                town: "",
                country: "Australia",
                state: "",
                postcode: "",
              }
            }
            isAusRes={(info.personalInfo && info.personalInfo.isAusRes) || ""}
            visaExpiry={
              (info.personalInfo && info.personalInfo.visaExpiry && parseTheDate(info.personalInfo.visaExpiry)) || ""
            }
          />
        );

      case 2:
        return (
          <Qualifications
            AHPRA={
              (info.qualifications && info.qualifications.AHPRA) || {
                AHPRANum: "",
                AHPRADoc: " ",
              }
            }
            primaryMedDegree={
              (info.qualifications && info.qualifications.primaryMedDegree) || {
                degreeName: "",
                degreeDoc: "",
                uni: {name: "", country: ""},
              }
            }
            otherQualifications={
              (info.qualifications &&
                info.qualifications.otherQualifications) ||
              []
            }
            insurance = {
              (info.qualifications && info.qualifications.insurance) || {
                providerName: "",
                insuranceDoc: ""
              }
            }
            passport = {
              (info.qualifications && info.qualifications.passport) || ""
            }
            drivers = {
              (info.qualifications && info.qualifications.drivers)|| ""
            }
            postGradYear = {(info.qualifications && info.qualifications.postGradYear )|| ""}
            areaOfTraining = {(info.qualifications && info.qualifications.areaOfTraining )|| ""}
            college = {(info.qualifications && info.qualifications.college) || {
              registered: "",
              name: "",
              year: ""
            } }
            submitHandler={submitHandler}
          />
        );
      case 3:
        return (
          <EmploymentHistory
            submitHandler={submitHandler}
            workInfo={info.workInfo || []}
            resume={info.resume || ""}
          />
        );
      case 4:
        return (
          <Referees
            refs={
              info.refs || {
                ref1: {
                  firstName: "",
                  lastName: "",
                  phone: "",
                  placeOfWork: "",
                  email: "",
                },
                ref2: {
                  firstName: "",
                  lastName: "",
                  phone: "",
                  placeOfWork: "",
                  email: "",
                },
                ref3: {
                  firstName: "",
                  lastName: "",
                  phone: "",
                  placeOfWork: "",
                  email: "",
                },
              }
            }
            submitHandler={submitHandler}
          />
        );
      case 5:
        return <WorkPref 
        states={(info.workPref && info.workPref.states) || []}
        areaOfWork={(info.workPref && info.workPref.areaOfWork )|| ""}
        submitHandler={submitHandler}
        />
      case 6:
        return <Submit submitHandler={submitHandler} info={info} />;
      default:
    }
  };
  //const currStep = getStep();
  const currStep = getStep(context.step);
  return (
    <Wrapper>
      <SignInAndRegHeader />
      <Container>

        <SignUpNav />
        <FormWrap>
          <FormContent>

         {currStep}
         </FormContent>

        </FormWrap>
        </Container>


    </Wrapper>
  );
};

export default PractitionerSignUp;
