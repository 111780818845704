import React, { useContext, useState, useEffect } from "react";
import {
  FormH2,
  FormSection,
  FormP,
  FormButton,
  ArrowForward,
  ErrorMsg,
  InputContainer,
} from "../../SignUpElements";
import { Formik, Field } from "formik";
import * as yup from "yup";
import { RefForm } from "./RefForm";
import { StepContext } from "../../StepContext";
import FormikErrorFocus from 'formik-error-focus'

const phoneRegExp = /^0{1}(2|4|3|7|8|5){1}[0-9]{8}$/;

const Referees = ({ refs, submitHandler }) => {
  const [saved, setSaved] = useState(false);
  
  useEffect(()=>window.scroll(0,0),[])

  const back = (e) => {
    e.preventDefault();
    if (!saved) {
      context.backStepAlert();
    }
  };

  const handleSubmit = (values) => {
    submitHandler({ refs: values });
  };

  const context = useContext(StepContext);

  const indSchema = yup.object().shape({
    firstName: yup.string().required("You must enter a first name."),
    lastName: yup.string().required("You must enter a last name. "),
    phone: yup
      .string()
      .matches(phoneRegExp, "Please enter a valid phone number.")
      .required("You must enter a phone number."),
    placeOfWork: yup.string().required("You must enter a place of work. "),
    email: yup
      .string("Invalid email address")
      .required("You must enter an email address.")
      .email("Invalid email address"),
  });

  const refSchema = yup.object().shape({
    ref1: indSchema,
    ref2: indSchema,
    ref3: indSchema,
  });

  return (
    <FormSection id="referees">
      <FormH2>4. Referees </FormH2>
      <FormP>
        Please supply details of 3 referees whom you have worked with directly
        in the past 12 months within the same specialty, who are of equal or
        higher seniority. Junior doctors are to supply referees at the
        consultant level or higher.
      </FormP>
      <Formik
        initialValues={{
          ref1: refs.ref1,
          ref2: refs.ref2,
          ref3: refs.ref3,
        }}
        validationSchema={refSchema}
        onSubmit={(values) => {
          handleSubmit(values);
          setSaved(true);
          if (context.reachedSubmit) {
            context.setStep(context.REVIEW_STEP);
          } else {
            context.increment();
          }
        }}
      >
        {({ errors, touched, dirty, handleSubmit }) => (
          <FormSection>
            <InputContainer>
              <FormP style={{fontWeight:"bold", marginBottom: "10px"}}>
                Referee #1 <span className="required">*</span>{" "}
              </FormP>
              {errors.ref1 && touched.ref1 && (
                <ErrorMsg className="error">
                  One or more fields for referee #1 are missing
                </ErrorMsg>
              )}{" "}
              <Field name="ref1" component={RefForm} className="formInput" />
            </InputContainer>
            <InputContainer>
              <FormP style={{fontWeight:"bold", marginBottom: "5px"}}>
                Referee #2 <span className="required">*</span>
              </FormP>
              {errors.ref2 && (
                <ErrorMsg className="error">
                  One or more fields for referee #2 are missing
                </ErrorMsg>
              )}{" "}
              <Field name="ref2" component={RefForm} className="formInput" />
            </InputContainer>
            <InputContainer>
              <FormP style={{fontWeight:"bold", marginBottom: "5px"}}>
                Referee #3 <span className="required">*</span>{" "}
              </FormP>
              {errors.ref3 && touched.ref3 && (
                <ErrorMsg className="error">
                  One or more fields for referee #3 are missing
                </ErrorMsg>
              )}{" "}
              <Field name="ref3" component={RefForm} className="formInput" />
            </InputContainer>
            <br></br>

            {!context.reachedSubmit ? (
              <FormButton type="button" onClick={handleSubmit}>
                Save and Continue <ArrowForward />{" "}
              </FormButton>
            ) : (
              <FormButton type="button" onClick={handleSubmit}>
                Return to Summary Page
              </FormButton>
            )}
            {!context.reachedSubmit && (
              <FormButton
                onClick={(e) => (dirty ? back(e) : context.decrement())}
              >
                Back
              </FormButton>
            )}{" "}
             <FormikErrorFocus
            offset={0}
            align={'middle'}
            focusDelay={100}
            ease={'out-bounce'}
            duration={200}
          />
          </FormSection>
        )}
      </Formik>
    </FormSection>
  );
};

export default Referees;
