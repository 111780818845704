import React, {useState, useEffect} from "react"
import {Sidebar} from "./Sidebar";
import {Main} from "./Main"
import {PractitionerDashboardProvider} from "./PractitionerDashboardContext"
import {MobileSidebar} from "./MobileSidebar";
import { Switch, Route } from "react-router-dom";
import {Forms} from "./Forms"
import {Search} from "./Search/Search"
import {VacancyViewPage} from "./Search/VacancyViewPage"
import {DetailsPage} from "./DetailsPage"
import {OngoingVacancies} from "./OngoingVacancies"
import {ProfProfile} from "./ProfProfile"
import { PersonalInformation } from "./PersonalInformation";

function PractitionerDashboard()
{
    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);
    const [user, setUser] = useState();

    useEffect(() => {
        const loggedInUser = localStorage.getItem("user");
        if (loggedInUser) {
          const foundUser = JSON.parse(loggedInUser);
          setUser(foundUser);
        }
    }, []);

    return(
    <PractitionerDashboardProvider>
        <Sidebar isOpen={isOpen} />
        <MobileSidebar toggle={toggle} setIsOpen={setIsOpen} isOpen={isOpen}/>
        <Main toggle={toggle} isOpen={isOpen}>
        <Switch>
            <Route exact path="/practitionerDashboard/healthForms" component={Forms} />
            <Route exact path="/practitionerDashboard/search" component={Search} />
            <Route exact path="/practitionerDashboard/search/showOne" component={VacancyViewPage} />
            <Route exact path="/practitionerDashboard/ongoingVacancies" component={OngoingVacancies} />
            <Route exact path="/practitionerDashboard/ongoingVacancies/show" component={DetailsPage} />
            <Route exact path="/practitionerDashboard/profile" component={PersonalInformation} />
            <Route exact path="/practitionerDashboard/proProfile" component={ProfProfile} />
        </Switch>
        </Main>
    </PractitionerDashboardProvider>
    )
}

export default PractitionerDashboard;
