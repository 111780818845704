import styled from "styled-components";
import { Link } from "react-scroll";

export const Button = styled(Link)`
  border-radius: 5px;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  background: ${({ primary }) => (primary ? "#63A9FF" : "#010606")};
  white-space: nowrap;
  color: ${({ dark }) => (dark ? "#010606" : "#fff")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#fff" : "#01BF71")};
  }
`;

export const GeneralButton = styled.button`
  background: #63a9ff;
  padding: 16px 0;
  border: none;
  border-radius: 50px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 10px;

  &:hover {
    opacity: 0.8;
    transition: opacity 0.25s ease-in;
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    background: grey;
    opacity: 0.8;
  }
`;
