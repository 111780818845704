import React, {useEffect, useState } from 'react';
import {useHistory} from "react-router-dom"
import UserService from "../../services/userService"
import {handleErr} from "../../helpers/handleErr"

const PractitionerDashboardContext = React.createContext();

const PractitionerDashboardProvider = (props) => {
    const [res, setRes] = useState("");

    const [acceptedVacancies, setAcceptedVacancies]=useState("")
    const [appliedVacancies, setAppliedVacancies]=useState("")
    const [vacancyID, setVacancyID]=useState("")
    const [providerID, setProviderID]=useState("")

    const [loading, setLoading] = useState(false);
    const [pracData, setPracData] = useState("");

    let history = useHistory();

    const getAcceptedVacancies = () => {
        UserService.getAcceptedVacanciesLink()
        .then(res=>{
            setLoading(false)
            if (!res.data) throw new Error("500: Internal Server Error - Please Try Again Later")

            setAcceptedVacancies(res.data)
            }).catch(err => {let message=handleErr(err); history.push({pathname: '/serverError', state: {message: message}})})

    }

    const getAppliedVacancies = (cognitoID) => {
        UserService.getAppliedVacanciesLink(cognitoID)
        .then(res=>{

            setLoading(false)
            if (!res.data) throw new Error("500: Internal Server Error - Please Try Again Later")

            setAppliedVacancies(res.data)
        }).catch(err => {let message=handleErr(err); history.push({pathname: '/serverError', state: {message: message}})})

    }

    const getPracInfo = () => {
        UserService.getPracInfo()
        .then(res=>{
            if (!res.data) throw new Error("500: Internal Server Error - Please Try Again Later")

             setPracData(res.data)
        }).catch(err => {let message=handleErr(err); history.push({pathname: '/serverError', state: {message: message}})})

    }

    useEffect(() => { 
        setLoading(true)
        UserService.getPracInfo()
        .then(res=>{
            setLoading(false)
            if (!res.data) throw new Error("500: Internal Server Error - Please Try Again Later")

            if (res.data) {
                setLoading(false)
                setPracData(res.data);
                if (res.data.completedForms === "2") {
                    getAcceptedVacancies();
                    getAppliedVacancies(res.data.cognitoID);
                    history.push("/practitionerDashboard/search")
                }
                else {
                    // show forms
                    history.push("/practitionerDashboard/forms")
                }
            }
            else {
                throw new Error("Sorry, the Select-a-Med Server is currently offline. Please try again later.")
            }
        }).catch((err) => {setLoading(false);
            let message=handleErr(err);
        })
    }, [])


    return (
        <PractitionerDashboardContext.Provider
        value={{
            pracData: pracData,
            loading: loading,
            setLoading: setLoading,
            appliedVacancies:appliedVacancies,
            acceptedVacancies:acceptedVacancies,
            res: res,
            setRes: setRes,
            vacancyID:vacancyID, 
            setVacancyID:setVacancyID,
            providerID:providerID,
            setProviderID:setProviderID,
            getAppliedVacancies: getAppliedVacancies,
            getAcceptedVacancies:getAcceptedVacancies,
            getPracInfo:getPracInfo,
    
    }}
  > {props.children}
  </PractitionerDashboardContext.Provider>
  )

}

export { PractitionerDashboardProvider, PractitionerDashboardContext }
