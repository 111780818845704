import React, {useContext} from 'react'
import styled from 'styled-components'
import {FaUserAlt} from "react-icons/fa"
import {PractitionerDashboardContext} from "./PractitionerDashboardContext"

const Container = styled.div`
margin-top: 5rem;
align-items: center;
display: flex;
flex-direction: column;
`
const ProfileName = styled.p`
font-size: 1rem;
font-weight: 300;
color: white;
overflow: hidden;
`

const ProfileNameContainer = styled.div`
text-align: center;
padding: 1rem;
`

const ProfileIcon = styled(FaUserAlt)`
margin-bottom: 10px;
color: white;
`
export const Profile = () => {
    let {pracData} = useContext(PractitionerDashboardContext)
    return(
        <Container>
            <ProfileIcon size={40} />
            <ProfileNameContainer>
            <ProfileName>{pracData.title} {pracData.firstName} {pracData.lastName}</ProfileName>
            </ProfileNameContainer>
       </Container>
    )
}