export const handleErr = (err) => {
    if (err.response) {
        if (err.response === "500") {
          return "500: Internal Server Error"
        }
        if (err.response === "404") {
            return "404: Page Not Found"
        }
        if (err.response === "400") {
            return "400: Bad Request"
        }
        if (err.response === "401") {
          return "401: Unauthorised Access"
      }
        else {
          return "403: Request Forbidden"
        }
      } 
      else if (err.request) {
        return "Network Error: Please try again later"
      } 
      else {
        return err.message
      }
    }