import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ProviderDashboardContext } from "../ProviderDashboardContext";
import { FaCheck } from "react-icons/fa";
import UserService from "../../../services/userService";
import { handleErr } from "../../../helpers/handleErr";
import { Storage } from "aws-amplify";
import styled from "styled-components";
import {
  ActionBtn,
  InfoSubSection,
  InfoSection,
  H1,
  H2,
  Paragraph,
  Footer,
  BackBtn,
  BackIcon,
} from "./PractitionerElements";
import { MiniLoader } from "../../Misc/MiniLoader";
export const PractitionerReviewPage = () => {
  let history = useHistory();
  let practitioner = history.location.state.practitioner;
  let vacancyID = history.location.state.vacancyID;

  let { setLoading , getData} = useContext(ProviderDashboardContext);
  let [qual, setQual] = useState("");
  let [refs, setRefs] = useState("");
  let [empHistory, setEmpHistory] = useState("");
  let [fileErrMsg, setFileErrMsg] = useState("");
  let [resMessage, setResMessage] = useState("");
  let [pending, setPending] = useState(false);

  const getPresignedUrl = (prefix) => {
    Storage.get(prefix)
      .then((data) => {
        window.open(data);
      })
      .catch((err) => {
        throw new Error("Could not get the form, please try again later");
      });
  };

  const downloadNSWHealthForm = () => {
    UserService.getPracIdentityID(practitioner.practitionerid)
      .then((res) => {
        let pracIdentityID = res.data[0].IdentityID;
        let prefix = `practitioners/${pracIdentityID}/healthForms/NSW-health-form.pdf`;
        getPresignedUrl(prefix);
      })
      .catch((err) => {
        setFileErrMsg(err);
      });
  };

  const downloadResume = () => {
    UserService.getPracIdentityID(practitioner.practitionerid)
      .then((res) => {
        let pracIdentityID = res.data[0].IdentityID;
        let prefix = `practitioners/${pracIdentityID}/resume`;
        getPresignedUrl(prefix);
      })
      .catch((err) => {
        setFileErrMsg(err);
      });
  };

  const accept = () => {
    setLoading(true);
    UserService.acceptPractitionerLink({
      practitionerID: practitioner.practitionerid,
      vacancyID: vacancyID,
    })
      .then((res) => {
        setLoading(false);
        if (res.data.message === "Success") {
          setResMessage(
            "You have successfully accepted this practitioner for this vacancy."
          );
          getData();
        }
      })
      .catch((err) => {
        setLoading(false);
        let message = handleErr(err);
        history.push({ pathname: "/serverError", state: { message: message } });
      });
  };

  // get qualifications and employment history
  useEffect(() => {
    setPending(true);
    UserService.pracGetQualLink(practitioner.practitionerid)
      .then((res) => {
        if (!res.data)
          throw new Error(
            "500: Internal Server Error - Please Try Again Later"
          );
        setQual(res.data);
        UserService.getEmploymentHistoryLink(practitioner.practitionerid)
          .then((res) => {
            if (!res.data)
              throw new Error(
                "500: Internal Server Error - Please Try Again Later"
              );
            setEmpHistory(res.data);
            UserService.getRefereesLink(practitioner.practitionerid)
              .then((res) => {
                if (!res.data)
                  throw new Error(
                    "500: Internal Server Error - Please Try Again Later"
                  );
                setRefs(res.data);
                setPending(false);
              })
              .catch((err) => {
                setPending(false);
                let message = handleErr(err);
                history.push({
                  pathname: "/serverError",
                  state: { message: message },
                });
              });
          })
          .catch((err) => {
            setPending(false);
            let message = handleErr(err);
            history.push({
              pathname: "/serverError",
              state: { message: message },
            });
          });
      })
      .catch((err) => {
        setPending(false);
        let message = handleErr(err);
        history.push({
          pathname: "/serverError",
          state: { message: message },
        });
      });
  }, []);

  const goBack = () => {
    history.goBack();
  };

  return (
    <Container>
      {pending ? (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <MiniLoader color="#63a9ff" />
        </div>
      ) : (
        <>
          <BackBtn onClick={goBack}>
            <BackIcon />
            Back to practitioners{" "}
          </BackBtn>

          {resMessage ? (
            <InfoSection>{resMessage}</InfoSection>
          ) : (
            <>
              <div style={{ textAlign: "center" }}>
                <H1 style={{ color: "black" }}>
                  {practitioner.title} {practitioner.firstname}{" "}
                  {practitioner.lastname}
                </H1>
              </div>
              <InfoSection style={{ flexDirection: "column" }}>
                <InfoSubSection
                  style={{
                    width: "100%",
                    alignItems: "left",
                    paddingBottom: "1rem",
                    borderBottom: "1px solid #f3f3f3",
                  }}
                >
                  <H2>Medical Education </H2>

                  <Paragraph>{`${practitioner.prefAreaOfWork} | ${practitioner.postGradYear} years postgraduation`}</Paragraph>

                  <Paragraph>{`${practitioner.primarymeddegree} | ${practitioner.university} (${practitioner.countryOfStudy}
        `}</Paragraph>

                  {practitioner.collegeName && (
                    <Paragraph>{`${practitioner.collegeName} | ${practitioner.collegeYearStart}
        `}</Paragraph>
                  )}

                  <Paragraph>AHPRA Number: {practitioner.ahpra}</Paragraph>
                  <Paragraph>
                    Medical Indemnity Insurance Provider:{" "}
                    {practitioner.insuranceProv}
                  </Paragraph>
                </InfoSubSection>

                {qual && qual.length > 0 && (
                  <InfoSubSection
                    style={{
                      width: "100%",
                      marginTop: "20px",
                      alignItems: "left",
                      paddingBottom: "1rem",
                      borderBottom: "1px solid #f3f3f3",
                    }}
                  >
                    <H2>Additional Medical Qualifications</H2>
                    {qual &&
                      qual.length > 0 &&
                      qual.map((item, index) => (
                        <Paragraph key={index}>
                          {item.qualificationName}
                        </Paragraph>
                      ))}
                  </InfoSubSection>
                )}

                <InfoSubSection
                  style={{
                    width: "100%",
                    alignItems: "left",
                    marginTop: "20px",
                  }}
                >
                  <H2>Contact Details</H2>
                  <Paragraph>Email: {practitioner.email}</Paragraph>
                  <Paragraph>Mobile: {practitioner.mobile}</Paragraph>
                  <Paragraph>Home Phone: {practitioner.homePhone}</Paragraph>
                  <Paragraph>Work Phone: {practitioner.workPhone}</Paragraph>
                </InfoSubSection>

                <InfoSubSection
                  style={{
                    width: "100%",
                    alignItems: "left",
                    paddingBottom: "1rem",
                    borderBottom: "1px solid #f3f3f3",
                    marginTop: "20px",
                  }}
                >
                  <H2>Past Employment</H2>

                  {empHistory &&
                    empHistory.length > 0 &&
                    empHistory.map((item, index) => (
                      <div key={index} style={{ paddingBottom: "5px" }}>
                        <Paragraph>Employer: {item.employer}</Paragraph>
                        <Paragraph>
                          Dates: {item.startDate} - {item.endDate}
                        </Paragraph>

                        <Paragraph>Position: {item.position}</Paragraph>
                        <Paragraph>
                          Supervisor: {item.supervisorFirstName}{" "}
                          {item.supervisorLastName}
                        </Paragraph>
                        <Paragraph>
                          Supervisor Phone: {item.supervisorPhone}{" "}
                        </Paragraph>
                        <Paragraph>
                          Supervisor Email: {item.supervisorEmail}
                        </Paragraph>
                      </div>
                    ))}
                </InfoSubSection>

                <InfoSubSection
                  style={{
                    width: "100%",
                    alignItems: "left",
                    paddingBottom: "1rem",
                    borderBottom: "1px solid #f3f3f3",
                    marginTop: "20px",
                  }}
                >
                  <H2>Referees</H2>

                  {refs &&
                    refs.length > 0 &&
                    refs.map((item, index) => (
                      <div key={index} style={{ marginBottom: "10px" }}>
                        <h3 style={{ fontSize: "16px" }}>
                          Referee #{index + 1}{" "}
                        </h3>
                        <Paragraph>
                          Name: {item.firstName} {item.lastName}
                        </Paragraph>
                        <Paragraph>Place of Work: {item.placeOfWork}</Paragraph>
                        <Paragraph>Email: {item.email}</Paragraph>
                      </div>
                    ))}
                </InfoSubSection>
              </InfoSection>

              <Footer>
                <ActionBtn onClick={downloadResume}>Download Resume</ActionBtn>
                <ActionBtn onClick={downloadNSWHealthForm}>
                  Download NSW Health Form
                </ActionBtn>
                {fileErrMsg && <Paragraph color="red">{fileErrMsg}</Paragraph>}
                <ActionBtn onClick={accept}>
                  Accept for vacancy <FaCheck />
                </ActionBtn>
              </Footer>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export const Container = styled.div`
  padding: 1rem 2rem;
`;
