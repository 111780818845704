import React from 'react'
import styled from 'styled-components'
import { Link as LinkR } from "react-router-dom";

export const MenuItem = ({link, title, info, active, makeActive, num, accessible=true}) => {
    return(
        <Container className={active ? "active" : !accessible ? "disabled" : ""} onClick={()=>makeActive(num)} >
           <MenuLink to={{pathname: link, info: info}}>{title}</MenuLink>
        </Container>
    )
}

const Container = styled.div`
width: 100%; 
padding: 0.3rem; 
padding-left: 2rem;
cursor: pointer;
display: flex;
flex-direction: row;
align-items: center;
margin-bottom: 1rem;
transition: 0.2s all ease-in-out; 

&:hover {
    background-color: rgba(0,0,0,0.1);
};

&.active {
    background-color: rgba(0,0,0,0.1);
    border-left: 3px solid #63a9ff; 
}


&.disabled {
  pointer-events: none;
  cursor: default;
  color: gray;
}
`
export const MenuLink = styled(LinkR)`
font-size: 1rem; 
margin-right: 1rem; 
font-weight: 300; 
color: white;


`;
 