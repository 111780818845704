import React, {useContext, useState} from "react"
import {useHistory} from "react-router-dom"
import {PractitionerDashboardContext} from "../PractitionerDashboardContext"
import {SessionContext} from "../../Misc/SessionContext"
import styled from "styled-components"
import {FaChevronLeft} from "react-icons/fa"
import {FaPencilAlt} from "react-icons/fa"
import UserService from "../../../services/userService"
import {handleErr} from "../../../helpers/handleErr"
import {MiniLoader} from "../../Misc/MiniLoader"

import {Container, H1, H3, Header, ActionBtn, Footer,
  Body, LoaderDiv} from "./ResultCardComponents"

export const VacancyP = styled.p`
margin-bottom: 4px;
`

export const BtnText = styled.p`
font-size: 16px;
font-weight: bold;
`

export const ResMessage = styled.p`
color: navy;
margin-bottom: 10px;

`

export const PageWrapper = styled.div`
padding-left: 2rem;
padding-right: 2rem;
`

export const ExtrasWrapper = styled.div`
margin-bottom: 10px;
`

export const MsgContainer = styled.div`
align-items: center;
display: flex;
flex-direction: column;
padding: 2rem;
background: #f3f3f3;
border-radius: 10px

`

const levels = ["JMO", "RMO", "VMO", "CMO", "SMO", "Registrar", "Fellow", "Consultant"]
const convertDateFormat = (date) => {
  let newDate = date.split("-").reverse().join("/");
  return newDate
}

export const VacancyViewPage = (props) => {

  let history = useHistory();
  let currVacancyFocus;

  if (history.location.state === undefined) {
    history.push("/practitionerDashboard/search")
  }
  else {
    currVacancyFocus = history.location.state;
  }

  const currUser = useContext(SessionContext).getLoggedInUser()
  const {getAppliedVacancies, res, setRes} = useContext(PractitionerDashboardContext)
  const data = {vacancyID: currVacancyFocus.vacancyid, providerID:currVacancyFocus.providerid,
  currUser:currUser}
  const [resMessage, setResMessage] = useState(false)
  const [pending, setPending] = useState(false)

  const handleClick = () => {
    history.goBack();
  }

    const handleApply = () => {
      setPending(true);
        UserService.applyForVacancy(data)
          .then(response=>{
            setPending(false);
            if (response.data.message === "Success") {
              getAppliedVacancies(currUser)
              setResMessage(`Your application has been sent to ${currVacancyFocus.providername}.`)
              console.log(res);
            let resCpy = res;
            resCpy = resCpy.filter(r => r.vacancyid !== data.vacancyID)
            console.log(resCpy);
            
            setRes(resCpy)
            }
          })
          .catch(err=>{setPending(false);let message=handleErr(err); history.push({pathname: '/serverError', state: {message: message}})})
        }


      const handleWithdraw = () => {
        setPending(true);
        UserService.withdrawFromVacancy(data)
          .then(response=>{
            setPending(false);
            if (response.data.message === "Success") {
            getAppliedVacancies(currUser)
        
            setResMessage(`Your application has been withdrawn.`)
            }
          })
          .catch(err=>{setPending(false); let message=handleErr(err); history.push({pathname: '/serverError', state: {message: message}})})
      }


      if (resMessage) return <PageWrapper><MsgContainer>
        <ResMessage>{resMessage}</ResMessage>
        <ActionBtn onClick={handleClick}>
          <BtnText><FaChevronLeft /> Return to search</BtnText>
       </ActionBtn>
       </MsgContainer></PageWrapper>;
    
    return (
    <PageWrapper>
    <Container className={pending && "disabled"}>
      <Header>
        <ActionBtn onClick={handleClick}>
      <BtnText><FaChevronLeft /> Return to search</BtnText>
      </ActionBtn>
      </Header>

      <Body>
      <ExtrasWrapper>
      <H1>{currVacancyFocus.specialty} {levels[currVacancyFocus.traininglevel-1]}</H1>
      <H3>{currVacancyFocus.providername}</H3>

      <H3 style={{display: "inline", marginRight: "5px"}}>{convertDateFormat(currVacancyFocus.startdate)}</H3>
          -  
      <H3 style={{display: "inline", marginLeft: "5px", marginRight: "5px"}}>{convertDateFormat(currVacancyFocus.enddate)}</H3>
      </ExtrasWrapper>

      <VacancyP>{currVacancyFocus.starttime} - {currVacancyFocus.endtime}</VacancyP>
      <VacancyP>${currVacancyFocus.rate} per hour</VacancyP>
      {currVacancyFocus.accommodation === "provided" ? <VacancyP>Accommodation Provided</VacancyP> : ""}
      {currVacancyFocus.accommodation === "notProvided" ? <VacancyP>Accommodation Not Provided</VacancyP> : ""}
      {currVacancyFocus.accommodation === "allowance" ? <VacancyP>Accommodation Allowance of ${currVacancyFocus.accallowance}</VacancyP> : ""}

      {currVacancyFocus.travel === "provided" ? <VacancyP>Travel Provided</VacancyP> : ""}
      {currVacancyFocus.travel === "notProvided" ? <VacancyP>Travel Not Provided</VacancyP> : ""}
      {currVacancyFocus.travel === "allowance" ? <VacancyP>Travel Allowance of ${currVacancyFocus.travelallowance}</VacancyP> : ""}
      {currVacancyFocus.oncall === "1" ? <VacancyP>On Call</VacancyP> : ""}      

<Footer>
      {currVacancyFocus.dateapplied ? <ActionBtn onClick = {handleWithdraw}><BtnText>Withdraw</BtnText></ActionBtn>
 :       <ActionBtn onClick = {handleApply}>{!currVacancyFocus.dateapplied ? <BtnText>Apply <FaPencilAlt /></BtnText>: ""}</ActionBtn>
    }

    </Footer>
    <LoaderDiv>
        {pending && <MiniLoader color="#69a3ff"/>}
      </LoaderDiv>
      </Body>


        </Container>
        </PageWrapper>

    )
}