import TimePicker from 'react-time-picker/dist/entry.nostyle';

import {useField, useFormikContext} from "formik"

export const TimePickerField = (props) => {
    const { setFieldValue, values } = useFormikContext();
    const [field] = useField(props);
return (
<TimePicker
      {...field}
      name={props.name}
      className="reactTimePicker"
      locale="en-us"
      customStyles={{dateInput:{borderWidth: 0}}}

      disableClock={true}
      onChange={(time)=>setFieldValue(props.name, time)}
      value={values[props.name]}
/> )
}






