import React, {useState} from "react"
import {Auth} from "aws-amplify"

const SessionContext = React.createContext();

const SessionContextProvider = (props) => {

  const getSessionCookie = async () => await Auth.currentSession()

  const getLoggedInUser = async () => {
    let session = await getSessionCookie()
    try {
      let currUser = session.accessToken.payload.sub
      return currUser; 
    }
    catch {
      return new Error("No user logged in")
    }
  }

  const getLoggedInUserInfo = async () => {
    let session = await getSessionCookie()
    try {
      let currUser = session.accessToken.payload
      return currUser; 
    }
    catch {
      return new Error("No user logged in")
    }
  }

  const getAccessToken = async () => {
    let session = await getSessionCookie()
    try {
      let accessToken = session.accessToken
      return accessToken; 
    }
    catch {
      return new Error("No user logged in")
    }
  }

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState();

  const handleLogout = async () => {
    try {
        //await Auth.signOut({ global: true });
        setUser({});
        setEmail("");
        setPassword("");
        localStorage.clear();
    }
    catch (err) {
      return err
    }
    finally {
    }
  }



  return (
  <SessionContext.Provider
  value={{getSessionCookie, getLoggedInUser, getLoggedInUserInfo, handleLogout, getAccessToken}}
> {props.children}
</SessionContext.Provider>
)

}

export { SessionContextProvider, SessionContext }

