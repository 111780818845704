import React from "react";
import {
  FormP,
  ErrorMsg,
  DeleteIcon,
  EditIcon,
  BtnSection,
  IconWrap,
} from "../../SignUpElements";
import styled from "styled-components"

export const EmpInfoCard = ({
  work,
  index,
  setAdding,
  setEditInfo,
  setEmpNum,
  deleteHandler,
  errorMsg,
}) => {
  console.log(work)
  return (
    <Container>
      {work.employer && (
        <>
          <FormP style={{fontWeight: "bold"}}>{`${index+1}.`}</FormP>
          <FormP>Employer: {work.employer}</FormP>
          <FormP>Position: {work.position}</FormP>
          <FormP> Start Date: {work.startDate}</FormP>
          {work.endDate && (
            <FormP> End Date: {work.endDate}</FormP>
          )}
          <FormP>
            Supervisor Name:{" "}
            {`${work.empName.firstName} ${work.empName.lastName}`}
          </FormP>
          <FormP>Supervisor telephone: {work.empContactNum}</FormP>
          <FormP>Supervisor email: {work.empContactEmail}</FormP>

          <BtnSection>
            <IconWrap>
              <DeleteIcon
                onClick={() => {
                  deleteHandler(index);
                }}
              />
            </IconWrap>
            <IconWrap>
              <EditIcon
                onClick={() => {
                  setAdding(true);
                  setEditInfo(work);
                  setEmpNum(index);
                }}
              />
            </IconWrap>
          </BtnSection>
          <ErrorMsg>{errorMsg}</ErrorMsg>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
background: #f8f8f8;
padding: 0.5rem;
border-radius: 10px;
`

export default EmpInfoCard;
