import React, { useState } from "react";
import { Auth } from "aws-amplify";
import {ProvideEmailContainer} from "./ProvideEmailContainer";
import {EnterCodeandPWord} from "./EnterCodeandPWord";

const ForgotPassword = ({ setVerificationMessage, setForgotPassword }) => {
  const [stage, setStage] = useState(1);  
  const [email, setEmail] = useState("");

  const sendCode = () => {
    Auth.forgotPassword(email)
      .then((data) => {
        setStage(2);
        return data;
      })
      .catch((err) => err);
  };

  const resetPassword = async (email, confirmedPassword, code, password) => {
      return new Promise((resolve, reject) => {
    if (password !== confirmedPassword) {
      reject({errMsg: "Please ensure that the two passwords are equal"});
      return;
    }
    Auth.forgotPasswordSubmit(email, code, password)
      .then((data) => {
        // return to login
        setForgotPassword(false);
        setVerificationMessage("Successfully reset password.");
        resolve(data);
      })
      .catch((err) => {
        if (err.code === "CodeMismatchException") reject({errMsg: "Invalid code - please try again."})
        if (err.code === "LimitExceededException") reject({errMsg: "Attempt limit exceeded, please try again later."})
        if (err.code === "InvalidParameterException") reject({errMsg: "Please enter a password of the appropriate format."})
        else reject({errMsg: "Internal server error - please try again later."})
      });
    })
}

  return (
      <>
      {stage === 1  && 
  <ProvideEmailContainer 
  setForgotPassword={setForgotPassword} 
  sendCode={sendCode} setEmail={setEmail} email={email} />
  

  


  
      }
  {stage === 2 && 

  (<EnterCodeandPWord setStage={setStage} email={email} setEmail={setEmail} resetPassword={resetPassword}/>
  
  )}
  </>
  )}

export default ForgotPassword;
