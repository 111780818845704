import React, { useContext, useState, useEffect } from "react";
import {useHistory} from "react-router-dom";
import {
  FormH2,
  FormLabel,
  FormInput,
  FormButton,
  FormSection,
  FormSubSection,
  ArrowForward,
  FormP,
  SubFormButton,
  ErrorMsg,
  FormSelect,
} from "../../SignUpElements";
import { Formik, Field } from "formik";
import { StepContext } from "../../StepContext";
import * as yup from "yup";
import { docSchema } from "../../../../supportedFileSettings.js";
import {MAX_NAME_LENGTH, MAX_DESC_LENGTH}  from "../../../../helpers/characterLimits"; 
import UserService from "../../../../services/userService"
import {handleErr} from "../../../../helpers/handleErr"
import FormikErrorFocus from 'formik-error-focus'
import Dropzone from "../../../Forms/ReactDropZone"
const Qualifications = ({
  AHPRA,
  primaryMedDegree,
  otherQualifications,
  insurance,
  passport,
  drivers,
  postGradYear,
  areaOfTraining, 
  college,
  submitHandler,
}) => {

  const AHPRAregex = /^\b(ATS|CHI|CMP|DEN|MED|MRP|NMW|OCC|PSY)(000)\d{7}\b/;
  const yearRegex = /^(19|20)\d{2}$/;
  const [saved, setSaved] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [isMounted, setIsMounted] = useState(false);
  let history = useHistory();

  const back = (e) => {
    e.preventDefault();
    if (!saved) {
      context.backStepAlert();
    }
  };

  useEffect(()=> {
    window.scrollTo(0, 0)
    setIsMounted(true);
    return () => setIsMounted(false);
  }, [isMounted])

  const context = useContext(StepContext);
  const initialValues = {
    AHPRA: AHPRA,
    primaryMedDegree: primaryMedDegree,
    otherQualifications: otherQualifications,
    insurance: insurance, 
    postGradYear: postGradYear, 
    passport: passport,
    drivers:drivers,
    areaOfTraining: areaOfTraining,
    college:college
  };
  const validationSchema = yup.object().shape({
    AHPRA: yup.object().shape({
      AHPRANum: yup
        .string()
        .required("Please enter an AHPRA number.")
        .matches(AHPRAregex, "AHPRA number is not valid."),
      AHPRADoc: docSchema("AHPRA.AHPRADoc"),
    }),
    primaryMedDegree: yup.object().shape({
      degreeName: yup
        .string()
        .required("Please enter the name of your primary medical degree. "),
      degreeDoc: docSchema("primaryMedDegree.degreeDoc"),
      uni: yup.object().shape({
        name:       yup.string().required("Please enter the name of the university where you obtained your medical degree."),
        country: yup.string().required("Please provide the country where you obtained your medical degree.")
      })
    }),
    insurance: yup.object().shape({
      providerName: yup.string().required("Please provide an insurance provider."),
      insuranceDoc:  docSchema("insurance.insuranceDoc"),
    }),
    passport: docSchema("passport"),
    drivers: docSchema("drivers"),
    areaOfTraining: yup.string().required("Please enter your area of training."),
    postGradYear: yup.string().required("Please enter a number."),
    college: yup.object().shape({
      registered: yup.string().required("Please enter whether you are registed in a medical college."),
      name: yup.string().nullable().when("registered", {
        is: "yes",
        then: yup.string().required("Please enter the name of the medical college.")
      }),
      year: yup.string().nullable().when("registered", {
        is: "yes",
        then: yup.string().matches(yearRegex, "Invalid Year").required("Please enter the starting year.")
      })

    }),
    otherQualifications: yup.array().of(
      yup.object().shape(
        {
          qualificationName: yup.string().when("qualificationDoc", {
            is: (name) => name,
            then: yup
              .string()
              .required(
                "Please provide the name of the qualification for which you have provided documentation."
              ),
          }),
          qualificationDoc: yup.mixed().when("qualificationName", {
            is: (name) => name && name.length > 0,
            then: docSchema("qualificationDoc"),
          }),
        },
        [["qualificationName", "qualificationDoc"]]
      )
    ),
  });

  return (
    <FormSection id="identity">
      <FormH2> 2. Qualifications </FormH2>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {

          values.otherQualifications = values.otherQualifications.filter(qual => 
            qual.qualificationDoc !== "" && qual.qualificationName !== "")

          UserService.checkAHPRALink(values.AHPRA.AHPRANum)
          .then(res=>{
            if (res.data.message === "Valid AHPRA") {
              submitHandler({ qualifications: values });
              setSaved(true);
               if (context.reachedSubmit) {
                 context.setStep(context.REVIEW_STEP);
               }
               else {
                 context.increment();
               }
              }
            else {
              setErrMsg(res.data.message)
            }
          })
          .catch(err => {let message=handleErr(err); history.push({pathname: '/serverError', state: {message: message}})})


        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          dirty,
          handleSubmit,
        }) => (
          <FormSection>
            <FormSubSection>
              <FormLabel>
                AHPRA Number <span className="required">*</span>{" "}
              </FormLabel>
              <Field name="AHPRA.AHPRANum" className="formInput" onChange={e=>{setFieldValue("AHPRA.AHPRANum", 
              e.target.value);setErrMsg("")}}/>
              {errors.AHPRA && touched.AHPRA && (
                <ErrorMsg>{errors.AHPRA.AHPRANum}</ErrorMsg>
              )}
              <ErrorMsg>{errMsg}</ErrorMsg>

              <Dropzone title="AHPRA Certificate" required={true} name="AHPRA.AHPRADoc"/>
              {errors.AHPRA && touched.AHPRA &&
              errors.AHPRA.AHPRADoc && touched.AHPRA.AHPRADoc &&
              (
                <ErrorMsg>{errors.AHPRA.AHPRADoc}</ErrorMsg>
              )}

              </FormSubSection>

              <FormSubSection>
              <FormLabel>
                Name of Primary Medical Degree <span className="required">*</span>{" "}
              </FormLabel>
              <Field name="primaryMedDegree.degreeName" className="formInput" maxLength={MAX_DESC_LENGTH} />
              {errors.primaryMedDegree && touched.primaryMedDegree && 
              errors.primaryMedDegree.degreeName && touched.primaryMedDegree.degreeName && 
              (
                <ErrorMsg>{errors.primaryMedDegree.degreeName}</ErrorMsg>
              )}



              <FormLabel>
                University where medical degree awarded <span className="required">*</span>{" "}
              </FormLabel>
              <Field name="primaryMedDegree.uni.name" className="formInput" maxLength={MAX_DESC_LENGTH}/>
              {(errors.primaryMedDegree && touched.primaryMedDegree &&
              errors.primaryMedDegree.uni && touched.primaryMedDegree.uni && 
              errors.primaryMedDegree.uni.name && touched.primaryMedDegree.uni.name)
              ? <ErrorMsg>{errors.primaryMedDegree.uni.name}</ErrorMsg> : null}

              <FormLabel>
                Country where degree awarded <span className="required">*</span>{" "}
              </FormLabel>
 
               <Field name="primaryMedDegree.uni.country">
                {({ field, form: { touched, errors }, meta }) => (
                  <>
                  <FormSelect
                {...field} style={{ width: "100%" }}

              >
                <option value="" label="Select country ... " />
                <option value="Australia" label="Australia" />
                <option value="New Zealand" label="New Zealand" />
                <option value="Overseas" label="Overseas" />
              </FormSelect>
              {meta.touched && meta.error && (
              <ErrorMsg className="error">{meta.error}</ErrorMsg>
              )}
              </>
                )}
              </Field>

              
              <Dropzone title="Supporting documentation for primary medical degree" 
              required={true} name="primaryMedDegree.degreeDoc"/>

              {errors.primaryMedDegree && touched.primaryMedDegree &&
              errors.primaryMedDegree.degreeDoc && touched.primaryMedDegree.degreeDoc &&
              (
                <ErrorMsg>{errors.primaryMedDegree.degreeDoc}</ErrorMsg>
              )}

</FormSubSection>
<FormSubSection>


                          <FormLabel>
                Area of Training
                <span className="required">*</span>{" "}
              </FormLabel>
              
              <Field name="areaOfTraining">
                {({ field, form: { touched, errors }, meta }) => (
                  <>
                        <FormSelect
                {...field} style={{ width: "100%" }}
              >
                <option value="" label="Select area of training ... " />
                <option value="Anasthetics" label="Anasthetics" />
                <option value="Emergency" label="Emergency" />
                <option value="General Practice" label="General Practice" />
                <option value="ICU/CCU" label="ICU/CCU" />
                <option value="OB/GYN" label="OB/GYN" />
                <option value="Paediatrics" label="Paediatrics" />
                <option value="Psychiatry" label="Psychiatry" />
                <option value="Other" label="Other" />

              </FormSelect>
              {meta.touched && meta.error && (
              <ErrorMsg className="error">{meta.error}</ErrorMsg>
              )}
              </>
              )}
              </Field>

              <FormLabel>
                Post Graduate Year
                <span className="required">*</span>{" "}
              </FormLabel>

              <Field name="postGradYear">
                {({ field, form: { touched, errors }, meta }) => (
                  <>
                  <FormSelect
                {...field} style={{ width: "100%" }}
              >
                <option value="" label="Select year ... " />
                <option value="1" label="1" />
                <option value="2" label="2" />
                <option value="3" label="3" />
                <option value="4" label="4" />
                <option value="5" label="5" />
                <option value="6" label="6" />
                <option value="7" label="7" />
                <option value="8" label="8" />
                <option value="9" label="9" />
                <option value="10" label="10" />

              </FormSelect>
              {meta.touched && meta.error && (
              <ErrorMsg className="error">{meta.error}</ErrorMsg>
              )}
              </>
              )}
              </Field>


              <FormLabel>
                Are you registered in a Medical College? <span className="required">*</span>{" "}
              </FormLabel>

          <table>
            <tbody>
              <tr>
                <td>
          <FormLabel>Yes</FormLabel>


          <FormInput
            type="radio"
            id="yes"
            name="college.registered"
            value="yes"
            onChange={(value) => setFieldValue("college.registered", "yes")}
            checked={values.college.registered === "yes"}
          />
          </td>
          <td>
          <FormLabel>No</FormLabel>

          <FormInput
            type="radio"
            id="no"
            name="college.registered"
            value="no"
            onChange={(value) => setFieldValue("college.registered", "no")}
            checked={values.college.registered === "no"}
          />
          </td>
          </tr>
          </tbody>
          </table>





              {values.college && values.college.registered === "yes" ? 
              <>
              <FormLabel>
                Name of College <span className="required">*</span>{" "}
              </FormLabel>
              <Field name="college.name" className="formInput" maxLength={MAX_NAME_LENGTH}/>
              {errors.college && touched.college && (
                <ErrorMsg>{errors.college.name}</ErrorMsg>
              )}
              
              <FormLabel>
                Starting Year <span className="required">*</span>{" "}
              </FormLabel>
              <Field name="college.year" className="formInput" />
              {errors.college && touched.college && (
                <ErrorMsg>{errors.college.year}</ErrorMsg>
              )}
              
              </> : null }
              
              </FormSubSection>

              

              <FormSubSection>
              <FormLabel>
                Medical Insurance Provider <span className="required">*</span>{" "}
              </FormLabel>
              <Field name="insurance.providerName">
                {({ field, form: { touched, errors }, meta }) => (
                  <>
                  <FormSelect {...field} style={{ width: "100%" }}>
                    <option
                      value=""
                      label="Please select an insurance provider... "
                    />
                    <option value="Avant" label="Avant" />
                    <option
                      value="BHSIC"
                      label="Berkshire Hathaway Specialty Insurance Company (distributed by Tego Insurance)"
                    />
                    <option value="MDAN" label="MDA National Limited" />
                    <option
                      value="MIGA"
                      label="Medical Insurance Group Australia (MIGA)"
                    />
                    <option
                      value="MIPS"
                      label="Medical Indemnity Protection Society (MIPS)"
                    />
                    </FormSelect>
                    {meta.touched && meta.error && (
                      <ErrorMsg className="error">{meta.error}</ErrorMsg>
                    )}

                  </>
                )}
              </Field>
            

              <Dropzone name="insurance.insuranceDoc" required={true} title="Insurance Certificate"/>

              {errors.insurance && touched.insurance && (
                <ErrorMsg>{errors.insurance.insuranceDoc}</ErrorMsg>
              )}
              </FormSubSection>
              
              <FormSubSection>

              
              <Dropzone name="passport" required={true} title="Passport"/>
              {errors.passport && touched.passport && (
                <ErrorMsg>{errors.passport}</ErrorMsg>
              )}

</FormSubSection>

             
<FormSubSection>

<Dropzone name="drivers" required={true} title="Drivers License"/>
{errors.drivers && touched.drivers && (
  <ErrorMsg>{errors.drivers}</ErrorMsg>
)}

</FormSubSection>



            <FormSubSection>
              <FormLabel>
                Other medical qualifications 
              </FormLabel>
              {values.otherQualifications &&
                values.otherQualifications.map((qualification, index) => {
                  return (
                    <FormSubSection key={index}>
                      <FormP>
                        Qualification name <span className="required">*</span>{" "}
                      </FormP>
                      <Field
                        name={`otherQualifications[${index}].qualificationName`}
                        className="formInput"
                        maxLength={MAX_DESC_LENGTH}
                      />
                      {errors.otherQualifications &&
                        errors.otherQualifications[index] &&
                        touched.otherQualifications &&
                        touched.otherQualifications[index] &&
                        touched.otherQualifications[index].qualificationName && (
                          <ErrorMsg>
                            {
                              errors.otherQualifications[index]
                                .qualificationName
                            }
                          </ErrorMsg>
                        )}
                      <Dropzone title="Supporting documentation" required={true} name={`otherQualifications[${index}].qualificationDoc`}/>

                      {errors &&
                      errors.otherQualifications &&
                      errors.otherQualifications[index] &&
                      errors.otherQualifications[index].qualificationDoc && (
                        <ErrorMsg>
                          {errors.otherQualifications[index].qualificationDoc}
                        </ErrorMsg> )}
                      </FormSubSection>


                  );
                })}
              <SubFormButton
                type="button"
                onClick={(e) => {
                  let prev = values.otherQualifications;
                  if (typeof prev === "undefined" || prev.length === 0) {
                    setFieldValue("otherQualifications[0]", {
                      qualificationName: "",
                      qualificationDoc: "",
                    });
                  } else if (
                    (errors && errors.otherQualifications) ||
                    (!values.otherQualifications.slice(-1)[0]
                      .qualificationName &&
                      !values.otherQualifications.slice(-1)[0].qualificationDoc)
                  ) {
                  } else {
                    prev.push({ qualificationName: "", qualificationDoc: "" });
                    setFieldValue("otherQualifications", prev);
                  }
                }}
              >
                Add another qualification...
              </SubFormButton>{" "}
            </FormSubSection>
            {!context.reachedSubmit ? (
              <FormButton type="button" onClick={(handleSubmit)}>
                Save and Continue <ArrowForward />{" "}
              </FormButton>
            ) : (
              <FormButton type="button" onClick={handleSubmit}>
                Return to Summary Page
              </FormButton>
            )}
            {!context.reachedSubmit && (
              <FormButton
                onClick={(e) => (dirty ? back(e) : context.decrement())}
              >
                Back
              </FormButton>
            )}
             <FormikErrorFocus
            offset={0}
            align={'middle'}
            focusDelay={100}
            ease={'out-bounce'}
            duration={200}
          />

                                
          </FormSection>
        )}
        
      </Formik>
    </FormSection>

  );
};

export default Qualifications;
