import React from 'react'
import styled from 'styled-components'
import {FaUserAlt} from "react-icons/fa"

const Container = styled.div`
margin-top: 5rem;
align-items: center;
display: flex;
flex-direction: column;
color: white;

`

const ProfileName = styled.p`
font-size: 1rem;
font-weight: 400;
color: white;

`

const ProfileIcon = styled(FaUserAlt)`
margin-bottom: 20px;
`

export const Profile = ({provName}) => {
    return(
        <Container>
            <ProfileIcon size={40} />
            <ProfileName>{provName}</ProfileName>
        </Container>

    )
}