import React, { useState, useEffect } from "react";
import { Auth } from 'aws-amplify';
import {useHistory} from "react-router-dom"
import {
    Container,
    FormLabel,
    FormWrap,
    FormSection,
    FormButton,
    FormInput,
    FormH1,
    ErrorMsg} from "../SignIn/SignInElements"
import {MiniLoader} from "../Misc/MiniLoader"
import styled from "styled-components";
import {SignInAndRegHeader} from "../SignInAndRegHeader"
export const AdministratorSignInPage = (props) => {

  const [errMsg, setErrMsg] = useState(true);
  const history = useHistory(); 
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault()
        if (username && password) {
          handleLogin(username, password)
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);



  const handleLogin = (username,password) => {
    setLoading(true);
    Auth.signIn(username, password)
    // session now available
    .then(user => {
      console.log(props.location.state.from.pathname + props.location.state.from.search)
      setLoading(false);
      // push !
      history.push(props.location.state.from.pathname + props.location.state.from.search)
    }).catch(e => {
        setErrMsg(e.message);
        setLoading(false);
    })
}


return (
  <Wrapper>
<SignInAndRegHeader />






<Container>
  <FormWrap>
    <FormSection>
            <FormH1>Administrator Sign In</FormH1>
            <FormLabel>
              Username<span className="required">*</span>
            </FormLabel>
            <FormInput
              disabled={loading}
              type="text"
              name="username"
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
            />
            <FormLabel> 
              Password<span className="required">*</span> 
            </FormLabel>
            <FormInput
              disabled={loading}
              type="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
            {errMsg && <ErrorMsg>{errMsg}</ErrorMsg>}
            <FormButton className={loading && "disabled"} type="button" onClick={()=>handleLogin(username, password)}>
              {loading ? <MiniLoader /> : "Sign In"}
            </FormButton>
            </FormSection>

      </FormWrap>












</Container>
</Wrapper>
)
}

export const Wrapper = styled.div`
background: green
margin: 0 auto;
`


