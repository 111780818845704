import React, { useState, useEffect } from "react";
import {Container, MessageWrap, MessageContent, MessageButton, MessageText, BtnText} from "../Misc/MessageElements"
import { MiniLoader } from "../Misc/MiniLoader";
import queryString from 'query-string'
import {useHistory} from "react-router-dom"
import UserService from "../../services/userService"
import {handleErr} from "../../helpers/handleErr"
import {requestTypes} from "../../helpers/constants"

export const AdminActionPage = (props) => {
  const [loading, setLoading] = useState(true)
  const [msgText, setMsgText] = useState("")
  let history = useHistory()

  // check no session
  useEffect(() => {
    const values = queryString.parse(props.location.search);
    if (values.type===requestTypes.PROVIDER && values.providerID) {
      UserService.adminCreateNewProvider({providerID:values.providerID})
      .then(res=>{
        setLoading(false);
        setMsgText(res.data.message);
      }
      )
      .catch(err=>{
        setLoading(false);
        setMsgText(err);
      })
    }
    if (values.type===requestTypes.PRACTITIONER && values.pracID) {
      console.log("Add practitioner")
      UserService.adminCreateNewPractitioner({pracID:values.pracID})
      .then(res=>{
        setLoading(false);
        setMsgText(res.data.message);
      }
      )
      .catch(err=>{
        setLoading(false);
        setMsgText(err.request);
      })
    }
    else if (values.type===requestTypes.HEALTH_FORM && values.userID) {
      UserService.approveHealthFormLink({cognitoID:values.userID})
      .then(res=>{
        console.log(res);
        setLoading(false);
        setMsgText(res.data.message);
      }
      )
      .catch(err=>{
        console.log(err)
        setLoading(false);
        let msg = handleErr(err)
        setMsgText(msg);
      })
    }


  }, [])

  return <Container>
  <MessageWrap>
  <MessageContent>

  {!loading ?   <MessageText>
{msgText}  </MessageText>
 : <MiniLoader color={"#639aff"}/>}

  {!loading && 
  <MessageButton onClick={() => history.push("/")}>
    <BtnText>Return to home page</BtnText>
  </MessageButton> 
}

  </MessageContent>
  </MessageWrap>
</Container>
}