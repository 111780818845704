
import React, {useContext, useEffect} from "react"
import {Vacancies} from "./Vacancies"
import {ProviderDashboardContext} from "./ProviderDashboardContext"
import {DetailsPage} from "./PractitionerView/DetailsPage"
import { Switch, Route } from "react-router-dom";
import styled from "styled-components";
export const Container = styled.div`
padding: 15px;
margin-left: 40px;
margin-right: 40px;
border-radius: 10px;
`
export const CurrentVacancyPage = () => {    

    let {uncompletedVacancies, getData } = useContext(ProviderDashboardContext)
    
    useEffect(() => {

        getData()
    }, [])

    return (
        <>
        <Container>
        <Switch>
        <Route exact path="/providerDashboard/currentVacancies">
                <Vacancies showBar={true} 
                title="Current Vacancies" 
                count={uncompletedVacancies.length} 
                vacancies={uncompletedVacancies} 
                action="markCompleted"/> 
            </Route>
            <Route exact path="/providerDashboard/currentVacancies/view">
                <DetailsPage />
            </Route>
        </Switch>
        </Container>
        </>

    )
}

