import React from "react"
import styled from "styled-components"
import {FaSearch} from "react-icons/fa"
import Fuse from "fuse.js";

const Search = styled.input`
border-radius: 15px;
outline: none;
border: 1px solid #d3d3d3;
height: 25px;
width: 200px;
padding: 1rem;
float:right;

`

const SearchWrapper = styled.div`
height:30px;
width:100%;
position:relative;
margin-bottom: 20px;

`

const SearchIcon = styled(FaSearch)`
height: 15px; 
position: absolute;
right: 10px;
top: 8px;
`


export const SearchBar = ({availInfo, setData}) => {

      const searchData = (pattern) => {
        if (!pattern) {
          setData(availInfo);
          return;
        }

        const fuse = new Fuse(availInfo,     
            {
                shouldSort: true,
                tokenize: true,
                matchAllTokens: true,
                findAllMatches: true,
                threshold: 0,
                location: 0,
                distance: 0,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: ["specialty", "startDate","endDate"]
              });
    
        const result = fuse.search(pattern);
        const matches = [];
        if (!result.length) {
          setData([]);
        } else {
          result.forEach(({item}) => {
            matches.push(item);
          });
          setData(matches);
        }
    }
    
    return (
    <SearchWrapper>
    <SearchIcon />
    <Search onChange={(e) => searchData(e.target.value)} placeholder="Search date, specialty..." />
    </SearchWrapper>
    )
}