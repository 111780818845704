import styled from "styled-components"

export const Container = styled.div`
margin-bottom: 20px;
margin-top: 10px;
display: flex;
flex-direction: column;
padding: 1rem;
border-bottom: 0.8px solid grey;
background: #f3f3f3;
border-radius: 10px;
`

export const H1 = styled.h1`
font-size: 20px;
margin-bottom: 3px;


`


export const H2 = styled.h2`
font-size: 18px;
margin-bottom: 3px;

`

export const H3 = styled.h3`
font-size: 16px;
margin-bottom: 3px;
`

export const DateInfo = styled.span`
color: navy;
`

export const Header = styled.div`
display: flex;
color: navy;
flex-direction: row;
justify-content: space-between;
margin-bottom: 5px;

`

export const Footer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-evenly;
margin-bottom: 10px;
background: #f3f3f3;
`

export const P = styled.p`

`

export const ActionBtn = styled.button`
outline: none;
padding: 0.;
font-weight: bold;
color:  navy;
border: none;
background: none;

&:hover {
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.5s ease-in;

}

`

export const Body = styled.div`
margin-bottom: 10px;
padding: 0.5rem;
`

export const LoaderDiv = styled.div`
align-items: center;
margin-top: 5px;
justify-content: space-around;
display: flex;
`