import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ProviderDashboardContext } from "../ProviderDashboardContext";
import { handleErr } from "../../../helpers/handleErr";
import { confirmAlert } from "react-confirm-alert";
import { FaArrowLeft, FaPencilAlt } from "react-icons/fa";
import styled from "styled-components";
import VacancyForm from "../VacancyForm";
import UserService from "../../../services/userService";
import { MiniLoader } from "../../Misc/MiniLoader";
const levels = [
  "JMO",
  "RMO",
  "VMO",
  "CMO",
  "SMO",
  "Registrar",
  "Fellow",
  "Consultant",
];

export const DetailsPage = () => {
  let history = useHistory();
   let {userData, getData} = useContext(ProviderDashboardContext);
  const [vacancyInfo, setVacancyInfo] = useState("");
  const [pending, setPending] = useState("");
  const [editMode, setEditMode] = useState(false);

  let vacancyID;

  if (history.location.state === undefined) {
    history.push("/providerDashboard/unfilledVacancies");
  }

  else {

    vacancyID = history.location.state.vacancyID;
  }


  const goBack = () => history.goBack();

  const deleteVacancy = () => {
    setPending(true);
    UserService.deleteVacancyLink({ vacancyID: vacancyID })
      .then((res) => {
        setPending(false);
        if (!res.data)
          throw new Error(
            "500: Internal Server Error - Please Try Again Later"
          );
        if (res.data.message === "Success") {
          window.scrollTo(0, 0);
          getData();
          goBack();

        }
      })
      .catch((err) => {
        setPending(false);
        let message = handleErr(err);
        history.push({ pathname: "/serverError", state: { message: message } });
      });
  };

  const removePractitioner = () => {
    setPending(true);
    UserService.removePractitionerLink({ vacancyID: vacancyID })
      .then((res) => {
        setPending(false);
        if (!res.data)
          throw new Error(
            "500: Internal Server Error - Please Try Again Later"
          );
        if (res.data.message === "Success") {
          goBack();
        }
      })
      .catch((err) => {
        setPending(false);
        let message = handleErr(err);
        history.push({ pathname: "/serverError", state: { message: message } });
      });
  };

  const handleDeletionPress = () => {
    confirmAlert({
      title: "Confirm deletion",
      message:
        "Are you sure you want to delete this vacancy? This cannot be undone.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteVacancy(),
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };

  const handleUnassignPress = () => {
    confirmAlert({
      title: "Confirm deletion",
      message:
        "Are you sure you want to unassign this practitioner from this vacancy?",
      buttons: [
        {
          label: "Yes",
          onClick: () => removePractitioner(),
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };

  const parseTheDate = (date) => {
    var parts = date.split("/");
    var dt = new Date(
      parseInt(parts[2], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[0], 10)
    );
    return dt;
  };

  const getInfo = () => {
    setPending(true);
    UserService.getVacancyByIdLink(vacancyID, userData.ProviderID)
      .then((res) => {
        console.log(res);
        setPending(false);
        if (!res.data)
          throw new Error(
            "500: Internal Server Error - Please Try Again Later"
          );
        setVacancyInfo(res.data);
      })
      .catch((err) => {
        setPending(false);
        let message = handleErr(err);
        history.push({ pathname: "/serverError", state: { message: message } });
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getInfo();

  }, []);

  if (pending) {
    return <MiniLoader />
  }

  if (!vacancyInfo) {
    return <Container></Container>;
  }

  if (!editMode) {
    return (
      <Container>
        <BackBtn onClick={goBack}>
          <BackIcon />
          Back to vacancies{" "}
        </BackBtn>

        <Header>
          <H1>Details for Vacancy </H1>
          {vacancyInfo.completed === "0" && (
            <IconWrapper onClick={() => setEditMode(true)}>
              <FaPencilAlt size={20} color={"navy"} />
            </IconWrapper>
          )}
        </Header>

        <Section>
          <Title>Specialty</Title>
          <Paragraph>{vacancyInfo.specialty}</Paragraph>
        </Section>

        <Section>
          <Title>Training Level</Title>
          <Paragraph>{levels[vacancyInfo.trainingLevel - 1]}</Paragraph>
        </Section>

        <Section>
          <Title>Dates</Title>
          <Paragraph>Start: {vacancyInfo.startDate}</Paragraph>
          <Paragraph>End: {vacancyInfo.endDate}</Paragraph>
        </Section>

        <Section>
          <Title>Times</Title>
          <Paragraph>Start: {vacancyInfo.startTime}</Paragraph>
          <Paragraph>End: {vacancyInfo.endTime}</Paragraph>
        </Section>

        <Section>
          <Title>Rate</Title>

          <Paragraph>${vacancyInfo.rate}</Paragraph>
        </Section>
        <Section>
          <Title>Accommodation</Title>

          <Paragraph>
            {vacancyInfo.accommodation === "notProvided"
              ? "Not provided"
              : null}
          </Paragraph>
          <Paragraph>
            {vacancyInfo.accommodation === "provided" ? "Provided" : null}
          </Paragraph>
          <Paragraph>
            {vacancyInfo.accommodation === "allowance"
              ? `Allowance of $${vacancyInfo.accAllowance}`
              : null}
          </Paragraph>
        </Section>
        <Section>
          <Title>Travel</Title>
          <Paragraph>
            {vacancyInfo.travel === "notProvided" ? "Not provided" : null}
          </Paragraph>
          <Paragraph>
            {vacancyInfo.travel === "provided" ? "Provided" : null}
          </Paragraph>
          <Paragraph>
            {vacancyInfo.travel === "allowance"
              ? `Allowance of $${vacancyInfo.travelAllowance}`
              : null}
          </Paragraph>
        </Section>
        <Section>
          <Title>On Call</Title>
          <Paragraph>{vacancyInfo.onCall === "0" ? "No" : "Yes"}</Paragraph>
        </Section>

        {vacancyInfo.PractitionerID && (
          <>
            <Section>
              <Title>Practitioner Assigned</Title>
              <Paragraph>
                {vacancyInfo.title} {vacancyInfo.firstName}{" "}
                {vacancyInfo.lastName}
              </Paragraph>
              <Paragraph>{vacancyInfo.prefAreaOfWork}</Paragraph>
              <Paragraph>
                {vacancyInfo.trainingLevel} years postgraduation
              </Paragraph>
            </Section>
          </>
        )}

        <Footer>
          {vacancyInfo.completed === "0" && (
            <ActionBtn
              onClick={handleDeletionPress}
              className={pending ? "disabled" : ""}
              delete={true}
            >
              {pending ? <MiniLoader /> : "Delete Vacancy"}
            </ActionBtn>
          )}
          {vacancyInfo.PractitionerID && vacancyInfo.completed === "0" && (
            <ActionBtn
              onClick={handleUnassignPress}
              className={pending ? "disabled" : ""}
              delete={true}
            >
              {pending ? <MiniLoader /> : "Unassign practitioner"}
            </ActionBtn>
          )}
        </Footer>
      </Container>
    );
  } else {
    return (
      <VacancyForm
        initialValues={{
          specialty: vacancyInfo.specialty,
          trainingLevel: vacancyInfo.trainingLevel,
          startDate: parseTheDate(vacancyInfo.startDate),
          endDate: parseTheDate(vacancyInfo.endDate),
          startTime: vacancyInfo.startTime,
          endTime: vacancyInfo.endTime,
          onCall: vacancyInfo.onCall,
          rate: vacancyInfo.rate,
          accommodation: vacancyInfo.accommodation,
          accAllowance: vacancyInfo.accAllowance,
          travel: vacancyInfo.travel,
          travelAllowance: vacancyInfo.travelAllowance,
        }}
        postFunc={UserService.editVacancyLink}
        providerID={vacancyInfo.ProviderID}
        vacancyID={vacancyInfo.vacancyID}
        goBack={true}
        title="Edit Vacancy"
        buttonTxt="Save changes"
        onComplete={()=>{
          getInfo();
          window.scrollTo(0, 0);
          setEditMode(false);
        }}
        back={() => {
          window.scrollTo(0, 0);
          setEditMode(false);
        }}
      />
    );
  }
};

export const ActionBtn = styled.button`
  outline: none;
  background: ${(props) => (props.delete ? "red" : "#63a9ff")};
  color: ${(props) => (props.delete ? "white" : "black")};
  padding: 10px;
  border-radius: 10px;
  border: none;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.5s ease-in;
  }
`;
export const Container = styled.div`
  align-items: center;

`;

export const H1 = styled.h1`
  color: navy;
  font-size: 20px;
  margin-bottom: 10px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Paragraph = styled.p`
  font-size: 16px;
  margin-bottom: 4px;
`;

export const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const Section = styled.div`
  padding: 10px;
  background: #f8f8f8;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
`;
export const Link = styled.a`
  font-size: 16px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
  justify-content: space-around;
`;

export const BackBtn = styled.button`
  outline: none;
  border: none;
  color: navy;
  margin-bottom: 30px;
  background: none;
  font-size: 18px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.25s ease-in;
  }
`;

export const BackIcon = styled(FaArrowLeft)`
  padding-top: 5px;
  padding-right: 5px;
`;
export const IconWrapper = styled.div`
  &:hover {
    cursor: pointer;
    opacity: 0.4;
    transition: opacity 0.2s ease-in-out;
  }
`;
