

import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const SignInAndRegHeader = () =>  
<div style={{marginTop: "-50px", marginBottom: "-50px"}}>
<Icon to="/"><img alt="select-a-med" className="logo logo-small" src="sam-logo.png"></img></Icon>
</div>

export const Icon = styled(LinkR)`
  margin-left: 32px;
  text-decoration: none;
  color: black;
  font-weight: 700;
  font-size: 32px;
  @media screen and (max-width: 480px) {
    margin-left: 16px;
    margin-top: 32px;
    font-size: 28px;
  }
`;



