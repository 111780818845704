import React, {useContext} from 'react'
import styled from 'styled-components'
import {FaSignOutAlt} from "react-icons/fa"
import {SessionContext} from "../Misc/SessionContext"
import HamburgerMenu from "react-hamburger-menu";
import {useHistory} from "react-router-dom"
export const Nav = ({isOpen, toggle}) => {
    const {handleLogout} = useContext(SessionContext)
    const history = useHistory();
    return (
        <Container>
                                   <HamburgerMenu
    isOpen={isOpen}
    menuClicked={toggle}
    height={20}
    strokeWidth={3}
    borderRadius={2}

/>
        <LogoutBtn onClick={() => handleLogout().then(res=>history.push("/")).catch(err=>console.log(err))}
        ><BtnText>Logout</BtnText> <LogoutIcon /></LogoutBtn>

        </Container>
    )
}

const Container = styled.div`
display: flex;
padding: 2rem;
background: #f3f3f3;
justify-content: space-between;
align-items: center;
margin-bottom: 3rem;
box-shadow: 0 0 10px 0 hsl(0, 0%, 80%);
`

const BtnText = styled.span`
`

const LogoutIcon = styled(FaSignOutAlt)`
font-size: 20px;
cursor: pointer;
`

const LogoutBtn = styled.button`
background: none;
display: flex;
width: 100px;
align-items: center;
justify-content: space-evenly;
border: none;
outline: none;

&:hover {
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.25s ease-in;
}
`

