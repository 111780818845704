import { Link as LinkS } from "react-scroll";

import styled from 'styled-components'

 export const FormSelect = styled.select`
 font-size: 15px;
 width: 10px;
 padding-left: 5px;
 display: inline-block;
 border-radius: 10px;
 border: none;
 height: 55px;
 box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

 &.hidden {
   max-height: 0px;
   display:flex;
   justify-content: space-evenly;
   width: 100%;
 }
 
 &.open {
 max-height: 3000px;
 display:flex;
 justify-content: space-evenly;
 width: 100%;
 transition: max-height 1s ease-in-out;
 
 }

`;

export const ErrMsg = styled.p`
color: red;
margin-top: 10px;
`

export const FormButton = styled(LinkS)`
font-size: 15px;
width: 100px;
padding-top: 10px;
padding-left: 25px;
height: 40px;
border-radius: 10px;
border: none;
margin-top: 5px;
margin-right: 5px;
margin-bottom: 5px;
color: white;
background-color: #63a9ff;
outline:none;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
&:hover{
 cursor: pointer;
}

`;

export const BtnText = styled.span`
font-weight: bold;

`



export const FormInput = styled.input`
border: none;
outline: none;
padding-left: 5px;
border-radius: 10px;
height: 50px;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);



`;

export const SearchBox = styled.div`
height: auto;
padding: 20px;
width: auto;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-around;
border-radius: 20px;
flex-direction: column;

`

export const ExtrasButton = styled.button`
border:none;
background: none;
color: gray;
outline: none;

&:hover{
 cursor: pointer;
 color:  black;
 transition: color 0.5s ease-in;
}

`

export const MainWrapper = styled.div`
display:flex;

justify-content: space-evenly;
margin-bottom: 10px;
flex-direction: column;
`

export const FieldWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: space-around;


`

export const BtnWrapper = styled.div`
display:flex;
flex-direction: column;
justify-content: flex-start;
@media screen and (max-width: 1000px) {
 flex-direction: row;
 margin-top: 0px;

}

`

export const ExtraWrapper = styled.div`
max-height: 0px;
overflow: hidden;

&.open {
max-height: 1000px;
display:flex;
justify-content: space-evenly;
background: none;
transition: max-height 0.2s ease-in;
flex-direction: column;
}

@media screen and (max-width: 1000px) {
 flex-direction: column;
}
`

export const FormLabel = styled.p`
font-size: 18px;
margin-left: 5px;
margin-bottom: 5px;
overflow: hidden;


&.hidden {
 display: none;
}
`

export const InputWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
`

export const SearchH1 = styled.h1`
padding-left: 25px;
margin-bottom: 30px;
`

export const Container = styled.div`
padding: 2rem;
`