import * as yup from "yup";
import * as moment from "moment";

const numRegex = /^[0-9]+$/
const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/

export const VacancyValidationSchema = yup.object().shape({
    specialty: yup.string().required("Please enter a specialty."),
    trainingLevel: yup.string().required("Please enter a training level."),
    startDate: yup
    .date("Please enter a valid date.")
    .required("Please enter a start date.")
    .test(
      "startDate",
      "Invalid start - must be in the future. ",
      (value) => {
        return moment().diff(moment(value), "days") < 0;
      }
    ),
    endDate: yup.date("Please enter a valid date.").min(yup.ref("startDate"), "End date must be after start date.")
    .required("Please enter an end date."),
    startTime: yup.string("Please enter a valid start time").matches(timeRegex, "Please enter a valid start time").required("Please enter a start time"),
    endTime: yup.string("Please enter a valid end time").matches(timeRegex, "Please enter a valid end time").required("Please enter an end time"),
    onCall: yup.string().required("Please indicate whether the practitioner is on call."),
    rate: yup.string().matches(numRegex, "The rate must consist of numbers only").required("Please enter a rate."),
    accommodation: yup.string().required("Please provide information on accommodation."),
    accAllowance: yup.string().when("accommodation", {
      is: "allowance",
      then: yup.string().matches(numRegex, "The allowance must consist of numers only").required("Please provide an allowance.")
    }),
    travel: yup.string().required("Please provide information on travel."),
    travelAllowance: yup.string().when("travel", {
      is: "allowance",
      then: yup.string().matches(numRegex, "The allowance must consist of numers only").required("Please provide an allowance.")
    }),

})

