import "./App.css";
import React, {useEffect} from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages";
import ProviderSignUpPage from "./pages/ProviderSignUpPage";
import PractitionerSignUpPage from "./pages/PractitionerSignUpPage";
import RedirectMessage from "./components/Misc/RedirectMessage";
import ScrollToTop from "./components/Misc/ScrollToTop";
import SignInPage from "./pages/SignInPage";
import ProviderDashboard from "./components/ProviderDashboard"
import PractitionerDashboard from "./components/PractitionerDashboard"
// import Amplify from "aws-amplify";
// import awsconfig from "./aws-exports";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import ProtectedRouteAdmin from "./components/Routes/ProtectedRouteAdmin";
import {ErrorMessage} from "./components/Misc/ErrorMessage";
import { SessionContextProvider } from "./components/Misc/SessionContext";
import {AdminActionPage} from "./components/Admin/AdminActionPage"
import { AdministratorSignInPage } from "./components/Admin/AdministratorSignInPage";

// Amplify.configure(awsconfig)

const Routes = () => {

  return(
    <SessionContextProvider>
      <Router>
      <ScrollToTop />
      <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/practitionersignup"component={PractitionerSignUpPage} exact />
          <Route path="/signin" component={SignInPage} exact/>
          <Route path="/success" component={RedirectMessage} exact />
          <Route path="/providersignup" component={ProviderSignUpPage} exact />
          <ProtectedRoute path="/providerDashboard/" for="providers" component={ProviderDashboard} />
          <Route path="/practitionerDashboard/" for="practitioners"component={PractitionerDashboard} />
          <Route exact path="/serverError" component={ErrorMessage} />
          <ProtectedRouteAdmin exact path="/administrators" component={AdminActionPage} />
          <Route exact path="/administratorsignin" component={AdministratorSignInPage} />
          <Route path='*' exact={true} component={ErrorMessage} />
          </Switch>
        </Router>
      </SessionContextProvider>
  )
}

const App = () => {
  useEffect(() => {
    document.title = "Select-A-Med"
 }, []);

  return(

  <div className="App">
    <Routes />
  </div>

  )
};

export default App; 
