import React, {useState} from 'react';
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';
import { confirmAlert } from "react-confirm-alert";
import "react-time-picker/dist/TimePicker.css"
const getColor = (props) => {
  if (props.isDragAccept) {
      return '#00e676';
  }
  if (props.isDragReject) {
      return '#ff1744';
  }
  if (props.isDragActive) {
      return '#2196f3';
  }
  return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

export default function Dropzone(props) {
    const [fileURL, setFileURL] = useState('')
    const [fileName, setFileName] = useState('')

    const generateUrl = (file) => {
        setFileName(file.name)
        let url = URL.createObjectURL(file);
        setFileURL(url)
    }

    const handlePress = (file) => {
      confirmAlert({
    title: "",
    message:
      "Are you sure you want to upload this file? It will be sent to the select-a-med-admin for approval.",
    buttons: [
      {
        label: "Yes",
        onClick: () => props.saveFile(file),
      },
      {
        label: "No",
        onClick: () => false
      },
    ],
  });
}

    
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: 'application/pdf',
    maxSize: 1048576,
    onDrop: file => {
        if (isDragAccept) {
          generateUrl(file[0])
          if (!handlePress(file)) {
            setFileURL("")
            setFileName("")
          }
        }
    }
});
  
  return (

    <div className="container" style={{marginTop: "30px"}}>

      <Container onClick= {()=>console.log("HI")} {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
        <input {...getInputProps()} />
        {(!fileName && !fileURL) &&<p>Drag and drop form ...</p>}
         <a style={{fontSize:"14px"}}href={fileURL} download>{fileName}</a>
      </Container>

    </div>
  );
}
