import styled from "styled-components"
import {FaArrowLeft} from "react-icons/fa"

export const ActionBtn = styled.button`
outline: none;
background: #63a9ff;
font-weight: bold;
border-radius: 10px;
width: 200px;
margin-bottom: 10px;
padding: 0.35rem;
color: white;
border: none;
&:hover {
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.5s ease-in;
}
`

export const InfoSubSection = styled.div`
margin-bottom: 5px;
`

export const Container = styled.div`
padding: 1rem 1rem;
border-radius: 10px;
background: #f3f3f3;

`

export const InfoSection = styled.div`
border-radius: 10px;
background: #f8f8f8;
padding: 0.8rem 0.5rem;
margin-top: 10px;
margin-bottom: 10px;
display: flex;
flex-direction: row;
justify-content: space-around;
align-items: center;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

`


export const H1 = styled.h1`
color: navy;
font-size: 20px;
`

export const H2 = styled.h1`
color: navy;
font-size: 18px;
margin-bottom: 5px;
`

export const Paragraph = styled.p`
font-size: 16px;
font-weight: 500;
margin-top: 3px;
margin-bottom: 3px;
color: ${props => props.color ? props.color : "black"}
`

export const Link = styled.a`
font-size: 16px;
`

export const Footer = styled.div`
align-items: center;
padding: 5px;
display: flex;
flex-direction: column;
justify-content: space-between;
`

export const Label=styled.p`
font-weight: bold;
`

export const BackBtn = styled.button`
outline: none;
border: none;
color: navy;
margin-bottom: 30px;
background: none;
font-size: 18px;
font-weight: bold;

&:hover {
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.25s ease-in;
}
`

export const BackIcon = styled(FaArrowLeft)`
padding-top: 5px;
padding-right: 5px;
`

