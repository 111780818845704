import React, { useContext } from "react";
import { StepContext } from "./StepContext";
import {
  FormH1,
  FormH2,
  FormP,
  InfoCard,
  IconWrap,
  EditIcon,
  FormFieldTitle,
  FormSection,
  FormSubTitle,
  SubInfoCard,
} from "./SignUpElements";

export const SummaryTemplate = ({info, loading}) => {
    let context = useContext(StepContext);

    return(
    <FormSection>
    <FormH1> 6. Review and Submit </FormH1>
    <InfoCard>
      <FormH2>
        1. Personal Information{" "}
        <IconWrap>
          <EditIcon className={loading ? "disabled" : ""} onClick={() => context.setStep(1)} />
        </IconWrap>
      </FormH2>
      <FormFieldTitle>Name: </FormFieldTitle>
      <FormP>
        {info.personalInfo.title ? `${info.personalInfo.title}. ` : ""}
        {info.personalInfo.firstName} {info.personalInfo.lastName}
      </FormP>
      <FormFieldTitle>Date of Birth: </FormFieldTitle>
      <FormP>{`${info.personalInfo.DOB}`}</FormP>

      <FormFieldTitle>Address</FormFieldTitle>
      <FormP>
        {info.personalInfo.address.unitNumber &&
          `Unit ${info.personalInfo.address.unitNumber}`}{" "}
        {`${info.personalInfo.address.streetNumber} ${info.personalInfo.address.streetName}`}
      </FormP>
      <FormP>{`${info.personalInfo.address.suburb} ${info.personalInfo.address.state} ${info.personalInfo.address.postcode}`}</FormP>
      <FormP>{`${info.personalInfo.address.town}`}</FormP>
      <FormP>{`${info.personalInfo.address.country} `}</FormP>

      <FormFieldTitle>Home Phone: </FormFieldTitle>
      <FormP>{`${info.personalInfo.homePhone}`}</FormP>
      <FormFieldTitle>Work Phone: </FormFieldTitle>
      <FormP>{`${info.personalInfo.workPhone}`}</FormP>
      <FormFieldTitle>Mobile: </FormFieldTitle>
      <FormP>{`${info.personalInfo.mobile}`}</FormP>
      <FormFieldTitle>Email:</FormFieldTitle>

<FormP>{`${info.personalInfo.email}`}</FormP>
      <FormFieldTitle>
        Are you an Australian Citizen/Permanent Resident?{" "}
      </FormFieldTitle>
      <FormP>{`${info.personalInfo.isAusRes.toUpperCase()}`}</FormP>
      {info.personalInfo.isAusRes === "no" && (
        <>
          <FormFieldTitle>Visa Expiry Date</FormFieldTitle>
          <FormP>{`${info.personalInfo.visaExpiry && info.personalInfo.visaExpiry}`}</FormP>
        </>
      )}

    </InfoCard>
    <InfoCard>
      <FormH2>
        2. Qualifications
        <IconWrap>
          <EditIcon onClick={() => {context.setStep(2)}} className={loading ? "disabled" : ""} />
        </IconWrap>
      </FormH2>

      <SubInfoCard>
      <FormSubTitle>Medical Qualifications </FormSubTitle>
      <FormFieldTitle>APHRA Number</FormFieldTitle>
      <FormP>{`${info.qualifications.AHPRA.AHPRANum}`}</FormP>
      <FormFieldTitle>APHRA Certificate</FormFieldTitle>
      <a style={{fontSize:"14px"}} href={URL.createObjectURL(info.qualifications.AHPRA.AHPRADoc)} download>{info.qualifications.AHPRA.AHPRADoc.name}</a>
      <FormFieldTitle>Primary Medical Degree Name</FormFieldTitle>
      <FormP>{`${info.qualifications.primaryMedDegree.degreeName}`}</FormP>
      <FormFieldTitle>University where degree obtained</FormFieldTitle>
      <FormP>{`${info.qualifications.primaryMedDegree.uni.name}`}</FormP>
      <FormFieldTitle>Country where degree obtained</FormFieldTitle>
      <FormP>{`${info.qualifications.primaryMedDegree.uni.country}`}</FormP>
      <FormFieldTitle>Primary Medical Degree Document</FormFieldTitle>
      <FormP><a style={{fontSize:"14px"}} href={URL.createObjectURL(info.qualifications.primaryMedDegree.degreeDoc)} download>{info.qualifications.primaryMedDegree.degreeDoc.name}</a></FormP>

      <FormFieldTitle>Post Graduation Year</FormFieldTitle>
      <FormP>{`${info.qualifications.postGradYear}`}</FormP>

      <FormFieldTitle>Area of Training</FormFieldTitle>
      <FormP>{`${info.qualifications.areaOfTraining}`}</FormP>

      <FormFieldTitle>Are you registered in a medical college?</FormFieldTitle>
      <FormP>{`${info.qualifications.college.registered.toUpperCase()}`}</FormP>

      {info.qualifications.college.registered === "yes" ? 
      <>
      <FormFieldTitle>Name of College</FormFieldTitle>
      <FormP>{`${info.qualifications.college.name}`}</FormP>
      <FormFieldTitle>Year started</FormFieldTitle>
      <FormP>{`${info.qualifications.college.year}`}</FormP>
      </> : null }
      </SubInfoCard>

      <SubInfoCard>

      <FormSubTitle>Medical Indemnity Insurance </FormSubTitle>
      <FormFieldTitle>Insurance Provider</FormFieldTitle>
      <FormP>{`${info.qualifications.insurance.providerName}`}</FormP>
      <FormFieldTitle>Insurance Certificate</FormFieldTitle>
      <FormP><a style={{fontSize:"14px"}} href={URL.createObjectURL(info.qualifications.insurance.insuranceDoc)} download>{info.qualifications.insurance.insuranceDoc.name}</a></FormP>
      </SubInfoCard>

      <SubInfoCard>

      <FormSubTitle>Identity Verification</FormSubTitle>

      <FormFieldTitle>Passport</FormFieldTitle>
      <FormP>      <a style={{fontSize:"14px"}} href={URL.createObjectURL(info.qualifications.passport)} download>{info.qualifications.passport.name}</a>
</FormP>


      <FormFieldTitle>Driver's License</FormFieldTitle>
      <FormP><a style={{fontSize:"14px"}} href={URL.createObjectURL(info.qualifications.drivers)} download>{info.qualifications.drivers.name}</a></FormP>

      </SubInfoCard>

      <SubInfoCard>

{console.log(info.qualifications.otherQualifications)}

      <FormSubTitle>Other Qualifications </FormSubTitle>
      {info.qualifications.otherQualifications.map((deg, index) => {
        return (
          <div key={index}>
            <FormFieldTitle>Qualification Name</FormFieldTitle>
            <FormP>{`${deg.qualificationName}`}</FormP>
            <FormFieldTitle>
              Supporting Documentation for Qualification
            </FormFieldTitle>

            <FormP> {`${deg.qualificationDoc.name}`}</FormP>
          </div>
        );
      })}
      {info.qualifications.otherQualifications.length === 0 && (
        <FormP>None</FormP>
      )}
              </SubInfoCard>

    </InfoCard>
    <InfoCard>
      <FormH2>
        {" "}
        3. Past Employment {" "}
        <IconWrap>
          <EditIcon onClick={() => context.setStep(3)}  className={loading ? "disabled" : ""} />
        </IconWrap>
      </FormH2>
      {info.workInfo.map((work, index) => {
        return (
          <SubInfoCard key={index}>
            {work.employer && <>
                <FormSubTitle>{`Employer #${index + 1}`}</FormSubTitle>
                <FormFieldTitle>Employer</FormFieldTitle>
                <FormP>{work.employer}</FormP>
                <FormFieldTitle>Position</FormFieldTitle>
                <FormP>{work.position}</FormP>
                <FormFieldTitle>Dates</FormFieldTitle>
                <FormP>{`${work.startDate
                } - ${work.endDate && work.endDate}`}</FormP>
                <FormFieldTitle>Supervisor Contact Name</FormFieldTitle>
                <FormP>
                  {`${work.empName.firstName} ${work.empName.lastName}`}
                </FormP>
                <FormFieldTitle>Supervisor Telephone</FormFieldTitle>
                <FormP>{work.empContactNum}</FormP>
              </>
            }
          </SubInfoCard>
        );
      })}
      <FormSubTitle>Resume</FormSubTitle>
      <FormP><a style={{fontSize:"14px"}} href={URL.createObjectURL(info.resume)} download>{info.resume.name}</a>
</FormP>


    </InfoCard>
    <InfoCard>
      <FormH2>
        {" "}
        4. Referees
        <IconWrap>
          <EditIcon onClick={() => context.setStep(4)} className={loading ? "disabled" : ""}  />
        </IconWrap>
      </FormH2>
      <SubInfoCard>
      <FormSubTitle>Referee #1</FormSubTitle>
      <FormFieldTitle>Name</FormFieldTitle>
      <FormP>{`${info.refs.ref1.firstName} ${info.refs.ref1.lastName}`}</FormP>
      <FormFieldTitle>Place of Work</FormFieldTitle>
      <FormP>{`${info.refs.ref1.placeOfWork}`}</FormP>
      <FormFieldTitle>Email</FormFieldTitle>
      <FormP>{`${info.refs.ref1.email}`}</FormP>
      <FormFieldTitle>Phone</FormFieldTitle>
      <FormP>{`${info.refs.ref1.phone}`}</FormP>
      </SubInfoCard>

      <SubInfoCard>
      <FormSubTitle>Referee #2</FormSubTitle>
      <FormFieldTitle>Name</FormFieldTitle>
      <FormP>{`${info.refs.ref2.firstName} ${info.refs.ref2.lastName}`}</FormP>
      <FormFieldTitle>Place of Work</FormFieldTitle>
      <FormP>{`${info.refs.ref2.placeOfWork}`}</FormP>
      <FormFieldTitle>Email</FormFieldTitle>
      <FormP>{`${info.refs.ref2.email}`}</FormP>
      <FormFieldTitle>Phone</FormFieldTitle>
      <FormP>{`${info.refs.ref2.phone}`}</FormP>
      </SubInfoCard>

      <SubInfoCard>

      <FormSubTitle>Referee #3</FormSubTitle>
      <FormFieldTitle>Name</FormFieldTitle>
      <FormP>{`${info.refs.ref3.firstName} ${info.refs.ref3.lastName}`}</FormP>
      <FormFieldTitle>Place of Work</FormFieldTitle>
      <FormP>{`${info.refs.ref3.placeOfWork}`}</FormP>
      <FormFieldTitle>Email</FormFieldTitle>
      <FormP>{`${info.refs.ref3.email}`}</FormP>
      <FormFieldTitle>Phone</FormFieldTitle>
      <FormP>{`${info.refs.ref3.phone}`}</FormP>
      </SubInfoCard>

    </InfoCard>

    <InfoCard>
      <FormH2>
        {" "}
        5. Work Preferences
        <IconWrap>
          <EditIcon onClick={() => context.setStep(5)} className={loading ? "disabled" : ""}  />
        </IconWrap>
      </FormH2>

      <FormFieldTitle>Preferred States</FormFieldTitle>
      {info.workPref.states.map((item, i) => <FormP key={i}>{item}</FormP>)}
      <FormFieldTitle>Area of Work</FormFieldTitle>

      <FormP>{info.workPref.areaOfWork}</FormP>
    </InfoCard>
    </FormSection>

    )
}
