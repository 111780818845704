import React, { useState, useEffect, useContext } from "react";
import { StepContext } from "./StepContext";
import { IconContext } from "react-icons/lib";

import {
  Nav,
  NavbarContainer,
  NavMenu,
  NavItem,
  FormSwitchBtn,
} from "./SignUpElements";

const SignUpNav = () => {
  const [scrollNav, setScrollNav] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const context = useContext(StepContext);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else setScrollNav(false);
  };

  useEffect(() => {
    if (isMounted){
    window.addEventListener("scroll", changeNav);
  }
  return () => {
    setIsMounted(false);
    window.removeEventListener("scroll", changeNav);
  } 
  }, [isMounted]);

  const getStatus = (navBarStep) => {
    if (context.step === navBarStep) {
      return "current";
    } else if (context.maxStep < navBarStep) {
      return "inaccessible";
    } else {
      return;
    }
  };

  return (
    <IconContext.Provider value={{ color: "#fff" }}>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavMenu>
            <NavItem>
              <FormSwitchBtn className={getStatus(1)}>
                <span className="lg-view">1. Personal Information</span>
                <span className="sm-view">1.</span>
              </FormSwitchBtn>
            </NavItem>
            <NavItem>
              <FormSwitchBtn className={getStatus(2)}>
              <span className="lg-view">2. Qualifications</span>
              <span className="sm-view">2.</span>
              </FormSwitchBtn>{" "}
            </NavItem>
            <NavItem>
              <FormSwitchBtn className={getStatus(3)}>
              <span className="lg-view">3. Past Employment</span>
              <span className="sm-view">3.</span>
              </FormSwitchBtn>{" "}
            </NavItem>
            <NavItem>
              <FormSwitchBtn className={getStatus(4)}>
              <span className="lg-view">4. Referees</span>
              <span className="sm-view">4.</span>
              </FormSwitchBtn>{" "}
            </NavItem>
            <NavItem>
              <FormSwitchBtn className={getStatus(5)}>
              <span className="lg-view">5. Work Preferences</span>
              <span className="sm-view">5.</span>
              </FormSwitchBtn>{" "}
            </NavItem>
            <NavItem>
              <FormSwitchBtn className={getStatus(6)}>
              <span className="lg-view">6. Review</span>
              <span className="sm-view">6.</span>
                
                </FormSwitchBtn>{" "}
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </IconContext.Provider>
  );
};

export default SignUpNav;
