
import React from "react"
import {ResultCard} from "./ResultCard"
import {MiniLoader} from "../../Misc/MiniLoader"

import styled from "styled-components"

const Container = styled.div`
display: flex;
padding: 15px;
flex-direction: column;
`
const MsgContainer = styled.div`
padding: 2rem 2rem;
`
const Msg = styled.p`
text-align: center;
color: navy;
`
export const SearchResults = ({res, doSearch, pending}) => {
if (Array.isArray(res) && res.length > 0) {
        return <Container>{res.map((data, idx) => <ResultCard doSearch={doSearch} key={idx} data={data} />)}</Container>
    }
    else if (pending){
        return <Container style={{alignItems: "center"}}><MiniLoader color="#63a9ff" /></Container>
    }
    else if (Array.isArray(res) && res.length === 0) {
    return <MsgContainer><Msg> No Vacancies Found. </Msg></MsgContainer>
}
else return <Container></Container>

}
