import React from "react";
import {
  SubFormLabel,
  ErrorMsg,
  FormSelect,
} from "../PractitionerSignUp/SignUpElements";
import { Field } from "formik";
import * as yup from "yup";
import { useValues } from "../PractitionerSignUp/useValues";
import { withSubForm } from "../PractitionerSignUp/withSubForm";
import {MID_NUM_LENGTH, MAX_NAME_LENGTH}  from "../../helpers/characterLimits"; 

const postcodeRegExp = /^([0-9]{4})$/;
const alphaNumRegExp = /^[a-zA-Z0-9_]*$/;

const addressSchema = yup.object().shape({
  unitNumber: yup.string().matches(alphaNumRegExp, "This is not a valid unit number."),
  streetNumber: yup.string().matches(alphaNumRegExp, "This is not a valid street number.").required("Please enter a street number"),
  streetName: yup.string().required("Please enter a street name."),
  suburb: yup.string().required("Please enter a suburb."),
  town: yup.string().required("Please enter a town."),
  country: yup.string().required("Please enter a country."),
  state: yup.string().required("Please enter a state."),
  postcode: yup
    .string()
    .matches(postcodeRegExp, "This is not a valid Australian postcode. ")
    .required("Please enter a postcode. "),
});

const AddressFormItem = ({ name, errors, touched, ...props }) => {
  useValues(name, props);

  return (
    <>
      <SubFormLabel>Unit number</SubFormLabel>
      <Field name="unitNumber" className="formInput" />
      {errors.unitNumber && touched.unitNumber ? (
        <ErrorMsg>{errors.unitNumber}</ErrorMsg>
      ) : null}

      <SubFormLabel>
        Street number <span className="required">*</span>{" "}
      </SubFormLabel>
      <Field name="streetNumber" className="formInput" maxLength={MID_NUM_LENGTH}/>
      {errors.streetNumber && touched.streetNumber ? (
        <ErrorMsg>{errors.streetNumber}</ErrorMsg>
      ) : null}

      <SubFormLabel>
        Street <span className="required">*</span>{" "}
      </SubFormLabel>
      <Field name="streetName" className="formInput" maxLength={MAX_NAME_LENGTH}/>
      {errors.streetName && touched.streetName ? (
        <ErrorMsg>{errors.streetName}</ErrorMsg>
      ) : null}

      <SubFormLabel>
        Suburb <span className="required">*</span>{" "}
      </SubFormLabel>
      <Field name="suburb" className="formInput" maxLength={MAX_NAME_LENGTH}/>
      {errors.suburb && touched.suburb ? (
        <ErrorMsg>{errors.suburb}</ErrorMsg>
      ) : null}

      <SubFormLabel>
        Town/City <span className="required">*</span>{" "}
      </SubFormLabel>

      <Field name="town" className="formInput" maxLength={MAX_NAME_LENGTH}/>
      {errors.town && touched.town ? <ErrorMsg>{errors.town}</ErrorMsg> : null}
      <SubFormLabel>
        State <span className="required">*</span>{" "}
      </SubFormLabel>

      <Field name="state">
        {({ field, form: meta }) => (
        <>
        <FormSelect {...field} style={{ display: "block" }}>
              <option value="" label="Choose state ... " />
              <option value="ACT" label="ACT" />
              <option value="NSW" label="NSW" />
              <option value="NT" label="NT" />
              <option value="QLD" label="QLD" />
              <option value="SA" label="SA" />
              <option value="TAS" label="TAS" />
              <option value="VIC" label="VIC" />
              <option value="WA" label="WA" />
            </FormSelect>
            {meta.touched && meta.error && (
            <ErrorMsg className="error">{meta.error}</ErrorMsg>)}
             </>
             )}
             </Field>



      <SubFormLabel>
        Country <span className="required">*</span>{" "}
      </SubFormLabel>
      <Field name="country" className="formInput" maxLength={MAX_NAME_LENGTH}/>
      {errors.country && touched.country ? (
        <ErrorMsg>{errors.country}</ErrorMsg>
      ) : null}

      <SubFormLabel>
        Postcode <span className="required">*</span>{" "}
      </SubFormLabel>
      <Field name="postcode" className="formInput" />
      {errors.postcode && touched.postcode ? (
        <ErrorMsg>{errors.postcode}</ErrorMsg>
      ) : null}
    </>
  );
};

export const AddressForm = withSubForm(AddressFormItem, addressSchema);
