import React, {useState, useEffect, useContext} from 'react'
import styled from 'styled-components'
import {MenuItem} from "../Dashboards/MenuItem"
import { PractitionerDashboardContext } from './PractitionerDashboardContext'
import {useHistory} from "react-router-dom"
const Container = styled.div`
margin-top: 2rem;
width: 100%;
color: white;
`

export const Menu = ({setIsOpen}) => {
    const [active, setActive] = useState([0,0,0,0,0])
    const {pracData} = useContext(PractitionerDashboardContext);
    let history = useHistory();
    
    useEffect(()=>{
        if (pracData.completedForms === "2" && history.location.pathname==="/practitionerDashboard/search") {
            setActive([0,1,0,0,0]);
        }
        if (pracData.completedForms === "2" && history.location.pathname === "/practitionerDashboard/ongoingVacancies") {
            setActive([0,0,1,0,0]);
        }
        if (history.location.pathname === "/practitionerDashboard/profile") {
            setActive([0,0,0,1,0]);
        }
        else if (history.location.pathname === "/practitionerDashboard/proProfile") {
            setActive([0,0,0,0,1]);
        }
        else {
            setActive([1,0,0,0,0]);
        }
    }, [])


    const makeActive = (num) => {
        if (setIsOpen) setIsOpen(false);
        let dummy = [0,0,0,0,0];
        dummy[num] = 1;
        setActive(dummy);
    }

    if (!pracData) {
      return <></>
    }

    return(
        <Container>
            <MenuItem  active={active[0] === 1} num={0} makeActive={makeActive} title="Fill out health forms" link = "/practitionerDashboard/healthForms" />
            <MenuItem accessible={pracData.completedForms==="2"} active={active[1] === 1} num={1} makeActive={makeActive} title="Search for vacancies" link = "/practitionerDashboard/search" />
            <MenuItem accessible={pracData.completedForms==="2"} active={active[2] === 1} num={2} makeActive={makeActive} title="Show ongoing vacancies" link = "/practitionerDashboard/ongoingVacancies" />
            <MenuItem active={active[3] === 1} num={3} makeActive={makeActive} title="Edit personal information" link = "/practitionerDashboard/profile" />
            <MenuItem active={active[4] === 1} num={4} makeActive={makeActive} title="View professional profile" link = "/practitionerDashboard/proProfile" />
        </Container>

    )
}