import React, { useContext, useState, useEffect} from "react";
import {useHistory} from "react-router-dom"
import "react-dropdown/style.css";
import {
  FormLabel,
  FormButton,
  FormSection,
  ArrowForward,
  FormSubSection,
  FormSelect,
  ErrorMsg,
  FormH2,
  FormP,
  FormInput,
} from "../SignUpElements";
import { Formik, Field } from "formik";
import * as yup from "yup";
import { AddressForm } from "../../Forms/AddressForm";
import { StepContext } from "../StepContext.js";
import * as moment from "moment";
import {MAX_NAME_LENGTH, MAX_EMAIL_LENGTH}  from "../../../helpers/characterLimits"; 
import UserService from "../../../services/userService"
import {handleErr} from "../../../helpers/handleErr"
import FormikErrorFocus from 'formik-error-focus'
import {ReactDatePicker} from "../../Misc/ReactDatePicker"
const phoneRegExp = /^0{1}(2|4|3|7|8){1}[0-9]{8}$/;
const mobileRegExp = /^0{1}(4|5){1}[0-9]{8}$/;
const postcodeRegExp = /^([0-7]{1}[0-9]{3})$/;
const alphaNumRegExp = /^[a-zA-Z0-9_]*$/;
const trimPhoneNumber = (number) => number.replace(/[- )(]/g, "");
const basicInfoSchema = yup.object().shape({
  title: yup.string().oneOf(['Mr', 'Mrs', 'Miss', 'Ms', 'Dr']),
  firstName: yup.string().required("Please enter a first name"),
  lastName: yup.string().required("Please enter a last name"),
  DOB: yup
    .date("Please enter a date.")
    .required("Please enter a date of birth")
    .test("DOB", "This DOB is not valid", (value) => {
      return (
        moment().diff(moment(value), "years") >= 18 &&
        moment().diff(moment(value), "years") < 120
      );
    }),

  homePhone: yup
    .string()
    .matches(
      phoneRegExp,
      "Please enter a phone number with the required format."
    )
    .required("Please enter a home phone number"),
  workPhone: yup
    .string()
    .matches(
      phoneRegExp,
      "Please enter a phone number with the required format."
    )
    .required("Please enter a work phone number"),
  mobile: yup
    .string()
    .matches(
      mobileRegExp,
      "Please enter a mobile number with the required format."
    )
    .required("Please enter a mobile phone number"),
  email: yup
    .string()
    .email("This is not a valid email address. ")
    .required("Please enter an email address"),
  address: yup.object().shape({
    unitNumber: yup.string().matches(alphaNumRegExp, "This is not a valid unit number."),
    streetNumber: yup.string().matches(alphaNumRegExp, "This is not a valid street number.").required("Please enter a street number"),
    streetName: yup.string().required("Please enter a street name."),
    suburb: yup.string().required("Please enter a suburb."),
    town: yup.string().required("Please enter a town."),
    country: yup.string().required("Please enter a country."),
    state: yup.string().required("Please enter a state."),
    postcode: yup
      .string()
      .matches(postcodeRegExp, "This is not a valid Australian postcode. ")
      .required("Please enter a postcode. "),
  }),
  isAusRes: yup
    .string()
    .required("Please confirm your residency/citizenship status"),
  visaExpiry: yup
    .date("Please enter a date.")
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .when("isAusRes", {
      is: "no",
      then: yup
        .date()
        .required("Please enter a visa expiry date.")
        .test(
          "visaExpiry",
          "Invalid visa expiry date - must be in the future",
          (value) => {
            return moment().subtract(1, "days").diff(moment(value), "days") < 0;
          }
        ),
    }),
});

const BasicInfo = ({
  submitHandler,
  title,
  firstName,
  lastName,
  DOB,
  homePhone,
  workPhone,
  mobile,
  email,
  address,
  isAusRes,
  visaExpiry,
}) => {
  const context = useContext(StepContext);
  const [errMsg, setErrMsg] = useState("");
  let history = useHistory();
  
useEffect(() => 
window.scrollTo(0, 0)
, [])
const dateParser = (date) => {
  if (date.length) return date;
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;
  
  return day + '/' + month + '/' + year;
}


  return (
    <FormSection id="basicinfo">
      <Formik
        initialValues={{
          title: title,
          firstName: firstName,
          lastName: lastName,
          DOB: DOB,
          homePhone: homePhone,
          workPhone: workPhone,
          mobile: mobile,
          email: email,
          address: address,
          isAusRes: isAusRes,
          visaExpiry: visaExpiry,
        }}
        validationSchema={basicInfoSchema}
        onSubmit={(values) => {
          let valuesCpy = JSON.parse(JSON.stringify(values))
          valuesCpy.DOB = dateParser(values.DOB)
          if (values.visaExpiry) {
            valuesCpy.visaExpiry = dateParser(values.visaExpiry)
          }
          UserService.checkEmailDoesNotExistLink(values.email)
          .then(res=>{
            if (res.data.message === "No user exists") {
            submitHandler({ personalInfo: valuesCpy });
             if (context.reachedSubmit) {
                context.setStep(context.REVIEW_STEP);
              } else {
              context.increment();
             }
           }
           else {
             setErrMsg(`The email address ${values.email} cannot be used as it is already associated with a Select-A-Med account. `)
          }
          }).catch(err=>{let message = handleErr(err); history.push({pathname: '/serverError', state: {message: message}})})
       }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <FormSection>
            <FormH2>1. Personal information </FormH2>
            <FormSubSection>
              <FormLabel htmlFor="for">Title</FormLabel>
              <FormSelect
                name="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ display: "block" }}
              >
                <option value="" label="Title ... " />
                <option value="Dr" label="Dr" />
                <option value="Mr" label="Mr" />
                <option value="Mrs" label="Mrs" />
                <option value="Ms" label="Ms" />
                <option value="Miss" label="Miss" />
              </FormSelect>

              <FormLabel htmlFor="for">
                First name <span className="required">*</span>{" "}
              </FormLabel>
              <Field name="firstName" className="formInput" maxLength={MAX_NAME_LENGTH}/>
              {errors.firstName && touched.firstName && (
                <ErrorMsg className="error">{errors.firstName}</ErrorMsg>
              )}

              <FormLabel htmlFor="for">
                Last name <span className="required">*</span>
              </FormLabel>
              <Field name="lastName" className="formInput" maxLength={MAX_NAME_LENGTH}/>
              {errors.lastName && touched.lastName && (
                <ErrorMsg className="error">{errors.lastName}</ErrorMsg>
              )}

              <FormLabel htmlFor="for">
                Date of Birth <span className="required">*</span>
              </FormLabel>
              <ReactDatePicker name="DOB" />
              {errors.DOB && touched.DOB && <ErrorMsg className="error">{errors.DOB}</ErrorMsg>
}




            </FormSubSection>

            <FormSubSection>
              <FormLabel htmlFor="for">Address </FormLabel>
              {errors.address && touched.address && (
                <ErrorMsg className="error">
                  One or more address fields are missing.{" "}
                </ErrorMsg>
              )}
              <Field name="address" component={AddressForm} />
            </FormSubSection>

            <FormSubSection>
              <FormLabel htmlFor="homePhone">
                Home Phone <span className="required">*</span>{" "}
              </FormLabel>
              <FormP>Please include the area code. </FormP>
              <FormP>Format: 0XXXXXXXXX</FormP>

              <Field name="homePhone">
                {({ field, form: { touched, errors }, meta }) => (
                  <>
                    <FormInput
                      {...field}
                      className="formInput"
                      name="homePhone"
                      value={values.homePhone}
                      onChange={(e) => {
                        setFieldValue(
                          "homePhone",
                          trimPhoneNumber(e.target.value)
                        );
                      }}
                    />
                    {meta.touched && meta.error && (
                      <ErrorMsg className="error">{meta.error}</ErrorMsg>
                    )}
                  </>
                )}
              </Field>

              <FormLabel htmlFor="for">
                Work Phone <span className="required">*</span>{" "}
              </FormLabel>
              <FormP>Please include the area code. </FormP>
              <FormP>Format: 0XXXXXXXXX</FormP>
              <Field name="workPhone">
                {({ field, form: { touched, errors }, meta }) => (
                  <>
                    <FormInput
                      {...field}
                      className="formInput"
                      name="workPhone"
                      value={values.workPhone}
                      onChange={(e) => {
                        setFieldValue(
                          "workPhone",
                          trimPhoneNumber(e.target.value)
                        );
                      }}
                    />

                    {meta.touched && meta.error && (
                      <ErrorMsg className="error">{meta.error}</ErrorMsg>
                    )}
                  </>
                )}
              </Field>

              <FormLabel htmlFor="mobile">
                Mobile <span className="required">*</span>{" "}
              </FormLabel>
              <Field name="mobile">
                {({ field, form: { touched, errors }, meta }) => (
                  <>
                    <FormInput
                      {...field}
                      className="formInput"
                      name="mobile"
                      value={values.mobile}
                      onChange={(e) => {
                        setFieldValue(
                          "mobile",
                          trimPhoneNumber(e.target.value)
                        );
                      }}
                    />

                    {meta.touched && meta.error && (
                      <ErrorMsg className="error">{meta.error}</ErrorMsg>
                    )}                  
                  </>
                )}
              </Field>

              <FormLabel htmlFor="for">
                Email <span className="required">*</span>{" "}
              </FormLabel>
              <Field name="email" className="formInput" maxLength={MAX_EMAIL_LENGTH} 
              onChange={e=>{setFieldValue("email", e.target.value); setErrMsg("")}}
              
              />
              
              {errors.email && touched.email && (
                <ErrorMsg className="error">{errors.email}</ErrorMsg>
              )}

              <FormLabel htmlFor="for">
                Are you an Australian Citizen or Permanent Resident?{" "}
                <span className="required">*</span>
              </FormLabel>          <FormLabel>Yes</FormLabel>

              <FormInput
            type="radio"
            id="yes"
            name="isAusRes"
            value="yes"
            onChange={(value) => {setFieldValue("isAusRes", "yes"); setFieldValue("visaExpiry", "")}}
            checked={values.isAusRes === "yes"}
          />
          <FormLabel>No</FormLabel>
          <FormInput
            type="radio"
            id="no"
            name="isAusRes"
            value="no"
            onChange={(value) => {setFieldValue("isAusRes", "no")}}
            checked={values.isAusRes === "no"}
          />

          {errors.isAusRes && touched.isAusRes && <ErrorMsg className="error">{errors.isAusRes}</ErrorMsg>}

              {values.isAusRes && values.isAusRes !== "yes" ? (
                <>
                  <FormLabel htmlFor="visaExpiry">
                    Visa Expiry Date <span className="required">*</span>
                  </FormLabel>



                  <ReactDatePicker name="visaExpiry" />
                  {errors.visaExpiry && touched.visaExpiry && (
                <ErrorMsg className="error">{errors.visaExpiry}</ErrorMsg>
              )}

                </>
              ) : null}
            </FormSubSection>
            <ErrorMsg>{errMsg}</ErrorMsg>

            {!context.reachedSubmit ? (
              <FormButton type="button" onClick={handleSubmit}>
                Save and Continue <ArrowForward />{" "}
              </FormButton>
            ) : (
              <FormButton type="button" onClick={handleSubmit}>
                Return to Summary Page
              </FormButton>
            )}
                      <FormikErrorFocus
            offset={0}
            align={'middle'}
            focusDelay={100}
            ease={'out-bounce'}
            duration={200}
          />
          </FormSection>

        )}
      </Formik>
    </FormSection>
  );
};

export default BasicInfo;
