import React, { useState } from "react";
import { Sidebar } from "./Sidebar";
import {Main} from "../Dashboards/Main"
import { ProviderDashboardProvider } from "./ProviderDashboardContext";
import { MobileSidebar } from "./MobileSidebar";
import { Switch, Route } from "react-router-dom";
import VacancyForm from "./VacancyForm";
import { CurrentVacancyPage } from "./CurrentVacancyPage";
import { UnfilledVacancyPage } from "./UnfilledVacancyPage";
import { PastVacancyPage } from "./PastVacancyPage";
import UserService from "../../services/userService";
import {useHistory} from "react-router-dom";

const ProviderDashboard = () => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  let history = useHistory();

  return (
    <ProviderDashboardProvider>
      <Sidebar isOpen={isOpen}/>
      <MobileSidebar isOpen={isOpen} toggle={toggle} setIsOpen={setIsOpen}/>
      <Main isOpen={isOpen} toggle={toggle}>
        <Switch>
        <Route
            path="/providerDashboard/currentVacancies"
            component={CurrentVacancyPage}
          />
          <Route
            path="/providerDashboard/unfilledVacancies"
            component={UnfilledVacancyPage}
          />
          <Route
            path="/providerDashboard/pastVacancies"
            component={PastVacancyPage}
          />
          <Route path="/providerDashboard/addNewVacancy">
            <VacancyForm
              initialValues={{
                specialty: "",
                trainingLevel: "",
                startDate: "",
                endDate: "",
                startTime: "",
                endTime: "",
                onCall: "",
                rate: "",
                accommodation: "",
                accAllowance: "",
                travel: "",
                travelAllowance: "",
              }}
              title="Advertise new vacancy"
              buttonTxt="Submit"
              onComplete={()=>{history.push("/providerDashboard/unfilledVacancies")}}
              postFunc={UserService.postVacancyLink}
            />
          </Route>
        </Switch>
      </Main>
    </ProviderDashboardProvider>
  );
};

export default ProviderDashboard;
