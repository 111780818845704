import React from "react"
import styled from 'styled-components'
import {Nav} from "./Nav"
export const Container = styled.div`
position: relative;
overflow: hidden;
margin-left: ${({ isOpen }) => (isOpen ? "16rem" : "0")};
transition: margin-left 0.2s ease-in-out;
`

export const Main = (props) => {
    return (
    <Container isOpen={props.isOpen}>
        <Nav isOpen={props.isOpen} toggle={props.toggle}/>
        {props.children}
    </Container>
    )
} 