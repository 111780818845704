import React, { useState } from "react";
import MainImage from "../../images/stock1.jpg";
import {
  MainContainer,
  MainBg,
  ImageBg,
  MainContent,
  MainH1,
  MainP,
  MainBtnWrapper,
  ArrowRight,
  ArrowForward,
} from "./MainElements";
import { Button } from "../Misc/ButtonElement";

const Main = () => {
  const [hover, setHover] = useState(false);
  const onHover = () => setHover(!hover);

  return (
    <MainContainer id="home">
      <MainBg>
        <ImageBg src={MainImage} />
      </MainBg>
      <MainContent>
        <MainH1>
          Removing the hassle of registering and recruiting locums
        </MainH1>
        <MainP>
          Connecting health service providers and practitioners on one simple
          platform.
        </MainP>
        <MainBtnWrapper>
          <Button
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            to="practitioners"
            primary="true"
            dark="true"
            smooth={true}
            duration={500}
            spy={true}
            eact="true"
            offset={-80}
            activeClass="active"
          >
            Learn More {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </MainBtnWrapper>
      </MainContent>
    </MainContainer>
  );
};

export default Main;
