import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { MiniLoader } from "../Misc/MiniLoader";
import UserService from "../../services/userService";
import { Formik, Field } from "formik";
import * as yup from "yup";
import { handleErr } from "../../helpers/handleErr";
import {initialValuesDev} from "./InitDummyData"
import {
  FormLabel,
  FormButton,
  FormSection,
  ErrorMsg,
  FormH2,
  FormP,
  FormContent,
  FormInput,
} from "./ProviderSignUpElements";

const trimPhoneNumber = (number) => number.replace(/[- )(]/g, "");
const phoneRegExp = /^0{1}(2|4|3|7|8){1}[0-9]{8}$/;

const providerValidationSchema = yup.object().shape({
  nameOfProvider: yup
    .string()
    .required("Please enter the name of the provider. "),
  contactPerson: yup.object().shape({
    firstName: yup.string().required("Please enter a first name"),
    lastName: yup.string().required("Please enter a last name"),
    phone: yup
      .string()
      .matches(
        phoneRegExp,
        "Please enter a valid Australian landline/mobile number."
      )
      .required("Please enter a phone number"),
    email: yup
      .string()
      .email("Please provide an email address in a valid format. ")
      .required("Please enter an email address"),
  }),
})

const ProviderSignUpForm = () => {
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [err, setErr] = useState("");

  return (
    <FormContent>
      <Formik
        initialValues={initialValuesDev}
        validationSchema={providerValidationSchema}
        onSubmit={(values) => {
          setSubmitting(true); 
          UserService.checkEmailDoesNotExistLink(values.contactPerson.email)
            .then((res) => {
              console.log("res: ", res)
              if (res.data.message === "No user exists") {
                UserService.newProvAccountReqLink(values)
                  .then((res) => {
                    console.log(res.data);
                    setSubmitting(false);
                    if (res.data.message !== "Successfully sent mail") {
                      // if a user w/ this email already has an account 
                      setErr(res.data.message);
                    } else {
                      history.push({
                        pathname: "/success",
                        state: {
                          message:
                            "Thank you. Your information has been sent to the Select-A-Med admin.",
                        },
                      });
                    }
                  })
                  .catch((err) => {
                    setSubmitting(false);
                    let message = handleErr(err);
                    history.push({
                      pathname: "/serverError",
                      state: { message: message },
                    });
                  });
              } else {
                setSubmitting(false);
                throw new Error("500: Internal Server Error");
              }
            })
            .catch((err) => {
              setSubmitting(false);
              let message = handleErr(err);
              history.push({
                pathname: "/serverError",
                state: { message: message },
              });
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <FormSection
            style={{ width: "300px" }}
            className={submitting && "disabled"}
          >
            <FormH2>Healthcare Provider Registration</FormH2>
            <FormLabel htmlFor="for">
              Health Care Provider Name <span className="required">*</span>
            </FormLabel>
            <Field
              style={{ width: "100%" }}
              name="nameOfProvider"
              className="formInput"
              maxLength={50}
            />
            {errors.nameOfProvider && touched.nameOfProvider && (
              <ErrorMsg className="error">{errors.nameOfProvider}</ErrorMsg>
            )}

            <FormLabel htmlFor="for">Contact Person Details</FormLabel>
            <br></br><br></br>

            <FormLabel htmlFor="for">
              First name <span className="required">*</span>
            </FormLabel>
            <Field
              style={{ width: "100%" }}
              name="contactPerson.firstName"
              className="formInput"
              maxLength={20}
            />
            {errors.contactPerson &&
              errors.contactPerson.firstName &&
              touched.contactPerson &&
              touched.contactPerson.firstName && (
                <ErrorMsg className="error">
                  {errors.contactPerson.firstName}
                </ErrorMsg>
              )}

            <FormLabel htmlFor="for">
              Last name <span className="required">*</span>
            </FormLabel>
            <Field
              style={{ width: "100%" }}
              name="contactPerson.lastName"
              className="formInput"
              maxLength={20}
              autoComplete="off"
            />
            {errors.contactPerson &&
              errors.contactPerson.lastName &&
              touched.contactPerson &&
              touched.contactPerson.lastName && (
                <ErrorMsg className="error">
                  {errors.contactPerson.lastName}
                </ErrorMsg>
              )}

            <FormLabel htmlFor="contactPerosn.phone">
              Contact Phone <span className="required">*</span>{" "}
            </FormLabel>
            <FormP>Please include the area code for landlines.</FormP>
            <FormP>Format: 0XXXXXXXXX</FormP>

            <Field name="contactPerson.phone" style={{ width: "100%" }}>
              {({ field, form: { touched, errors }, meta }) => (
                <>
                  <FormInput
                    {...field}
                    className="formInput"
                    name="contactPerson.phone"
                    value={values.contactPerson.phone}
                    onChange={(e) => {
                      setFieldValue(
                        "contactPerson.phone",
                        trimPhoneNumber(e.target.value)
                      );
                    }}
                  />
                  {meta.touched && meta.error && (
                    <ErrorMsg className="error">{meta.error}</ErrorMsg>
                  )}
                </>
              )}
            </Field>

            <FormLabel htmlFor="for">
              Email <span className="required">*</span>{" "}
            </FormLabel>

            <Field
              name="contactPerson.email"
              className="formInput"
              maxLength={40}
              style={{ width: "100%" }}
              onChange={(e) => {
                handleChange(e);
                setErr("");
              }}
            />
            {errors.contactPerson &&
            errors.contactPerson.email &&
            touched.contactPerson &&
            touched.contactPerson.email ? (
              <ErrorMsg className="error">
                {errors.contactPerson.email}
              </ErrorMsg>
            ) : (
              ""
            )}

            <ErrorMsg className="error">{err}</ErrorMsg>

            <FormButton
              type="button"
              onClick={handleSubmit}
              className={submitting && "disabled"}
            >
              {submitting ? <MiniLoader /> : "Submit"}
            </FormButton>
          </FormSection>
        )}
      </Formik>
    </FormContent>
  );
};

export default ProviderSignUpForm;
