import React, { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const StepContext = React.createContext();
const REVIEW_STEP = 6;

const StepContextProvider = (props) => {
  const [step, setStep] = useState(1);
  const [maxStep, setMaxStep] = useState(1);
  const [reachedSubmit, setReachedSubmit] = useState(false);

  useEffect(() => {
    if (step > maxStep) {
      setMaxStep(step);
    }
    if (step === REVIEW_STEP) {
      setReachedSubmit(true);
    }
  }, [step, maxStep, reachedSubmit]);

  const increment = () => {
    setStep((prev) => prev + 1);
  };
  const decrement = () => {
    setStep((prev) => prev - 1);
  };

  const completedPhase = (step) => {
    return step > maxStep;
  };

  const backStepAlert = () =>
    confirmAlert({
      title: "Before going back...",
      message:
        "Are you sure you want to go back to previous page? Any changes you made to this section will be lost.",
      buttons: [
        {
          label: "Yes",
          onClick: () => decrement(),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });

  return (
    <StepContext.Provider
      value={{
        step: step,
        maxStep: maxStep,
        increment: increment,
        decrement: decrement,
        setStep: setStep,
        reachedSubmit: reachedSubmit,
        completedPhase: completedPhase,
        backStepAlert: backStepAlert,
        REVIEW_STEP: REVIEW_STEP,
      }}
    >
      {props.children}
    </StepContext.Provider>
  );
};

export { StepContextProvider, StepContext };
