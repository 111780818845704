import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import { FaTrash, FaEdit } from "react-icons/fa";

import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";

export const Wrapper = styled.div`
margin: 0 auto;
`

export const Container = styled.div`
  min-height: 692px;
  vertical-align: middle
  padding: 1rem;
  margin: 0 auto;
  @media screen and (max-width: 400px) {
    padding-top: 20px;
  }
`;

export const DeleteIcon = styled(FaTrash)`
  &:hover: {
    opacity: 0.8;
    transition: opacity 0.25s ease-in;
    cursor: pointer;
  }
`;

export const IconWrap = styled.div`
  background: transparent;
  outline: none;
  display: inline;
  margin-left: 10px;
`;

export const EditIcon = styled(FaEdit)`
  font-size: 1.1rem;
`;

export const FormWrap = styled.div`
  height: 100%; 

  width: 100%;
  @media screen and (max-width: 400px) {
    height: 500px;
  }
`;

export const Icon = styled(LinkR)`
  margin-left: 32px;
  margin-top: 32px;
  text-decoration: none;
  color: black;
  font-weight: 700;
  font-size: 32px;
  @media screen and (max-width: 480px) {
    margin-left: 16px;
    margin-top: 8px;
    font-size: 28px;
  }
`;

export const FormContent = styled.div`
  height: 100%;
  display: flex;
  padding: 4rem;
  justify-content: center;
`;
export const FormSection = styled.div`
  height: 100%;
  width: 100%;
  padding: 1rem;
  flex-direction: column;
  justify-content: center;
`;

export const FormH1 = styled.h1`
  margin-bottom: 30px;
  color: black;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
`;

export const FormH2 = styled.h1`
  margin-bottom: 30px;
  color: black;
  font-size: 20px;
  font-weight: 400;
`;

export const FormSubTitle = styled.h1`
  margin-bottom: 20px;
  color: black;
  font-size: 18px;
  font-weight: 400;
`;

export const FormP = styled.p`
  margin-bottom: 10px;
  color: black;
  font-size: 15px;
`;

export const BtnSection = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const FormFieldTitle = styled.p`
  color: black;
  font-size: 15px;
  font-weight: bold;
`;

export const FormLabel = styled.label`
  padding-bottom: 10px;
  font-size: 17px;
  margin-right: 20px;
`;

export const SubFormLabel = styled.div`
  margin-bottom: 20px;
  font-size: 15px;
`;

export const CheckboxWrapper = styled.div`
border: 1px solid black;
align-items: center;
`;

export const FormInput = styled.input`
  margin-bottom: 16px;
  width: 100%;
`;

export const FormUl = styled.ul`
  font-size: 15px;
  text-align: left;
  margin-bottom: 10px;
  padding-bottom: 10px;
  color: black;
`;

export const FormHeader = styled.div`
  display: flex;
`;
export const FormLi = styled.ul`
  font-size: 15px;
  list-style-type: square;
`;

export const InfoCard = styled.div`
  padding: 20px;
  border-bottom: 1px solid grey;
`;

export const SubInfoCard = styled.div`
padding-top: 15px;
padding-bottom: 15px;
`;

export const BtnText = styled.text`
  color: white;
`;

export const FormSwitchBtn = styled.a`
  color: black;
  display: flex;
  text-align: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  outline: none;

  &.current {
    border-bottom: 3px solid #63a9ff;
  }

  &.inaccessible {
    color: grey;
    pointer-events: none;
    cursor: default;
`;

export const FormButton = styled.button`
  background: #63a9ff;
  padding: 16px 0;
  border: none;
  width: 100%; 
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 20px;
  outline: none;

  &:hover {
    opacity: 0.8;
    transition: opacity 0.25s ease-in;
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    background: grey;
    opacity: 0.8;
  }
`;

export const SubFormButton = styled.button`
  background: #63a9ff;
  padding: 10px 0;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  margin-bottom: 5px;

  &:hover {
    opacity: 0.8;
    transition: opacity 0.25s ease-in;
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    background: grey;
    opacity: 0.8;
  }
`;

export const Text = styled.span`
  text-align: center;
  margin-bottom: 24px;
  color: black;
  font-size: 14px;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
  position: relative;
  top: 3px;
`;
export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;
export const FormSubSection = styled.div`
  border-bottom: 1px solid grey;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
`;



export const NavLinks = styled(LinkS)`
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  cursor: pointer;

  &.completed {
    border-bottom: 3px solid #63a9ff;
  }
`;

export const ErrorMsg = styled.p`
  font-size: 15px;
  color: red;
  margin-bottom: 10px;

`;

export const FormContainer = styled.div`
  border-bottom: 1px solid gray;
`;
export const FormSelect = styled.select`
  font-size: 15px;
  margin-bottom: 10px;
  display: inline-block;
`;



export const NavBtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 30%;
  margin-left: 50px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: #63a9ff;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`;

export const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 70%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;
