import React, {useContext} from "react"
import styled from "styled-components"
import {Profile} from "./Profile"
import {Menu} from "./Menu"
import {ProviderDashboardContext} from "./ProviderDashboardContext"
import {Link as LinkR} from "react-router-dom"
export const Container = styled.div`
position: fixed; 
left: 0; 
top: 0;
bottom: 0;
width: ${({ isOpen }) => (isOpen ? "16rem" : "0")};
transition: width 0.2s ease-in-out;
overflow: hidden;
display: flex;
flex-direction: column; 
align-items: center;
background:#001a33;
@media screen and (max-width: 600px) {
    width: 0;
    display: none;
    transition: none;
}
`

export const Sidebar = ({isOpen}) => {
    let context = useContext(ProviderDashboardContext)
    return(
    <Container isOpen={isOpen}>
        <Profile provName={context.userData.providerName} />
        <Menu />
    </Container>
    )
}

export const Icon = styled(LinkR)`

`;


