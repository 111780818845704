import React from "react"
import styled from 'styled-components'
import {SortingBar} from "./SortingBar"
import {Vacancy} from "./Vacancy"

export const Title = styled.h1`
font-size: 1.3rem; 
display: flex;
align-items: center;
font-weight: 500;
`

export const VacanciesCount = styled.div`
margin-left: 1rem;
background-color: black;
color: white;
font-size: 1rem; 
width: 20px;
height: 20px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 20px;
`

const Text = styled.h1`
font-size: 0.8rem;
text-transform: uppercase;
font-weight: 500;
color: black;
text-align: center;
width: 33%;
align-items: center;
`

export const Container = styled.div`
padding: 1rem 1rem;
background: #f3f3f3;
border-radius: 10px;
margin-bottom: 15px;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

`
export const Vacancies = ({title, vacancies, withdrawOption}) => {
    return(
   <Container>
    <Title>{title}<VacanciesCount>{vacancies.length}</VacanciesCount></Title>
    <SortingBar>
        <Text>
            Position
        </Text>
        <Text>
            Provider
        </Text>
        <Text>
            START
        </Text>
        <Text>
            END
        </Text>
        <Text>
            Action   
        </Text>
    </SortingBar>
    {vacancies.map((vacancy, idx) =>
        <Vacancy data={vacancy} key={idx} withdrawOption={withdrawOption}/>
     )}
    </Container>

    )


}