import React from "react"
import {FaPencilAlt} from "react-icons/fa"
import {useHistory} from "react-router-dom";
import {Container, H1,H2, H3, DateInfo, Header, Footer, ActionBtn, 
Body} from "./ResultCardComponents"

const convertDateFormat = (date) => {
  let newDate = date.split("-").reverse().join("/");
  return newDate
}

function timeSince(dateStr) {
    var seconds = (new Date() - new Date(dateStr))/1000;
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " y";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " m";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " d";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " h";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " min";
    }
    return "Just now";
  }


export const ResultCard = ({data, doSearch}) => {
   let history = useHistory();

    const seeMore = () => 
      history.push({
        pathname: "/practitionerDashboard/search/showOne",
        state: data})
    
    const levels = ["JMO", "RMO", "VMO", "CMO", "SMO", "Registrar", "Fellow", "Consultant"]
    return (
      
    
    <Container>
        <Header><H1>{data.specialty} {levels[data.traininglevel-1]}</H1>
        <DateInfo>{timeSince(data.dateposted)}
        </DateInfo></Header>
        <Body>
        <H2 style={{marginLeft: "5px", marginRight: "5px"}}>{data.providername}</H2>

        <H3 style={{display: "inline", marginLeft: "5px", marginRight: "5px"}}>{convertDateFormat(data.startdate)}</H3>
          -  
         <H3 style={{display: "inline", marginLeft: "5px", marginRight: "5px"}}>{convertDateFormat(data.enddate)}</H3>

        </Body>
        <Footer>
          <ActionBtn onClick={seeMore}>View details <FaPencilAlt /></ActionBtn> 

        </Footer>








    </Container>
    )}