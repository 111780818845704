import styled from "styled-components";

export const GetStartedContainer = styled.div`
  background: #d3d3d3;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 30px;
  height: 100vh;
  position: relative;
  z-index: 1;
`;

export const GetStartedWrapper = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 10px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  margin-top: 80px;
`;

export const Heading = styled.h1`
  color: black;
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media-screen and (max-width: 428px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  margin-top: 50px;
  color: black;
  font-size: 24px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media-screen and (max-width: 428px) {
    font-size: 18px;
  }
`;

export const BtnWrap = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;
