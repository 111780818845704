import { ConsoleLogger } from "@aws-amplify/core";
import axios from "axios"
import authHeader from "./authHeader"
//import {Auth} from "aws-amplify"
const host = 'selectmeapi.pinesucceed.com'
const API_LINK = `http://${host}/api/`
const API_PRAC_URL = `http://${host}/api/protected/practitioners/`;
const API_PROV_URL = `http://${host}/api/protected/providers/`
const API_ADMIN_URL = `http://${host}/api/protected/admin/`

class UserService {

    //GENERAL USER
    checkEmailDoesNotExistLink(email) {
        return axios.get(API_LINK + `checkUserExists/${email}`)
    }

    addIdentityIDLink(data) {
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
            let header;
            if (res == null) {
                throw new Error("You must be logged in to access this resource.")
            }
            else {
                //let accessToken = session.accessToken.jwtToken
                header = {'Content-Type': 'application/json'}
            }
            return axios.post(API_LINK + `protected/addIdentity`, data, {headers: header})
        // })
        // .catch(err=>err)
    }

    //PROVIDERS 
    newProvAccountReqLink(data) {
        return axios.post(API_LINK + `providers/newAccountRequest`, data)
    }

    getProviderInfoLink() { 
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
            let header;
            if (res == null) {
                throw new Error("You must be logged in to access this resource.")
            }
            else {
                //let accessToken = session.accessToken.jwtToken
                header = {'Content-Type': 'application/json'}
            }
            //let cognitoID = session.accessToken.payload.sub;
            let cognitoID = res.data.data.firstName;
            return axios.get(API_PROV_URL + `${cognitoID}/getInfo`, {headers: header})
        // })
        // .catch(err=>err)
    }

    adminCreateNewProvider(data) {
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
            let header;
            if (res == null) {
                throw new Error("You must be logged in to access this resource.")
            }
            else {
                //let accessToken = session.accessToken.jwtToken
                header = {'Content-Type': 'application/json'}
            }
            return axios.post(API_ADMIN_URL + 'providers/verifyNewAccount', data, {headers: header})
        // })
        // .catch(err=>err)
    }

    //PROVIDERS AND VACANCIES 
    acceptPractitionerLink(data) {
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
            let header;
            if (res == null) {
                throw new Error("You must be logged in to access this resource.")
            }
            else {
                //let accessToken = session.accessToken.jwtToken
                header = {'Content-Type': 'application/json'}
            }
            //let cognitoID = session.accessToken.payload.sub;
            let cognitoID = res.dat.data.firstName;
            return axios.post(API_PROV_URL + `${cognitoID}/vacancies/acceptPractitioner`, data, {headers: header})
        // })
        // .catch(err=>err)
    }

    getVacancyByIdLink(vacancyID, providerID) {
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
            let header;
            if (res == null) {
                throw new Error("You must be logged in to access this resource.")
            }
            else {
                //let accessToken = session.accessToken.jwtToken
                header = {'Content-Type': 'application/json'}
            }
            return axios.get(API_PROV_URL + `${providerID}/vacancies/${vacancyID}/get`, {headers: header})
        // })
        // .catch(err=>err)
    }

    postVacancyLink(data) {
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
            let header;
            if (res == null) {
                throw new Error("You must be logged in to access this resource.")
            }
            else {
                //let accessToken = session.accessToken.jwtToken
                header = {'Content-Type': 'application/json'}
            }
            //let cognitoID = session.accessToken.payload.sub;
            let cognitoID = res.data.data.firstName;
            return axios.post(API_PROV_URL + `${cognitoID}/vacancies/post`, data, {headers: header})
        // })
        // .catch(err=>err)
    }

    deleteVacancyLink(data) {
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
            let header;
            if (res == null) {
                throw new Error("You must be logged in to access this resource.")
            }
            else {
                //let accessToken = session.accessToken.jwtToken
                header = {'Content-Type': 'application/json'}
            }
            //let cognitoID = session.accessToken.payload.sub;
            let cognitoID = res.data.data.firstName;
            return axios.delete(API_PROV_URL + `${cognitoID}/vacancies/delete`, 
            {headers: header, data: data})
        // })
        // .catch(err=>err)
    }

    removePractitionerLink(data) {
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
            let header;
            if (res == null) {
                throw new Error("You must be logged in to access this resource.")
            }
            else {
                //let accessToken = session.accessToken.jwtToken
                header = {'Content-Type': 'application/json'}
            }
            //let cognitoID = session.accessToken.payload.sub;
            let cognitoID = res.data.data.firstName;
            return axios.post(API_PROV_URL + `${cognitoID}/vacancies/deletePrac`, data,
            {headers: header})
        // })
        // .catch(err=>err)
    }

    editVacancyLink(data) {
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
            let header;
            if (res == null) {
                throw new Error("You must be logged in to access this resource.")
            }
            else {
                //let accessToken = session.accessToken.jwtToken
                header = {'Content-Type': 'application/json'}
            }
            return axios.post(API_PROV_URL + `vacancies/edit`, data, {headers: header})
        // })
        // .catch(err=>err)
    }

    getUnfilledVacanciesLink() {
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
            let header;
            if (res == null) {
                throw new Error("You must be logged in to access this resource.")
            }
            else {
                //let accessToken = session.accessToken.jwtToken
                header = {'Content-Type': 'application/json'}
            }
            //let cognitoID = session.accessToken.payload.sub;
            let cognitoID = res.data.data.firstName;
            return axios.get(API_PROV_URL + `${cognitoID}/vacancies/getUnfilledVacancies`, {headers: header})
        // })
        // .catch(err=>err)
    }

    getUncompletedVacanciesLink() {
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
            let header;
            if (res == null) {
                throw new Error("You must be logged in to access this resource.")
            }
            else {
                //let accessToken = session.accessToken.jwtToken
                header = {'Content-Type': 'application/json'}
            }
            //let cognitoID = session.accessToken.payload.sub;
            let cognitoID = res.data.data.firstName;
            return axios.get(API_PROV_URL + `${cognitoID}/vacancies/getUncompletedVacancies`, {headers: header})
        // })
        // .catch(err=>err)
    }

    getCompletedVacanciesLink() {
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
            let header;
            if (res == null) {
                throw new Error("You must be logged in to access this resource.")
            }
            else {
                //let accessToken = session.accessToken.jwtToken
                header = {'Content-Type': 'application/json'}
            }
            //let cognitoID = session.accessToken.payload.sub;
            let cognitoID = res.data.data.firstName;
            return axios.get(API_PROV_URL + `${cognitoID}/vacancies/getCompletedVacancies`, {headers: header})
        // })
        // .catch(err=>err)
    }

    markVacancyAsCompletedLink(data) {
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
            let header;
            if (res == null) {
                throw new Error("You must be logged in to access this resource.")
            }
            else {
                //let accessToken = session.accessToken.jwtToken
                header = {'Content-Type': 'application/json'}
            }
            return axios.post(API_PROV_URL + `vacancies/markCompleted`, data, {headers: header})
        // })
        // .catch(err=>err)
    }

    getPracsThatApplied(vacancyID) {
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
            let header;
            if (res == null) {
                throw new Error("You must be logged in to access this resource.")
            }
            else {
                //let accessToken = session.accessToken.jwtToken
                header = {'Content-Type': 'application/json'}
            }
            //let cognitoID = session.accessToken.payload.sub;
            let cognitoID = res.data.data.firstName;
            return axios.get(API_PROV_URL + `${cognitoID}/vacancies/${vacancyID}/getpracs`, {headers: header})
        // })
        // .catch(err=>err)
    }

    //PRACTITIONERS

    adminCreateNewPractitioner(data) {
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
            let header;
            if (res == null) {
                throw new Error("You must be logged in to access this resource.")
            }
            else {
                //let accessToken = session.accessToken.jwtToken
                header = {'Content-Type': 'application/json'}
            }
            return axios.post(API_ADMIN_URL + 'practitioners/verifyNewAccount', data, {headers: header})
        // })
        // .catch(err=>err)
    }

    approveHealthFormLink(data) {
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
            let header;
            if (res == null) {
                throw new Error("You must be logged in to access this resource.")
            }
            else {
                //let accessToken = session.accessToken.jwtToken
                header = {'Content-Type': 'application/json'}
            }
            return axios.post(API_ADMIN_URL + 'practitioners/approveHealthForm', data, {headers: header})
        // })
        //     .catch(err=>err)
    }

    fileTransferLink(data) {
        return authHeader().then(header => {
            axios.post(API_PRAC_URL + 'fileTransfer', data, {headers: header})
        }).catch(err=> {console.log(err); return err})
    }
    
    pracEditLink(data) {
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
            let header;
            if (res == null) {
                throw new Error("You must be logged in to access this resource.")
            }
            else {
                //let accessToken = session.accessToken.jwtToken
                header = {'Content-Type': 'application/json'}
            }
            //let cognitoID = session.accessToken.payload.sub;
            let cognitoID = res.data.data.firstName;
            return axios.post(API_PRAC_URL + `${cognitoID}/editInfo`, data, {headers: header})
        // })
        // .catch(err=>err)
    }

    pracGetQualLink(pracID) {
        return authHeader().then(header => axios.get(API_PRAC_URL + `${pracID}/getAdditionalQualifications`, {headers: header})).catch(err=>
            {console.log(err); return err})
    }

    getPracIdentityID(pracID) {
        return authHeader().then(header => 
            axios.get(API_PRAC_URL + `${pracID}/getIdentityID`, {headers: header}))
            .catch(err=> {console.log(err); return err})
    }

    pracFileUploadLink(data) {
        return axios.post(API_LINK + `practitioners/register/fileUpload`, data)
    }

    newPracAccountReqLink(data) {
        return axios.post(API_LINK + `practitioners/register/newAccountRequest`, data)
    }    

    checkSignn(username, password){
        const url = API_LINK + `practitioners/register/checkSignn?username=`+username+`&password=`+password;
        return axios.post(url)
    }

    checkAHPRALink(id) {
        return axios.get(API_LINK + `practitioners/checkAHPRA/${id}`)
    }

    getPracInfo() {
        let res = JSON.parse(localStorage.getItem("user"));
        let header;
        if (res == null) {
            throw new Error("You must be logged in to access this resource.")
        }
        else {
            header = {'Content-Type': 'application/json'}
        }
        let cognitoID = res.data.data.firstName;
        return axios.get(API_PRAC_URL + `${cognitoID}/getInfo`, {headers: header})
    }

    uploadHealthFormLink(data) {
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
            let header;
            if (res == null) {
                throw new Error("You must be logged in to access this resource.")
            }
            else {
                //let accessToken = session.accessToken.jwtToken
                header = {'Content-Type': 'application/json'}
            }
            //let cognitoID = session.accessToken.payload.sub;
            let cognitoID = res.data.data.firstName;
            return  axios.post(API_PRAC_URL + `${cognitoID}/healthFileUpload`, data, {headers: header})
        // })
        // .catch(err=>err)
    }

    getEmploymentHistoryLink(pracID){
        return authHeader().then(header => 
            axios.get(API_PRAC_URL + `${pracID}/getEmploymentHistory`, {headers: header}))
            .catch(err=> {console.log(err); return err})
    }

    getRefereesLink(pracID){
        return authHeader().then(header => 
            axios.get(API_PRAC_URL + `${pracID}/getReferees`, {headers: header}))
            .catch(err=> {console.log(err); return err})
    }

    //PRACTITIONERS AND VACANCIES
    withdrawFromVacancy(data) {
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
            let header;
            if (res == null) {
                throw new Error("You must be logged in to access this resource.")
            }
            else {
                //let accessToken = session.accessToken.jwtToken
                header = {'Content-Type': 'application/json'}
            }
            //let cognitoID = session.accessToken.payload.sub;
            let cognitoID = res.data.data.firstName;
            return axios.post(API_PRAC_URL + `${cognitoID}/withdrawVacancy`, data, {headers: header})
        //})
    }

    applyForVacancy(data) {
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
        let header;
        if (res == null) {
            throw new Error("You must be logged in to access this resource.")
        }
        else {
            //let accessToken = session.accessToken.jwtToken
            header = {'Content-Type': 'application/json'}
        }
        //let cognitoID = session.accessToken.payload.sub;
        let cognitoID = res.data.data.firstName;
        return axios.post(API_PRAC_URL + `${cognitoID}/applyForVacancy`, data, {headers: header})
        // })
        // .catch(err=>err)
    }

    getAcceptedVacanciesLink() {
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
        let header;
        if(res == null) {
            throw new Error("You must be logged in to access this resource.")
        }
        else {
            //let accessToken = session.accessToken.jwtToken
            //header = {'Content-Type': 'application/json', 'accesstoken': accessToken}
            header = {'Content-Type': 'application/json'}
        }
        //let cognitoID = session.accessToken.payload.sub;
        let cognitoID = res.data.data.firstName;
        return axios.get(API_PRAC_URL + `${cognitoID}/getAcceptedVacancies`, {headers: header})
        // })
        // .catch(err=>err)
    }

    getAppliedVacanciesLink(cognitoID) {
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
            let header;
            if (res == null) {
                throw new Error("You must be logged in to access this resource.")
            }
            else {
                //let accessToken = session.accessToken.jwtToken
                header = {'Content-Type': 'application/json'}
            }
            //let cognitoID = session.accessToken.payload.sub;
            //let cognitoID = res.data.data.firstName;
            return axios.get(API_PRAC_URL + `${cognitoID}/getAppliedVacancies`, {headers: header})
        //})
    }

    searchVacancyLink(specialty, level, start, end, rate, acc, travel) {
        let res = JSON.parse(localStorage.getItem("user"));
        //return Auth.currentSession().then(session => {
            let header;
            if (res == null) {
                throw new Error("You must be logged in to access this resource.")
            }
            else {
                //let accessToken = session.accessToken.jwtToken
                header = {'Content-Type': 'application/json'}
            }
            //let cognitoID = session.accessToken.payload.sub;
            let cognitoID = res.data.data.firstName;
            return axios.get(API_PRAC_URL + `getVacancies/?specialty=${specialty}&level=${level}&start=${start}&end=${end}&rate=${rate}&acc=${acc}&travel=${travel}&currUser=${cognitoID}`, {headers: header})
        //})
    }
}

export default new UserService();