import React, {useState} from "react"
import styled from 'styled-components'
import {SortingBar} from "./SortingBar"
import {Vacancy} from "./Vacancy"
import {SearchBar} from "./SearchBar"
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import moment from "moment"
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
export const Title = styled.h1`
font-size: 1.3rem; 
display: flex;
align-items: center;
font-weight: 500;
`

export const VacanciesCount = styled.div`
margin-left: 1rem;
background-color: black;
color: white;
font-size: 1rem; 
width: 25px;
height: 25px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 20px;
`

export const Container = styled.div`
padding: 15px;
border-radius: 10px;
background: #f3f3f3;

`
const Text = styled.h1`
font-size: 0.8rem;
text-transform: uppercase;
font-weight: 500;
color: black;
text-align: center;
width: 33%;
align-items: center;
`

const ArrowLeft = styled(FaArrowLeft)`
&:hover {
    cursor: pointer;
}
`

const ArrowRight = styled(FaArrowRight)`
&:hover {
    cursor: pointer;
}
`

const DropDownDiv = styled.div`
display:flex;
justify-content: space-between;

@media screen and (max-width: 870px) {
    align-items: center;
    justify-content: space-around;


  }
`


const options = [
  'Start Date', 'End Date', 'Specialty', 'Level', 'Date posted'
]


export const Vacancies = ({title, vacancies, action, showBar, show}) => {
    const [isDesktop, setDesktop] = useState(window.innerWidth > 800);
    let [index, setIndex] = useState(0);
    let [data, setData] = useState(useState(vacancies.slice(0, index)))

    React.useEffect(() => {
        const handleResizeWindow = () => setDesktop(window.innerWidth > 800);
         // subscribe to window resize event "onComponentDidMount"
         window.addEventListener("resize", handleResizeWindow);
         return () => {
           // unsubscribe "onComponentDestroy"
           window.removeEventListener("resize", handleResizeWindow);
         };
         

         
       }, []);


       const sort = (info) => {
           const {value, label} = info;
           if (value === "Start Date") {
               
               vacancies = vacancies.sort((a, b) => {
                   let d1 = new Date(a.startDate.split("/").reverse().join("-"));
                   let d2 = new Date(b.startDate.split("/").reverse().join("-"));
                   return(
                    d1 < d2 ? -1 : 1
                )
               })

           }
           else if (value === "End Date") {
            vacancies = vacancies.sort((a, b) => {
                let d1 = new Date(a.endDate.split("/").reverse().join("-"));
                let d2 = new Date(b.endDate.split("/").reverse().join("-"));
                return(
                    d1 < d2 ? -1 : 1
                )
            })

           }
           else if (value === "Specialty") {
            vacancies = vacancies.sort((a, b) => a.specialty < b.specialty ? -1 : 1)
           }
           else if (value === "Level") {            

            vacancies = vacancies.sort((a, b) => parseInt(a.trainingLevel) < parseInt(b.trainingLevel) ? -1 : 1)

           }
           else if (value === "Date posted") {
               vacancies.sort((a, b) => moment(a.datePosted) < moment(b.datePosted) ? -1 : 1)

           }
           setData(vacancies.slice(index, index + 10));

       }


       React.useEffect(()=>{
        setData(vacancies.slice(index, index + 10));
       },[vacancies, index]);



    return(
        <>
        <DropDownDiv>
        <Dropdown placeholder="Sort by..." className="dropdown" options={options} onChange={(value)=>sort(value)}  />
        {isDesktop &&  <SearchBar availInfo={vacancies} setData={setData}/>}
        </DropDownDiv>

    <Container>

    <Title>{title}<VacanciesCount>{vacancies.length}</VacanciesCount></Title>
    {isDesktop && 
    <SortingBar>
        <Text>
            Position
        </Text>
        <Text>
            Dates
        </Text>
        <Text>
            Details
        </Text>

        {showBar && 
        <Text>
            Applicants
        </Text> }

        

    </SortingBar>
}
 {!isDesktop && <br></br>}


    {data && data.map((vacancy, idx) =>
        <Vacancy data={vacancy} key={idx} action={action} show={show} />
     )}
     {vacancies.length > 0 && 

     <div style={{ padding: "1rem", margin: "0 auto", alignItems: "center", display:"flex", justifyContent: "space-around"}}>

        <ArrowLeft onClick={()=>{
            if (index - 10 >=0) setIndex(prev=>prev-10)}
        }/>

<span > Page {1 + index/10} of {Math.ceil(vacancies.length / 10)} </span>

        <ArrowRight className="arrows" onClick={()=>{
            if (index + 10 < vacancies.length)
            setIndex(prev=>prev+10)}
        }/>

        </div>
}

    </Container> 
    </>)


}