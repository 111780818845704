import React from "react";
import { Field } from "formik";
import * as yup from "yup";
import { SubFormLabel, ErrorMsg, FormP, FormInput } from "../../SignUpElements";
import { useValues } from "../../useValues";
import { withSubForm } from "../../withSubForm";
import {MAX_EMAIL_LENGTH, MAX_DESC_LENGTH, MAX_NAME_LENGTH}  from "../../../../helpers/characterLimits"; 


const phoneRegExp = /^0{1}(2|4|3|7|8|5){1}[0-9]{8}$/;
const trimPhoneNumber = (number) => number.replace(/[- )(]/g, "");

const refSchema = yup.object().shape({
  firstName: yup.string().required("You must enter a first name."),
  lastName: yup.string().required("You must enter a last name. "),
  phone: yup
    .string()
    .matches(phoneRegExp, "Incorrect format")
    .required("You must enter a phone number."),
  placeOfWork: yup.string().required("You must enter a place of work. "),
  email: yup
    .string("Invalid email address")
    .required("You must enter an email address.")
    .email(),
});

const RefFormItem = ({ name, errors, touched, ...props }) => {
  useValues(name, props);
  return (
    <>
      <SubFormLabel>
        First Name <span className="required">*</span>{" "}
      </SubFormLabel>

      <Field name="firstName" className="formInput" maxLength={MAX_NAME_LENGTH}/>
      {errors.firstName && touched.firstName ? (
        <ErrorMsg>{errors.firstName}</ErrorMsg>
      ) : null}

      <SubFormLabel>
        Last Name <span className="required">*</span>{" "}
      </SubFormLabel>

      <Field name="lastName" className="formInput" maxLength={MAX_NAME_LENGTH}/>
      {errors.lastName && touched.lastName ? (
        <ErrorMsg>{errors.lastName}</ErrorMsg>
      ) : null}

      <SubFormLabel>
        Place of Work <span className="required">*</span>{" "}
      </SubFormLabel>

      <Field name="placeOfWork" className="formInput" maxLength={MAX_DESC_LENGTH}/>
      {errors.placeOfWork && touched.placeOfWork ? (
        <ErrorMsg>{errors.placeOfWork}</ErrorMsg>
      ) : null}

      <SubFormLabel>
        Phone number <span className="required">*</span>{" "}
      </SubFormLabel>

      <FormP>
        Can be mobile or telephone. If telephone, please provide the area code.{" "}
      </FormP>
      <FormP>Format: 0XXXXXXXXX</FormP>

      

      <Field name="phone">
        {({ field, form: { touched, errors }, meta }) => (
          <>
            <FormInput
              {...field}
              className="formInput"
              name="phone"
              onChange={(e) => {
                field.onChange(e);
                field.value = trimPhoneNumber(field.value);
              }}
            />
            {meta.touched && meta.error && (
              <ErrorMsg className="error">{meta.error}</ErrorMsg>
            )}
          </>
        )}
      </Field>
      <SubFormLabel>
        Email <span className="required">*</span>
      </SubFormLabel>

      <Field name="email" className="formInput" maxLength={MAX_EMAIL_LENGTH} />
      {errors.email && touched.email ? (
        <ErrorMsg>{errors.email}</ErrorMsg>
      ) : null}
    </>
  );
};

export const RefForm = withSubForm(RefFormItem, refSchema);
