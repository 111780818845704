import React, {useContext} from "react"
import styled from "styled-components"
import {Profile} from "./Profile"
import {Menu} from "./Menu"
import HamburgerMenu from "react-hamburger-menu";
import {ProviderDashboardContext} from "./ProviderDashboardContext"


export const MobileSidebar = ({isOpen, setIsOpen, toggle}) => {
  let context = useContext(ProviderDashboardContext)

    return(
    <SidebarContainer isOpen={isOpen}>
    <Header>
       <HamburgerMenu
    isOpen={isOpen}
    menuClicked={toggle}
    height={20}
    color="white"
    strokeWidth={3}
    borderRadius={2}
    animationDuration={0.5}
/>
</Header>
        <Profile provName={context.userData.providerName}/>
        <Menu setIsOpen={setIsOpen}/>
    </SidebarContainer>
    )
}

export const SidebarContainer = styled.aside`
  display: none;

  @media screen and (max-width: 600px) {
    position: fixed;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background: green;
    align-items: center;
    display:flex;
    flex-direction:column;
    justify-content: space-evenly;
    padding-bottom: 20%;
    background:#001a33;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
    top: ${({ isOpen }) => (isOpen ? "0" : "-200%")}
  }

`;

export const Header = styled.div`
display: flex;
width: 100%; 
justify-content: flex-end;
margin-right: 50px;
`
