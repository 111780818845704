import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
padding: 0.4rem;
margin: 2rem 0;
border-radius: 5px;
background: #f8f8f8;
display: flex;
align-items: center;
justify-content: space-evenly;
`

export const SortingBar = (props) => {
    return(
        <Container>
            {props.children}
        </Container>
    )


}