import React, { useContext, useState, useEffect } from "react";
import "react-dropdown/style.css";
import {
  FormLabel,
  FormButton,
  FormSection,
  ArrowForward,
  FormSelect,
  ErrorMsg,
  FormH2,
  FormInput,
} from "../SignUpElements";
import { Formik, Field, FieldArray} from "formik";
import * as yup from "yup";
import { StepContext } from "../StepContext.js";

const statesArray = [
    { value: "ACT", label: "ACT" },
    { value: "NSW", label: "NSW" },
    { value: "NT", label: "NT" },
    { value: "SA", label: "SA" },
    { value: "QLD", label: "QLD" },
    { value: "VIC", label: "VIC" },
    { value: "WA", label: "WA" }
  ];

const prefSchema = yup.object().shape({
    states: yup.array().of(
        yup.string().required("Please enter at least one state where you would like to work")),
    areaOfWork: yup.string().required("Please enter an area of work.")
});

const WorkPref = ({
    states, 
    areaOfWork,
    submitHandler
}) => {
  const context = useContext(StepContext);
  const [saved, setSaved] = useState(false);
  useEffect(() => window.scroll(0,0), [])

  const back = (e) => {
    e.preventDefault();
    if (!saved) {
      context.backStepAlert();
    }
  };

  return (
    <FormSection id="basicinfo">
      <Formik
        initialValues={{
            states:states,
            areaOfWork:areaOfWork,
        }}
        validationSchema={prefSchema}
        onSubmit={(values) => {
          
          submitHandler({ workPref: values });
          setSaved(true);

          if (context.reachedSubmit) {
            context.setStep(context.REVIEW_STEP);
          } else {
            context.increment();
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
          setFieldValue,
        }) => (
            <FormSection>
                    <FormH2>5. Locum Placement Preference </FormH2>

                
                <FormLabel>In which states would you like to work? Select all that apply.</FormLabel>

                <FieldArray name="states"
                    render={arrayHelpers => 
                    <>
                    {statesArray.map((state, i) => 
                    <label htmlFor={state.label} key={i}>
                    <FormInput
                        name="states"
                        type="checkbox"
                        value={state.value}
                        checked={values.states && values.states.includes(state.value)}
                        onChange={e => {
                            if (e.target.checked) {
                            arrayHelpers.push(state.value);
                          } else {
                            const idx = values.states.indexOf(state.value);
                            arrayHelpers.remove(idx);
                          }
                        }}
                    />
                    <span>{state.label}</span>
                    </label>
                    )}
                </>}
                />


                <FormLabel>Please select your preferred area of work. </FormLabel>
                <Field name="areaOfWork">
                {({ field, form: { touched, errors }, meta }) => (
                  <>
                        <FormSelect
                {...field} style={{ width: "100%" }}
              >
                <option value="" label="Select area of work ... " />
                <option value="Anasthetics" label="Anasthetics" />
                <option value="Emergency" label="Emergency" />
                <option value="General Practice" label="General Practice" />
                <option value="ICU/CCU" label="ICU/CCU" />
                <option value="OB/GYN" label="OB/GYN" />
                <option value="Paediatrics" label="Paediatrics" />
                <option value="Psychiatry" label="Psychiatry" />
                <option value="Other" label="Other" />

              </FormSelect>
              {meta.touched && meta.error && (
              <ErrorMsg className="error">{meta.error}</ErrorMsg>
              )}
              </>
              )}
              </Field>

              {!context.reachedSubmit ? (
              <FormButton type="button" onClick={() => {
                
                handleSubmit();
            
              
              }}>
                Save and Continue <ArrowForward />{" "}
              </FormButton>
            ) : (
              <FormButton type="button" onClick={handleSubmit}>
                Return to Summary Page
              </FormButton>
            )}
            {!context.reachedSubmit && (
              <FormButton
                onClick={(e) => (dirty ? back(e) : context.decrement())}
              >
                Back
              </FormButton>
            )}




            </FormSection>
        )
        }


      </Formik>
    </FormSection>
  );
};

export default WorkPref;
