import React from "react";
import PractitionerSignUp from "../components/PractitionerSignUp";
import { StepContextProvider } from "../components/PractitionerSignUp/StepContext";

const PractitionerSignUpPage = () => {
  return (
    <StepContextProvider>
      <PractitionerSignUp />
    </StepContextProvider>
  );
};

export default PractitionerSignUpPage;
