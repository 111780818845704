import React, { useState } from "react";
import {
  Container,
  FormLabel,
  FormWrap,
  FormSection,
  FormButton,
  FormInput,
  FormH1,
  FormP,
  ErrorMsg,
  BackBtnWrap,
} from "./SignInElements";
import { FaArrowLeft } from "react-icons/fa";

export const EnterCodeandPWord = ({setStage, email, setEmail, resetPassword}) => {
    const [password, setPassword] = useState("");
    const [code, setCode] = useState("");
    const [confirmedPassword, setConfirmedPassword] = useState("");
    const [err, setErr] = useState("");

  return (
    <Container>
      <FormWrap>
        <FormSection>
          <BackBtnWrap>
            <FaArrowLeft
              onClick={() => {
                setStage(1);
                setPassword("");
                setCode("");
                setConfirmedPassword("");
                setEmail("");
              }}
              color="#63a9ff"
              size={20}
            />
          </BackBtnWrap>

          <FormH1 style={{ marginBottom: "10px" }}>Create new password</FormH1>
          <FormP style={{ textAlign: "center" }}>
            Please enter the verification code sent to {email}.
          </FormP>
          <FormLabel>Code</FormLabel>
          <FormInput
            value={code}
            onChange={(e) => {
              setErr("");
              setCode(e.target.value);
            }}
          />
          <FormLabel>Password</FormLabel>
          <FormInput
            type="password"
            value={password}
            onChange={(e) => {
              setErr("");
              setPassword(e.target.value);
            }}
          />
          <FormLabel>Confirm password</FormLabel>
          <FormInput
            type="password"
            value={confirmedPassword}
            onChange={(e) => {
              setErr("");
              setConfirmedPassword(e.target.value);
            }}
          />
          <ErrorMsg className={err.length > 0 && "active"}>{err}</ErrorMsg>
          <FormButton onClick={() => resetPassword(email, confirmedPassword, code, password).then(res=>console.log(res)).catch(err=>setErr(err.errMsg))}>Change password</FormButton>
        </FormSection>
      </FormWrap>
    </Container>
  );
};
