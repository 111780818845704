import React from "react";
import { useHistory } from "react-router-dom";
import {Container, MessageWrap, MessageContent, MessageButton, MessageText, BtnText} from "./MessageElements.js"

const RedirectMessage = (props) => {
  console.log(props);
  const history = useHistory();

  if (!props.history.location.state) {
    history.push("/");
  }

  return (
    <Container>
      <MessageWrap>
      <MessageContent>
      <MessageText>
      {props.history.location.state && props.history.location.state.message}
      </MessageText>
      <MessageButton onClick={() => history.push("/")}>
        <BtnText>Return to home page</BtnText>
      </MessageButton>



      </MessageContent>
      


      </MessageWrap>

    </Container>
  );
};

export default RedirectMessage;
