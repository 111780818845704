import React, { useState } from "react";
import { Auth } from "aws-amplify";
import SignInForm from "./SignInForm";
import NewPasswordForm from "./NewPasswordForm";
import { useHistory } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import { FaCheck } from "react-icons/fa";
import FlashMessage from "react-flash-message";
import styled from "styled-components";
import UserService from "../../services/userService";
import { handleErr } from "../../helpers/handleErr";
import { SignInAndRegHeader } from "../SignInAndRegHeader";

const SignIn = () => {
	const [isFirstLogin, setIsFirstLogin] = useState(true);
	const [verificationMessage, setVerificationMessage] = useState("");
	const [errMsg, setErrMsg] = useState(true);
	const [forgotPassword, setForgotPassword] = useState(false);
	const history = useHistory();
	const [currUser, setCurrUser] = useState("");
	const [loading, setLoading] = useState(false);
	let identityID = "";

	const handleLogin = (username, password) => {
		setLoading(true);
		UserService.checkSignn(username, password)
		.then(res => {
			console.log(res);
			setLoading(false);
			setCurrUser(res);
			localStorage.setItem('user', JSON.stringify(res));
			history.push({
				pathname: "/practitionerDashboard",
			});
		})
		.catch((err) => {
			console.log(err);
		});
	};

	const makeNewPassword = (user, newPassword) => {
	setLoading(true);
	Auth.completeNewPassword(user, newPassword)
		.then((user) => {
		setLoading(false);
		Auth.signOut()
			.then((res) => {
			setIsFirstLogin(true);
			setVerificationMessage("New password created");
			})
			.catch((err) => {
				setLoading(false);
				let message = handleErr(err);
				history.push({
					pathname: "/serverError",
					state: { message: message },
				});
			});
		})
		.catch((e) => {
		setLoading(false);
		setErrMsg(e.message);
		console.log(e);
		});
	};

  return (
    <Wrapper>
      <SignInAndRegHeader />
      {verificationMessage && (
        <FlashMessage duration={2000}>
          <div
            style={{
              background: "#f8f8f8",
              marginTop: "10px",
              padding: "10px",
              color: "green",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <p>
              {verificationMessage} <FaCheck />
            </p>
          </div>{" "}
        </FlashMessage>
      )}

      {forgotPassword && (
        <ForgotPassword
          setVerificationMessage={setVerificationMessage}
          setForgotPassword={setForgotPassword}
        />
      )}

      {isFirstLogin && !forgotPassword && (
        <SignInForm
          handleLogin={handleLogin}
          errMsg={errMsg}
          loading={loading}
          setForgotPassword={setForgotPassword}
        />
      )}

      {!isFirstLogin && (
        <NewPasswordForm
          makeNewPassword={makeNewPassword}
          errMsg={errMsg}
          loading={loading}
          user={currUser}
        />
      )}
    </Wrapper>
  );
};

export default SignIn;

export const Wrapper = styled.div`
  margin-top: -45px;
`;
