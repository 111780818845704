import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { useField, useFormikContext } from "formik";

import "react-datepicker/dist/react-datepicker-cssmodules.css";

export const ReactDatePicker = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <DatePickerDiv>
      <DatePicker
        {...field}
        {...props}
        dateFormat="dd/MM/yyyy"
        minDate={props.minDate}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
      ></DatePicker>
    </DatePickerDiv>
  );
};

const DatePickerDiv = styled.div`
  position: relative;
  display: flex;
`;
