

import {FormSelect} from "./FormElements"
import {useField, useFormikContext} from "formik"

export const TrainingLevelPickerField = (props) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    return (
    <FormSelect style={{ width: "100%" }} {...field} onChange={(e) => setFieldValue(props.name, e.target.value)}
    >
                <option value="" label="Select level of training..." />
                <option value="1" label="JMO" />
                <option value="2" label="RMO" />
                <option value="3" label="VMO" />
                <option value="4" label="CMO" />
                <option value="5" label="SMO" />
                <option value="6" label="Registrar" />
                <option value="7" label="Fellow" />
                <option value="8" label="Consultant" />

  </FormSelect>
    )
}