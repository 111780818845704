
import React, {useContext, useEffect} from "react"
import {Vacancies} from "./Vacancies"
import {ProviderDashboardContext} from "./ProviderDashboardContext"
import {DetailsPage} from "./PractitionerView/DetailsPage"
import styled from "styled-components"
import { Switch, Route } from "react-router-dom";

export const Container = styled.div`
padding: 15px;
margin-left: 40px;
margin-right: 40px;
border-radius: 10px;
`
export const PastVacancyPage = () => {

    let {pastVacancies, getData, loading } = useContext(ProviderDashboardContext)
    
    useEffect(() => {
        getData()
    }, [])


    return (
        <Container>
        <Switch>
            <Route exact path="/providerDashboard/pastVacancies">
                <Vacancies showBar={false} title="Past Vacancies" count={pastVacancies.length} vacancies={pastVacancies} /> 
            </Route>
            <Route exact path="/providerDashboard/pastVacancies/view">
                <DetailsPage />
               
            </Route>
        </Switch>
        </Container>
    )
}

