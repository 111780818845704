import React, { useState, useEffect } from "react";
import {
  FormLabel,
  FormWrap,
  Container,
  FormButton,
  FormLink,
  FormInput,
  FormH1,
  ErrorMsg,
  FormSection,
} from "./SignInElements";
import { MiniLoader } from "../Misc/MiniLoader";

import styled from "styled-components";

const SignInForm = ({handleLogin, errMsg, loading, setForgotPassword}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault()
        if (username && password) {
          handleLogin(username, password)
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);


  return (

    <Container>
      <FormWrap>
        <FormSection>
            <FormH1>Practitioner/Provider Sign In</FormH1>
            <FormLabel>
              Username<span className="required">*</span>
            </FormLabel>
            <FormInput
              disabled={loading}
              type="text"
              name="username"
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
            />
             <span style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
            <FormLabel> 
              Password<span className="required">*</span> 
            </FormLabel>
            <SmallBtn onClick={()=>setForgotPassword(true)} style={{fontSize: "13px", 
            outline:"none", border:"none", background: "none"}}>Forgot?</SmallBtn>

            </span>


            <FormInput
              disabled={loading}
              type="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />

            {errMsg && <><ErrorMsg>{errMsg}</ErrorMsg><br></br></>}
            <FormButton className={loading && "disabled"} onClick={()=>handleLogin(username,password)}>
              {loading ? <MiniLoader /> : "Sign In"}
            </FormButton>
            <LinksSection>
              <FormLink to="/practitionersignup">Register as a practitioner</FormLink>
              <FormLink to="/providersignup">Register as a provider </FormLink>
              </LinksSection>
          </FormSection>
        </FormWrap>
        </Container>

  );
};

export default SignInForm;
const LinksSection = styled.div`
display: flex;
align-items: center;
justify-content: space-around;
padding: 0 1rem;
@media screen and (max-width: 480px) {
  flex-direction: column;
}
`
const SmallBtn = styled.button`
color: #69a3ff;
&:hover {
  cursor: pointer;
  color: black;
  transition: all 0.2s ease-in-out;
}
`
export const Wrapper = styled.div`
background: green
margin: 0 auto;
`


