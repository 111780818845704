import React, {useContext} from "react";
import {useHistory} from "react-router-dom"
import styled from "styled-components"
import { PractitionerDashboardContext } from "./PractitionerDashboardContext";
import { confirmAlert } from "react-confirm-alert";
import UserService from "../../services/userService"
import {handleErr} from "../../helpers/handleErr"

const Container = styled.div`
display: flex;
align-items: center;
background: #f8f8f8;
padding: 1.0rem 0rem;
border-radius: 10px;
justify-content: space-evenly;
margin-bottom: 4px;
cursor: pointer;
transition: all ease-in-out 300ms;
`

const Subtitle = styled.div`
font-size: 0.8rem;
color: black;
margin-top: 2px;
margin-left: 30%; 
`


const Practitioner = styled.div`
width: 33%;
text-align: center;
align-items: center;
`
const PropertyStreet = styled.div`
font-size: 1rem;
margin-left: 30%; 
`

const ContentWrapper = styled.div`
display: flex;
flex-direction: column;
`
const WithdrawBtn = styled.button`
outline: none;
border: none;
background: #FF3333;
color: white;
padding: 5px;
border-radius: 10px;
&:hover {
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
}
`
const Action = styled.button`
outline: none;
text-align: center; 
border: none;
background-color: #63a9ff;
color: white;
padding: 5px;
border-radius: 10px;
white-space: nowrap;
   text-align: center;
&:hover {
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
}
@media screen and (max-width: 750px) {
  margin-bottom: 3px;
  }
`

const BtnDiv = styled.div`
display: flex;
justify-content: space-around;
width: 33%;
@media screen and (max-width: 750px) {
flex-direction: column;
align-items: center;
justify-content: space-between;
}

`


export const Vacancy = ({data, withdrawOption}) => {

let history = useHistory()
let {getAppliedVacancies, setLoading} = useContext(PractitionerDashboardContext)

const showAlert = () => {
  confirmAlert({
    title: "",
    message:
      "Are you sure you want to withdraw from this vacancy?",
    buttons: [
      {
        label: "Yes",
        onClick: handleWithdraw,
      },
      {
        label: "No",
        onClick: () => {},
      },
    ],
  });
}

const handleWithdraw = () => {
  setLoading(true);
  UserService.withdrawFromVacancy({
    providerID: data.providerID,
    vacancyID: data.vacancyID
  })
  .then(response=>{
    setLoading(false)
    if (response.data.message === "Success") {getAppliedVacancies()}
    else throw new Error("500: Internal Server Error")
  })
  .catch(err=>{setLoading(false); let message=handleErr(err); 
    history.push({pathname: '/serverError', state: {message: message}})})
  }

const showDetails = () => {
  history.push({
    pathname: "/practitionerDashboard/ongoingVacancies/show",
    state: data})
}
    const levels = ["JMO", "RMO", "VMO", "CMO", "SMO", "Registrar", "Fellow", "Consultant"]
    return(
      <ContentWrapper>
    <Container>
      <Practitioner>
          <PropertyStreet>{levels[data.trainingLevel-1]}</PropertyStreet>
          <Subtitle>{data.specialty}</Subtitle>
      </Practitioner>
      <Practitioner>{data.providername}</Practitioner>
      <Practitioner>{data.startDate}</Practitioner>

      <Practitioner>{data.endDate}</Practitioner>

      <BtnDiv>
      <Practitioner><Action onClick={showDetails}>More Info</Action> </Practitioner>
      
      {withdrawOption ? <Practitioner>
        <WithdrawBtn onClick={showAlert}>Withdraw</WithdrawBtn>
        </Practitioner>
      : null }</BtnDiv>

    </Container>

    </ContentWrapper>
    )

  }

export default Vacancy 