import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {MenuItem} from "../Dashboards/MenuItem"
import UserService from "../../services/userService"
import {useHistory} from "react-router-dom"

const Container = styled.div`
margin-top: 2rem;
width: 100%;
color: white;
`
export const Menu = ({setIsOpen}) => {
    const [active, setActive] = useState([0,0,0,0,0])
    let history = useHistory();

    useEffect(()=> {
        if (history.location.pathname == "/providerDashboard/currentVacancies") {
            setActive([1,0,0,0,0]);
        }
        else if (history.location.pathname == "/providerDashboard/unfilledVacancies") {
            setActive([0,1,0,0,0]);
        }
        else if (history.location.pathname == "/providerDashboard/pastVacancies") {
            setActive([0,0,1,0,0]);
        }
        else if (history.location.pathname == "/providerDashboard/addNewVacancy") {
            setActive([0,0,0,1,0]);
        }
        else {
            setActive([0,0,0,0,1]);

        }
    }, [])

    const makeActive = (num) => {
        if (setIsOpen) setIsOpen(false);
        let dummy = [0,0,0,0,0];
        dummy[num] = 1;
        setActive(dummy);
    }
    return(
        <Container>
            <MenuItem active={active[0] === 1} num={0} makeActive={makeActive} title="Show current vacancies" link = "/providerDashboard/currentVacancies" />
            <MenuItem active={active[1] === 1} num={1} makeActive={makeActive} title="Show unfilled vacancies" link = "/providerDashboard/unfilledVacancies" />
            <MenuItem active={active[2] === 1} num={2} makeActive={makeActive} title="Show past vacancies" link = "/providerDashboard/pastVacancies" />
            <MenuItem active={active[3] === 1} num={3} makeActive={makeActive}title="Add new vacancy" link = "/providerDashboard/addNewVacancy" info = {{initialValues: {
                specialty: "",
                trainingLevel: "",
                startDate: "",
                endDate: "",
                startTime: "",
                endTime: "",
                onCall: "",
                rate: "",
                accommodation: "",
                accAllowance: "",
                travel: "",
                travelAllowance: "",
              },
              title:"Advertise new vacancy",
              buttonTxt:"Submit",
              postFunc: UserService.postVacancyLink,
            }}
              />
            <MenuItem makeActive={makeActive} active={active[4] == 1} num={4} title="Edit contact details" link = "/providerDashboard/editContact" />
        </Container>
    )
}