import React, {useContext, useEffect} from "react"
import {Vacancies} from "./Vacancies"
import {PractitionerDashboardContext} from "./PractitionerDashboardContext"
import {MainLoader} from "../Misc/Loader"
import styled from 'styled-components'

export const Container = styled.div`
padding: 0 2rem;

`
export const OngoingVacancies = () => {

    let {acceptedVacancies, appliedVacancies} = useContext(PractitionerDashboardContext);
    
    useEffect(()=>
    window.scrollTo(0,0)
    ,[])

    if (!acceptedVacancies || !appliedVacancies) {
        return <MainLoader />
        
    }

    return <Container>

    <Vacancies title="Accepted Placements" vacancies={acceptedVacancies} />
    <Vacancies title="Pending Placement Applications" vacancies={appliedVacancies} withdrawOption={true}/>


    </Container>

}
