import React from "react"
import styled from "styled-components"
import {Profile} from "./Profile"
import {Menu} from "./Menu"

export const Container = styled.div`
position: fixed; 
left: 0; 
top: 0;
bottom: 0;
width: ${({ isOpen }) => (isOpen ? "16rem" : "0")};
transition: width 0.2s ease-in-out;
overflow: hidden;
display: flex;
flex-direction: column; 
align-items: center;
background:#001a33;
@media screen and (max-width: 600px) {
    width: 0;
    display: none;
    transition: none;
}

`

;

export const Sidebar = ({isOpen}) => {
    return(
    <Container isOpen={isOpen}>
        <Profile />
        <Menu />
    </Container>
    )
}