

import {FormSelect} from "./FormElements"
import {useField, useFormikContext} from "formik"

export const SpecialtyPickerField = (props) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    return (
    <FormSelect style={{ width: "100%" }} {...field} onChange={(e) => setFieldValue(props.name, e.target.value)}
    >
    <option value="" label="Select specialty ... " />
    <option value="Anasthetics" label="Anasthetics" />
    <option value="Emergency" label="Emergency" />
    <option value="General Practice" label="General Practice" />
    <option value="ICU/CCU" label="ICU/CCU" />
    <option value="OB/GYN" label="OB/GYN" />
    <option value="Paediatrics" label="Paediatrics" />
    <option value="Psychiatry" label="Psychiatry" />
    <option value="Other" label="Other" />
  </FormSelect>
    )
}