import React from "react";
import {
  GetStartedContainer,
  GetStartedWrapper,
  TextWrapper,
  Heading,
  Subtitle,
  BtnWrap,
  ButtonWrapper,
} from "./GetStartedElements";
import { NavBtnLink } from "../Navbar/NavbarElements";

const GetStarted = () => {
  return (
    <GetStartedContainer id="register">
      <GetStartedWrapper>
        <TextWrapper>
          <Heading>Get started. Verify your identity once only. </Heading>
          <Subtitle>
            Follow our simple sign-up process to begin accepting or advertising
            vacancies.
          </Subtitle>
        </TextWrapper>
        <ButtonWrapper>
          <BtnWrap>
            <NavBtnLink to="/practitionersignup" dark2={1}>
              Register as a practitioner
            </NavBtnLink>
          </BtnWrap>
          <BtnWrap>
            <NavBtnLink to="/providersignup">Register as a provider</NavBtnLink>
          </BtnWrap>
        </ButtonWrapper>
      </GetStartedWrapper>
    </GetStartedContainer>
  );
};

export default GetStarted;
