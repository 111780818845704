import React, {useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { FaCalendarAlt } from "react-icons/fa";

export const ReactDatePickerNoFormik = ({ ...props }) => {
    const [date, setDate] = useState("")
  return (
    <DatePickerDiv>
          <FaCalendarAlt style={{marginRight: "5px"}}/>

    <DatePicker
      {...props}
      dateFormat="dd/MM/yyyy"
      selected={date}
      onChange={val => {
       setDate(val)
       props.changeValue(val);
      }} />
  </DatePickerDiv>

      
  );
    }



const DatePickerDiv = styled.div`
  position: relative;

`;
