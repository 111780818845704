/* For use in development */ 

export const initialValuesProd = {
  nameOfProvider: "",
  contactPerson: {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
  },
}

export const initialValuesDev = {
  nameOfProvider: "Medical Provider",
  contactPerson: {
    firstName: "First",
    lastName: "Last",
    phone: "0400000000",
    email: "cl4902@icloud.com",
  },
}


