import React, { useState } from "react";
import {
  Container,
  FormLabel,
  FormWrap,
  FormSection,
  FormButton,
  FormInput,
  FormH1,
  ErrorMsg,
  BackBtnWrap,
} from "./SignInElements";
import { FaArrowLeft } from "react-icons/fa";

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ProvideEmailContainer = ({
  setForgotPassword,
  sendCode,
  email,
  setEmail
}) => {
    const [err, setErr] = useState("");
    return(

  <Container>
    <FormWrap>
      <FormSection>
        <BackBtnWrap>
          <FaArrowLeft
            onClick={() => setForgotPassword(false)}
            color="#63a9ff"
            size={20}
          />
        </BackBtnWrap>
        <FormH1>Send verification code</FormH1>
        <FormLabel>Email</FormLabel>
        <FormInput
          value={email}
          onChange={(e) => {
            setErr("");
            setEmail(e.target.value);
          }}
        />
        <ErrorMsg>{err}</ErrorMsg>
        <FormButton onClick={() => {
            if (!emailRegex.test(email)) {
                setErr("Please enter a valid email");
            }
            else {
                sendCode();
            }
        }
        }>Send verification code</FormButton>
      </FormSection>
    </FormWrap>
  </Container>
    )
};
