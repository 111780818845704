import React, {useContext, useEffect, useState} from "react"
import {Route, Redirect} from "react-router-dom"
import {SessionContext} from "../Misc/SessionContext"
import {MiniLoader} from "../Misc/MiniLoader"
import {useIdleTimer} from 'react-idle-timer'
import {confirmAlert} from "react-confirm-alert"
import {Auth} from "aws-amplify"

function ProtectedRoute({component: Component, ...rest}) {
    const context = useContext(SessionContext);
    const [loading, setLoading] = useState(true)
    const [currUser, setCurrUser] = useState(null);
    const [userGrp, setUserGrp] = useState(null);

    const handleOnIdle = event => {
      Auth.currentSession().then(session => {
          
          let date = new Date(Date.now() + 2*60000);
          var dateStr = date.toString().substring(16,21)
        if (session) {
            setTimeout(() => {
              document.dispatchEvent(new KeyboardEvent('keydown',{key:"Escape", keyCode:27}));
              setCurrUser("")
                Auth.signOut().then(res=>{console.log(res);

                });
            }, 2*60000)
            

            confirmAlert({
          title: 'Your session is about to expire.',
          message: `Do you want to logout? Otherwise, you will be logged out automatically at
          ${dateStr}.
          `,
          buttons: [
            {
              label: 'Yes',
              onClick: () => Auth.signOut().then(res=><Redirect to="/" />)
            },
            {
              label: 'No',
              onClick: () => clearTimeout()
            }
          ],
          closeOnEscape: true,
        })
    


    }
    })
      .catch(err=>console.log(err))
  }


    const handleOnActive = event => {
 
    }
  
    const handleOnAction = (e) => {
    }

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 15 * 60,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
      })
    
      
  

    useEffect(() => {
        context.getLoggedInUserInfo()
        .then(res => {
            setCurrUser(res.sub);
            setUserGrp(res["cognito:groups"][0]);
            setLoading(false);
        })
        .catch(err=>setLoading(false))
    }, [])

    return (
        loading ? <MiniLoader /> :
        <Route {...rest} render={(props) => {
            if (!currUser || rest.for !== userGrp) {
                return <Redirect to={{pathname: '/signin'}} /> 
            } else {
                return <Component currUser = {currUser} />
            }
        }}
         />


    )



    

}

export default ProtectedRoute; 