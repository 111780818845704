import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const Container = styled.div`
  min-height: 692px;
  vertical-align: middle
  padding: 1rem;
  min-width: 300px;
  max-width: 500px;
  margin: 0 auto;
  @media screen and (max-width: 400px) {
    padding-top: 20px;
  }
`;
export const FormLabel = styled.label`
  padding-bottom: 10px;
  font-size: 17px;
`;

export const FormSection = styled.div`
  height: 100%;
  width: 100%;
  padding: 10rem 2rem;
  flex-direction: column;
  justify-content: center;
`;


export const FormInput = styled.input`
  margin-bottom: 16px;
  padding: 10px;
  background: #f3f3f3;
  border: none;
  border-radius: 10px;
  outline: none;
  width: 100%;

  &.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.8;
  }


  
`;

export const FormWrap = styled.div`
  height: 100%; 
  width: 95%;
  margin: 0 auto;

  @media screen and (max-width: 400px) {
    height: 500px;
  }
`;

export const FormContent = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;  
  padding: 3rem 3rem;


  @media screen and (max-width: 480px) {
    padding: 40px;
  }
`;

export const FormP = styled.p`
  margin-bottom: 10px;
  color: black;
  font-size: 15px;
`;

export const BackBtnWrap = styled.div`

&:hover {
  cursor: pointer;
  opacity: 0.2;
  transition: opacity 0.2s ease-in-out;
}
`

export const Form = styled.form`
  max-width: 400px;
  height: auto;
  width: 100%;
  z-index: 1;
  display: grid;
  margin: 0 auto;
  padding: 2rem 2rem;

`;

export const FormH1 = styled.h1`
  margin-bottom: 30px;
  color: black;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
`;

export const FormH2 = styled.h1`
  margin-bottom: 30px;
  color: black;
  font-size: 18px;
  font-weight: 400;
`;

export const ErrorMsg = styled.span`
  font-size: 15px;
  color: red;
  margin-bottom: 20px;

`;

export const FormLink = styled(LinkR)`
  border-radius: 50px;
  white-space: nowrap;
  padding: 10px;
  color: #63a9ff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: #010606;
  }
`;

export const FormButton = styled.button`
  background: #63a9ff;
  padding: 16px 0;
  border: none;
  width: 100%; 
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 20px;
  outline: none;

  &:hover {
    opacity: 0.8;
    transition: opacity 0.25s ease-in;
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    background: grey;
    opacity: 0.8;
  }
`;

export const Icon = styled(LinkR)`
  margin-left: 32px;
  text-decoration: none;
  color: black;
  font-weight: 700;
  font-size: 32px;
  @media screen and (max-width: 480px) {
    margin-left: 16px;
    margin-top: 32px;
    font-size: 28px;
  }
`;
