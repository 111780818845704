
import React from "react"
import {useHistory} from "react-router-dom"
import {FaArrowLeft} from "react-icons/fa"
import styled from "styled-components"
import {MiniLoader} from "../Misc/MiniLoader"

const convertDateFormat = (date) => {
    let newDate = date.split("-").reverse().join("/");
    if (newDate === date) {
     newDate = date.split("/").reverse().join("/");
    }
    return newDate
  }

const levels = ["JMO", "RMO", "VMO", "CMO", "SMO", "Registrar", "Fellow", "Consultant"]
export const DetailsPage = () => {
    const history = useHistory();
    const vacancyInfo = history.location.state;

    if (!vacancyInfo) {
        return <MiniLoader color="#63a9ff" />
    }
    else {
        return(
    <Container>
    <BackBtn onClick={()=>history.goBack()}><BackIcon />Back to vacancies </BackBtn>
        
    <Header>
   <H1>Details for Vacancy </H1>

   </Header>

   <Section>
   <Title>Specialty</Title>
   <Paragraph>{vacancyInfo.specialty}</Paragraph>
   </Section>

   <Section>
   <Title>Training Level</Title>
   <Paragraph>{levels[vacancyInfo.trainingLevel - 1]}</Paragraph>
   </Section>

   <Section>
   <Title>Dates</Title>
   <Paragraph>Start: {convertDateFormat(vacancyInfo.startDate)}</Paragraph>
   <Paragraph>End: {convertDateFormat(vacancyInfo.endDate)}</Paragraph>
   </Section>

   <Section>
       <Title>Times</Title>
       <Paragraph>Start: {vacancyInfo.startTime}</Paragraph>
       <Paragraph>End: {vacancyInfo.endTime}</Paragraph>

   </Section>

   <Section>
<Title>Rate</Title>

<Paragraph>${vacancyInfo.rate}</Paragraph>

   </Section>
   <Section>

<Title>Accommodation</Title>

<Paragraph>{vacancyInfo.accommodation === "notProvided" ? "Not provided" : null}</Paragraph>
<Paragraph>{vacancyInfo.accommodation === "provided" ? "Provided" : null}</Paragraph>
<Paragraph>{vacancyInfo.accommodation === "allowance" ? `Allowance of $${vacancyInfo.accAllowance}`: null}</Paragraph>
</Section>
<Section>

<Title>Travel</Title>
<Paragraph>{vacancyInfo.travel === "notProvided" ? "Not provided" : null}</Paragraph>
<Paragraph>{vacancyInfo.travel === "provided" ? "Provided" : null}</Paragraph>
<Paragraph>{vacancyInfo.travel === "allowance" ? `Allowance of $${vacancyInfo.travelAllowance}`: null}</Paragraph>
</Section>
<Section>

<Title>On Call</Title>
<Paragraph>{vacancyInfo.onCall === '0' ? "No" : "Yes" }</Paragraph>
</Section>



</Container>
        )}
}


export const ActionBtn = styled.button`
outline: none;
background: none;
border: none;
font-weight: bold;
&:hover {
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.5s ease-in;
}
`
export const Container = styled.div`
padding: 10px 30px;
`

export const H1 = styled.h1`
color: navy;
font-size: 20px;
margin-bottom: 10px;
`

export const Header = styled.div`
display: flex;
justify-content: space-between;

`

export const Paragraph = styled.p`
font-size: 16px;
margin-bottom: 4px;
`

export const Title = styled.p`
font-size: 16px;
font-weight: bold;
margin-bottom: 5px;
`

export const Section = styled.div`
background: #f3f3f3;
padding: 10px;
margin-bottom: 10px;
border-radius: 10px;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

`
export const Link = styled.a`
font-size: 16px;
`

export const Footer = styled.div`
`

export const BackBtn = styled.button`
outline: none;
border: none;
color: navy;
margin-bottom: 30px;
background: none;
font-size: 22px;
font-weight: bold;

&:hover {
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.25s ease-in;
}
`

export const BackIcon = styled(FaArrowLeft)`
padding-top: 5px;
padding-right: 5px;
`
export const IconWrapper = styled.div`

&:hover {
    cursor: pointer;
    opacity: 0.4;
    transition: opacity 0.2s ease-in-out;
}

`