import React from "react";
import ProviderSignUpForm from "./ProviderSignUpForm";
import { Container, FormWrap, Wrapper } from "./ProviderSignUpElements";
import { SignInAndRegHeader } from "../SignInAndRegHeader";

const ProviderSignUp = () => {
  return (
    <Wrapper>
      <SignInAndRegHeader />
      <Container>
        <FormWrap>
          <ProviderSignUpForm />
        </FormWrap>
      </Container>
    </Wrapper>
  );
};

export default ProviderSignUp;
