import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  FormSelect,
  FormButton,
  BtnText,
  SearchBox,
  ExtrasButton,
  MainWrapper,
  FieldWrapper,
  BtnWrapper,
  ExtraWrapper,
  FormLabel,
  InputWrapper,
  SearchH1,
  Container,
  ErrMsg,
} from "./SearchComponents";
import { handleErr } from "../../../helpers/handleErr";
import UserService from "../../../services/userService";
import { SearchResults } from "./SearchResults";
import { ReactDatePickerNoFormik } from "../../Misc/ReactDatePickerNoFormik";
import {PractitionerDashboardContext} from "../PractitionerDashboardContext"
import { ConsoleLogger } from "@aws-amplify/core";

const MAX_LEVEL = 8;

export const Search = () => {
  const [extras, showExtras] = useState(false);
  const [pending, setPending] = useState("");
  const [err, setErr] = useState("");
  const [specialty, setSpecialty] = useState("");
  const [level, setLevel] = useState(MAX_LEVEL);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [rate, setRate] = useState(0);
  const [acc, setAcc] = useState("");
  const [travel, setTravel] = useState("");
  let {res, setRes} = useContext(PractitionerDashboardContext);

  const parseTheDate = (date) => {
    var parts = date.split("/");
    var dt = new Date(
      parseInt(parts[2], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[0], 10)
    );
    console.log(dt);
    return dt;
  };

  const filterDataByDate = (data) => {
    let resToKeep = [];
    for (let i = 0; i < data.length; i++) {
      if (end) {
        if (start) {
          if (start - end > 0) {
            setErr(
              "Invalid Search Parameters - end date must occur after start date"
            );
          }
          if (
            new Date(start) - parseTheDate(data[i].startdate) <= 0 &&
            new Date(end) - parseTheDate(data[i].enddate) >= 0
          ) {
            // keep
            resToKeep.push(data[i]);
          }
        } else {
          if (new Date(end) - parseTheDate(data[i].enddate) >= 0) {
            resToKeep.push(data[i]);
          }
        }
      } else if (start) {
        // start date must be after
        if (new Date(start) - parseTheDate(data[i].startdate) <= 0) {
          resToKeep.push(data[i]);
        }
      } else {
        resToKeep.push(data[i]);
      }
    }
    return resToKeep;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let history = useHistory();

  const doSearch = () => {
    setErr("");
    setRes("");
    setPending(true);
    UserService.searchVacancyLink(
      specialty,
      level,
      start,
      end,
      rate,
      acc,
      travel
    )
      .then((res) => {
        let resToKeep = filterDataByDate(res.data);
        resToKeep.sort((a,b) => {
          return new Date(a.dateposted) - new Date(b.dateposted) > 0 ? -1 : 1;
        })
        //resToKeep = resToKeep.filter(res=>res.dateapplied===null);
        setRes(resToKeep);
        setPending(false);
      })
      .catch((err) => {
        setPending(false);
        if (err.request)
          setErr(
            "Sorry, there was a problem performing the search. Please try again later."
          );
        else {
          let message = handleErr(err);
          history.push({
            pathname: "/serverError",
            state: { message: message },
          });
        }
      });
  };

  return (
    <Container>
      <SearchH1>Search for vacancies</SearchH1>
      <SearchBox>
        <FieldWrapper>
          <MainWrapper>
            <InputWrapper>
              <FormSelect
                style={{ width: "100%" }}
                onChange={(e) => setSpecialty(e.target.value)}
              >
                <option value="" label="Any" />
                <option value="Anasthetics" label="Anasthetics" />
                <option value="Emergency" label="Emergency" />
                <option value="General Practice" label="General Practice" />
                <option value="ICU/CCU" label="ICU/CCU" />
                <option value="Paediatrics" label="Paediatrics" />
                <option value="Psychiatry" label="Psychiatry" />
                <option value="Other" label="Other" />
              </FormSelect>
              <FormLabel>Specialty</FormLabel>
            </InputWrapper>
            <InputWrapper>
              <FormSelect
                onChange={(e) => setLevel(e.target.value)}
                style={{ width: "100%" }}
              >
                <option value="" label="Any" />
                <option value="1" label="JMO" />
                <option value="2" label="RMO" />
                <option value="3" label="VMO" />
                <option value="4" label="CMO" />
                <option value="5" label="SMO" />
                <option value="6" label="Registrar" />
                <option value="7" label="Fellow" />
                <option value="8" label="Consultant" />
              </FormSelect>
              <FormLabel>Experience</FormLabel>
            </InputWrapper>

            <InputWrapper>
              <ReactDatePickerNoFormik changeValue={setStart} />

              <FormLabel>Beginning of your availability</FormLabel>
            </InputWrapper>
            <InputWrapper>
              <ReactDatePickerNoFormik changeValue={setEnd} />

              <FormLabel>End of your availability</FormLabel>
            </InputWrapper>

            <InputWrapper></InputWrapper>
          </MainWrapper>

          <ExtraWrapper className={extras ? "open" : ""}>
            <InputWrapper>
              <FormSelect
                className={extras ? "open" : "hidden"}
                onChange={(e) => setRate(e.target.value)}
              >
                <option value="" label="$0" />
                <option value="50" label="$50" />
                <option value="100" label="$100" />
                <option value="150" label="$150" />
                <option value="200" label="$200" />
                <option value="250" label="$250" />
                <option value="300" label="$300" />
                <option value="400+" label="$400+" />
              </FormSelect>
              <FormLabel className={extras ? "open" : "hidden"}>
                Minimum Hourly Rate
              </FormLabel>
            </InputWrapper>

            <InputWrapper>
              <FormSelect
                className={extras ? "open" : "hidden"}
                onChange={(e) => setAcc(e.target.value)}
              >
                <option value="" label="Any" />
                <option value="provided" label="Provided" />
                <option value="allowance" label="Allowance" />
                <option value="notprovided" label="Not Provided" />
              </FormSelect>
              <FormLabel className={extras ? "open" : "hidden"}>
                Travel
              </FormLabel>
            </InputWrapper>

            <InputWrapper>
              <FormSelect
                className={extras ? "open" : "hidden"}
                onChange={(e) => setTravel(e.target.value)}
              >
                <option value="" label="Any" />
                <option value="provided" label="Provided" />
                <option value="allowance" label="Allowance" />
                <option value="notprovided" label="Not Provided" />
              </FormSelect>
              <FormLabel className={extras ? "open" : "hidden"}>
                Accommodation
              </FormLabel>
            </InputWrapper>
          </ExtraWrapper>
        </FieldWrapper>
        <BtnWrapper>
          <FormButton
            onClick={doSearch}
            to="results"
            activeClass="active"
            spy={true}
            smooth={true}
          >
            <BtnText>Search</BtnText>
          </FormButton>

          <ExtrasButton onClick={() => showExtras(!extras)}>
            {!extras ? "More Options" : "Hide Options"}
          </ExtrasButton>
        </BtnWrapper>

        {err && <ErrMsg id="results">{err}</ErrMsg>}
      </SearchBox>
      <div id="results">
        <SearchResults doSearch={doSearch} res={res} pending={pending} />
      </div>
    </Container>
  );
};
