import React, {useContext, useEffect, useState} from "react"
import {useHistory} from "react-router-dom"
import {Route, Redirect} from "react-router-dom"
import {SessionContext} from "../Misc/SessionContext"
import {MiniLoader} from "../Misc/MiniLoader"

function ProtectedRouteAdmin({component: Component, ...rest}) {
    const context = useContext(SessionContext);
    const [loading, setLoading] = useState(true)
    const [currUser, setCurrUser] = useState(null);
    const [userGrp, setUserGrp] = useState(null);
    const history = useHistory();

    useEffect(() => {
        context.getLoggedInUserInfo()
        .then(res => {
            console.log(res)
            setCurrUser(res.sub);
            setUserGrp(res["cognito:groups"][0]);
            setLoading(false);
        })
        .catch(err=>setLoading(false))
    }, [])



    return (
        loading ? <MiniLoader /> :
        <Route {...rest} render={(props) => {
            if (!currUser || userGrp !== "administrators") {
                return <Redirect to={{
                    pathname: '/administratorsignin',
                    state: { from: history.location }
                }} />
            } else {
                return <Component {...props} currUser = {currUser} />
            }
        }}
         />


    )
}

export default ProtectedRouteAdmin; 