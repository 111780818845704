import React, {useState, useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';
import {useField, useFormikContext} from "formik"
import {FaTrashAlt} from "react-icons/fa"

const getColor = (props) => {
  if (props.isDragAccept) {
      return '#00e676';
  }
  if (props.isDragReject) {
      return '#ff1744';
  }
  if (props.isDragActive) {
      return '#2196f3';
  }
  return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

export default function Dropzone(props) {
    const { setFieldValue } = useFormikContext();
    const field = useField(props);
    const [fileURL, setFileURL] = useState('')
    const [fileName, setFileName] = useState('')

    const generateUrl = (file) => {
        setFileName(file.name)
        let url = URL.createObjectURL(file);
        setFileURL(url)
    }

    useEffect(() => {
        if (field.value && field.value.name) {
           setFileName(field.value.name)
           generateUrl(field.value)
        }
        //console.log("field: ", field)

    }, [])

    const onDrop = React.useCallback(file => {
      if (!file[0]) {
        return;
      }
      generateUrl(file[0])
      setFieldValue(props.name, file[0])
    },[]);


    
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: ['image/jpg', 'image/jpeg', 'application/pdf'],
    maxSize: 1048576,
    onDrop: onDrop
});
  
  return (
    <>
    <div style={{display:"flex", justifyContent:"space-between", marginBottom: "10px"}}>
    <p style={{fontSize: "17px"}}>{props.title} {props.required ? <span className="required">*</span> : "" }   </p>
    <span onClick={()=>{setFieldValue(props.name,""); setFileURL(""); setFileName("")}} 
    className="deleteDoc" style={{marginLeft: "10px", display: "flex"}}>
        <FaTrashAlt style={{alignSelf:"flex-end"}} /></span>
    </div>
    <div className="container">

      <Container field {...getRootProps()}>
        <input onClick= {()=>console.log("HI")}  name={props.name} {...getInputProps()} />
        {(!fileName && !fileURL) && <p>Drag and drop file here</p>}
         <a style={{fontSize:"14px"}}href={fileURL} download>{fileName}</a>
      </Container>

    </div>
    </>
  );
}
