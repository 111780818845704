/**
 * For quick testing purposes
 */

export const init = {
  "personalInfo": {
      "title": "Dr",
      "firstName": "FirstName",
      "lastName": "LastName",
      "DOB": "01/01/1970",
      "homePhone": "0700000000",
      "workPhone": "0700000000",
      "mobile": "0400000000",
      "email": "cmlivingston@student.unimelb.edu.au",
      "address": {
          "unitNumber": "1A",
          "streetNumber": "1",
          "streetName": "Street",
          "suburb": "Suburb",
          "town": "Town",
          "country": "Australia",
          "state": "ACT",
          "postcode": "0000"
      },
      "isAusRes": "no",
      "visaExpiry": "26/07/2021"
  },
  "qualifications": {
      "AHPRA": {
          "AHPRANum": "MED0001000000",
          "AHPRADoc": ""
      },
      "primaryMedDegree": {
          "degreeName": "MED",
          "degreeDoc": "",
          "uni": {
              "name": "Uni",
              "country": "Australia"
          }
      },
      "otherQualifications": [
          {
              "qualificationName": "Qual",
              "qualificationDoc": ""
          }
      ],
      "insurance": {
          "providerName": "Avant",
          "insuranceDoc": ""
      },
      "postGradYear": "1",
      "passport": "",
      "drivers": "",
      "areaOfTraining": "Anasthetics",
      "college": {
          "registered": "yes",
          "name": "College",
          "year": "2021"
      }
  },
  "workInfo": [
      {
          "employer": "employer",
          "position": "position",
          "empName": {
              "firstName": "supervisorfirstname",
              "lastName": "supervisorlastname"
          },
          "empContactNum": "0400000000",
          "empContactEmail": "supervisoremail@example.com",
          "startDate": "01/01/1980",
          "ongoing": "yes",
          "endDate": ""
      }
  ],
  "resume": "",
  "refs": {
      "ref1": {
          "firstName": "Ref",
          "lastName": "One",
          "phone": "0700000000",
          "placeOfWork": "Work",
          "email": "email@email.com"
      },
      "ref2": {
          "firstName": "Ref",
          "lastName": "2",
          "phone": "0700000000",
          "placeOfWork": "Work",
          "email": "email@email.com"
      },
      "ref3": {
          "firstName": "Ref",
          "lastName": "3",
          "phone": "0700000000",
          "placeOfWork": "Work",
          "email": "email@email.com"
      }
  },
  "workPref": {
      "states": [
          "ACT",
          "NSW"
      ],
      "areaOfWork": "Anasthetics"
  }
}