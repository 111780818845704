import React, { useState } from "react";
import {
  Container,
  FormLabel,
  FormWrap,
  FormSection,
  FormButton,
  FormInput,
  FormH1,
  ErrorMsg,
} from "./SignInElements";
import { MiniLoader } from "../Misc/MiniLoader";

const NewPasswordForm = ({makeNewPassword, loading, user}) => {
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [err, setErr] = useState("");

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const handleSubmit = () => {
      if (password !== confirmedPassword) {
          setErr("Please ensure that the two passwords are the same")
          return;
      }
      else if (password.length < 8) {
        setErr("Please ensure that the password is at least 8 characters in length.")
        return;
      }
      else if (!password.match(passwordRegex)) {
        setErr("Please ensure that your password is at least 8 characters and has at least one uppercase letter, one lowercase letter, and one special character.")
      }

      else {
          makeNewPassword(user, password)
      }

  }

  return (
    <Container>
      <FormWrap>
        <FormSection>
            <FormH1>Create New Password</FormH1>
            <FormLabel>
              New Password<span className="required">*</span>
            </FormLabel>
            <FormInput
              type="password"
              name="password"
              pattern={passwordRegex}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
            <FormLabel>
              Confirm New Password<span className="required">*</span>
            </FormLabel>
            <FormInput
              type="password"
              name="password"
              onChange={(e) => setConfirmedPassword(e.target.value)}
              placeholder="Password"
            />
            {err && <ErrorMsg>{err}</ErrorMsg>}
            <FormButton type="button" onClick={handleSubmit}>
              {loading ? <MiniLoader /> : "Sign In"}
            </FormButton>
        </FormSection>
      </FormWrap>
    </Container>
  );
};

export default NewPasswordForm;
