import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import { FaTrashAlt, FaEdit } from "react-icons/fa";

import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";

export const Wrapper = styled.div`
margin: 0 auto;
`

export const Container = styled.div`
  min-height: 692px;
  vertical-align: middle
  padding: 1rem;
  margin: 0 auto;
  @media screen and (max-width: 400px) {
    padding-top: 20px;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  padding: 1rem;
  background: white;
  border-bottom: 1px solid #f3f3f3;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  
  justify-content: space-evenly;
`;

export const DeleteIcon = styled(FaTrashAlt)`
&:hover {
  cursor: pointer;
  opacity: 0.4;
  transition: opacity 0.25s ease-in;
}
`;

export const IconWrap = styled.div`
  background: transparent;
  outline: none;
  display: inline;
  margin-left: 10px;

  &:hover {
    cursor: pointer;
  }
`;

export const EditIcon = styled(FaEdit)`
  font-size: 1.1rem;
  &:hover {
    cursor: pointer;
    opacity: 0.4;
    transition: opacity 0.25s ease-in;
  }
`;

export const FormWrap = styled.div`
  height: 100%;  

`;

export const Icon = styled(LinkR)`
  margin-left: 32px;
  margin-top: 32px;
  text-decoration: none;
  color: black;
  font-weight: 700;
  font-size: 32px;
  @media screen and (max-width: 480px) {
    margin-left: 16px;
    margin-top: 8px;
    font-size: 28px;
  }
`;

export const Blurb = styled.div`
margin-bottom: 20px;
`

export const FormContent = styled.div`
  height: 100%;
  display: flex;
  padding: 4rem;
  justify-content: center;
`;

export const FormSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Form = styled.form`
  background: white;
  max-width: 400px;
  height: auto;
  width: 100%;
  z-index: 1;
  display: grid;
  margin: 0 auto;
  padding: 80px 32px;

  @media screen and (max-width: 400px) {
    padding: 32px 32px;
  }
`;

export const FormH1 = styled.h1`
  margin-bottom: 30px;
  color: black;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
`;

export const FormH2 = styled.h1`
  margin-bottom: 30px;
  color: black;
  font-size: 20px;
  font-weight: 400;
`;

export const FormSubTitle = styled.h1`
  margin-bottom: 20px;
  color: black;
  font-size: 18px;
  font-weight: 400;
`;

export const FormP = styled.p`
  margin-bottom: 10px;
  color: black;
  font-size: 15px;
`;

export const BtnSection = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const FormFieldTitle = styled.p`
  color: black;
  font-size: 15px;
  font-weight: bold;
`;

export const FormLabel = styled.label`
  padding-bottom: 10px;
  font-size: 17px;
  margin-right: 20px;
`;

export const SubFormLabel = styled.div`
  margin-bottom: 20px;
  font-size: 15px;
`;

export const CheckboxWrapper = styled.div`
border: 1px solid black;
align-items: center;
`;

export const FormInput = styled.input`
  margin-bottom: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  `;

export const FormUl = styled.ul`
  font-size: 15px;
  text-align: left;
  margin-bottom: 10px;
  padding-bottom: 10px;
  color: black;
  border-bottom: 1px solid gray;
`;

export const FormHeader = styled.div`
  display: flex;
`;
export const FormLi = styled.ul`
  font-size: 15px;
  list-style-type: square;
`;

export const InfoCard = styled.div`
background: #f8f8f8;
border-radius: 10px;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  padding: 20px;
  margin-bottom: 20px;
  
`;

export const BtnWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: white;
`;

export const SubInfoCard = styled.div`
padding-top: 15px;
padding-bottom: 15px;
`;

export const BtnText = styled.text`
  color: white;
`;

export const FormSwitchBtn = styled.a`
  color: black;
  display: flex;
  text-align: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  outline: none;

  &.current {
    border-bottom: 3px solid #63a9ff;
  }

  &.inaccessible {
    color: grey;
    pointer-events: none;
    cursor: default;
`;

export const FormButton = styled.button`
  background: #63a9ff;
  padding: 16px 0;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  &:hover {
    opacity: 0.8;
    transition: opacity 0.25s ease-in;
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    background: grey;
    opacity: 0.8;
  }
`;

export const SubFormButton = styled.button`
  padding: 10px 10px;
  border: none;
  background: #f8f8f8;
  padding: 0.7rem;
  font-weight: bold;
  border-radius: 4px;
  color: navy;
  font-size: 15px;
  cursor: pointer;
  margin-bottom: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);


  &:hover {
    opacity: 0.8;
    transition: opacity 0.25s ease-in;
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    background: grey;
    opacity: 0.8;
  }
`;

export const Text = styled.span`
  text-align: center;
  margin-bottom: 24px;
  color: black;
  font-size: 14px;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
  position: relative;
  top: 3px;
`;
export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;
export const FormSubSection = styled.div`
  border-bottom: 1px solid #f3f3f3;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
`;


export const Nav = styled.nav`
  height: 80px;
  font-size: 1rem;
  z-index: 10;
  max-width: 1000px;
  margin: 0 auto;
  @media screen and (max-width: 600px) {
    transition: 0.8 all ease;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  height: 80px;
  justify-content: space-evenly;
  align-items: center;
  z-index: 1;
  width: 100%;
  max-width: 1100px;
`;

export const NavMenu = styled.ul`
  display: flex;
  height: 100%; 
  margin: 0 auto;
  align-items: center;
  justify-content: space-evenly;
  list-style: none;
  text-align: center;
`;

export const NavItem = styled.li`
padding: 0.5rem;
@media screen and (max-width: 475px) {
  padding: 0.5rem;

}

`;

export const NavLinks = styled(LinkS)`
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  cursor: pointer;

  &.completed {
    border-bottom: 3px solid #63a9ff;
  }
`;

export const ErrorMsg = styled.span`
  font-size: 15px;
  color: red;
  margin-bottom: 10px;
`;

export const FormContainer = styled.div`
  border-bottom: 1px solid gray;
`;
export const FormSelect = styled.select`
  font-size: 15px;
  margin-bottom: 10px;
  display: inline-block;
`;



export const NavBtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 30%;
  margin-left: 50px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: #63a9ff;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

`;

export const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 70%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;
