
import React, {useContext, useState,useEffect} from "react"
import {Vacancies} from "./Vacancies"
import {ProviderDashboardContext} from "./ProviderDashboardContext"
import {DetailsPage} from "./PractitionerView/DetailsPage"
import { Switch, Route } from "react-router-dom";
import {ShowPractitioners} from "./PractitionerView/ShowPractitioners";
import {PractitionerOverviewCard} from "./PractitionerView/PractitionerOverviewCard";
import { PractitionerReviewPage } from "./PractitionerView/PractitionerReviewPage"
import styled from "styled-components";

export const Container = styled.div`
padding: 15px;
margin-left: 40px;
margin-right: 40px;
border-radius: 10px;
`


  
export const UnfilledVacancyPage = () => {
    
    let {noPracVacancies } = useContext(ProviderDashboardContext)



    return (
        <>
        <Container>
        <Switch>
        <Route exact path="/providerDashboard/unfilledVacancies">
                <Vacancies showBar={true} title="Unfilled Vacancies" count={noPracVacancies.length} vacancies={noPracVacancies} action="viewPrac"/> 
            </Route>
            <Route exact path="/providerDashboard/unfilledVacancies/view">
                <DetailsPage />
            </Route>
            <Route exact path="/providerDashboard/unfilledVacancies/practitioners">
               <ShowPractitioners />
            </Route>
            <Route exact path="/providerDashboard/unfilledVacancies/practitioners/view">
                <PractitionerOverviewCard />
            </Route>
            <Route exact path="/providerDashboard/unfilledVacancies/practitioners/viewOne">
                <PractitionerReviewPage />
            </Route>

            
        </Switch>
        </Container>
        </>
    )
}

