import React, {useContext, useEffect} from "react";
import { PractitionerDashboardContext } from "./PractitionerDashboardContext";
import styled from "styled-components"
import {PersonalInfoCard} from "./PersonalInfoCard"
import {MAX_NAME_LENGTH, MAX_NUM_LENGTH, MID_NUM_LENGTH} from "../../helpers/characterLimits"

const phoneRegExp = /^0{1}(2|4|3|7|8){1}[0-9]{8}$/;
const mobileRegExp = /^0{1}(4|5){1}[0-9]{8}$/;
const postcodeRegExp = /^([0-7]{1}[0-9]{3})$/;
const alphaRegex = /^[A-Za-z ,_-]+$/
const alphaNumRegExp = /^[a-zA-Z0-9_]*$/;

// To check unit number and street number
const checkNumber = (number) => {
    return (alphaNumRegExp.test(number) && number.toString().length < 11) 
}

const checkName = (name) => {
    return alphaRegex.test(name) && name.length <= 30;
}

const checkPC = (postcode) => {
    return postcodeRegExp.test(postcode);
}

const checkPhone = (phone) => {
    return phoneRegExp.test(phone);
}

const checkMobile = (phone) => {
    return mobileRegExp.test(phone);
}


export const PersonalInformation = () => {
  const {pracData} = useContext(PractitionerDashboardContext)

useEffect(() => {
  window.scrollTo(0,0)
}, [])

    return (
    <Container>
        <Body>
        <PersonalH1>Your details</PersonalH1>
        <Wrapper>
            <PersonalH2>Contact Information</PersonalH2>
            <PersonalInfoCard 
        txt={pracData.title}
        title="Title"
        name="title"
        isValid={checkName}
        immutable={true}

        >
            <FormSelect
            name="title"
            style={{ display: "inline" }}
          >
            <option value="" label="Title ... " />
            <option value="Dr" label="Dr" />
            <option value="Mr" label="Mr" />
            <option value="Mrs" label="Mrs" />
            <option value="Ms" label="Ms" />
            <option value="Miss" label="Miss" />
          </FormSelect>
        
        </PersonalInfoCard>



        <PersonalInfoCard 
        txt={pracData.firstName}
        title="First Name"
        name="firstName"
        maxLength={MAX_NAME_LENGTH}
        isValid={checkName}
        immutable={true}

        />
        <PersonalInfoCard 
        txt={pracData.lastName}
        title="Last Name"
        name="lastName"
        maxLength={MAX_NAME_LENGTH}
        isValid={checkName}
        immutable={true}

        />

     <PersonalInfoCard 
        txt={pracData.email}
        title="Email"
        name="email"

        immutable={true}
       />

       <PersonalInfoCard 
        txt={pracData.mobile}
        title="Mobile"
        name="mobile"
        maxLength={MID_NUM_LENGTH}
        isValid={checkMobile}
        />




        <PersonalInfoCard 
        txt={pracData.homePhone}
        title="Home Phone"
        name="homePhone"
        maxLength={MAX_NUM_LENGTH}

        isValid={checkPhone}

        />

        <PersonalInfoCard 
        txt={pracData.workPhone}
        title="Work Phone"
        name="workPhone"
        maxLength={MAX_NUM_LENGTH}

        isValid={checkPhone}

        />
        </Wrapper>

        <Wrapper>
        <PersonalH2>Address</PersonalH2>


        <PersonalInfoCard 
        txt={pracData.unit ? pracData.unit : "None"}
        title="Unit Number"
        name="unit"

        isValid={checkNumber}


        />




<PersonalInfoCard 
        txt={pracData.streetNo}
        title="Street Number"
        name="streetNo"
        isValid={checkNumber}
        />
        
            <PersonalInfoCard 
        txt={pracData.streetName}
        title="Street Name"
        name="streetName"

        isValid={checkName}

        />
                    <PersonalInfoCard 
        txt={pracData.suburb}
        title="Suburb"
        name="suburb"
        maxLength={MAX_NAME_LENGTH}

        isValid={checkName}
        />

<PersonalInfoCard 
        txt={pracData.city}
        title="Town/City"
        name="city"
        maxLength={MAX_NAME_LENGTH}

        isValid={checkName}
        />


<PersonalInfoCard 
        txt={pracData.country}
        title="Country"
        name="country"
        maxLength={MAX_NAME_LENGTH}
        isValid={checkName}
        />

          <PersonalInfoCard 
        txt={pracData.postcode}
        title="Postcode"
        name="postcode"
        maxLength={4}
        isValid={checkPC}
         
        />

<PersonalInfoCard 
        txt={pracData.state}
        title="State"
        name="state"
        isValid={checkName}>
                    <FormSelect >
              <option value="" label="Choose state ... " />
              <option value="ACT" label="ACT" />
              <option value="NSW" label="NSW" />
              <option value="NT" label="NT" />
              <option value="QLD" label="QLD" />
              <option value="SA" label="SA" />
              <option value="TAS" label="TAS" />
              <option value="VIC" label="VIC" />
              <option value="WA" label="WA" />
            </FormSelect>


        </PersonalInfoCard>



        </Wrapper>




            
            </Body></Container>)
}


export const Container = styled.div`
padding: 0 2rem;
display: flex;
flex-direction: column;

`

export const Header = styled.div`
margin-bottom: 10px;
`

export const VacancyWrapper = styled.div`
background: blue;
`

export const Body = styled.div`
display: flex;
flex-direction: column;
justify-content: space-evenly;
margin-bottom: 10px;
background: #f3f3f3;
border-radius: 10px;
padding: 1rem;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
`

export const PersonalH1 = styled.h1`
font-size: 24px;
color: navy;
margin-bottom: 10px;
`
export const PersonalH2 = styled.h1`
font-size: 20px;
margin-bottom: 10px;
padding-left: 4px;

`


export const BtnText = styled.p`
font-size: 16px;
`

export const BackBtn = styled.button`
color: navy;
outline: none;
border: none;
background: none;
margin-bottom: 10px;

&:hover {
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.25s ease-in;
}
`

export const ResMessage = styled.p`
color: navy;

`

export const Wrapper = styled.div`
background: #f8f8f8;
padding: 1rem;
margin-bottom: 10px;
border-radius: 10px;

`



export const FormButton = styled.button`
font-size: 15px;
width: 150px;
height: 40px;
border-radius: 10px;
border: none;
margin-top: 5px;
margin-right: 5px;
margin-bottom: 5px;
padding: 5px;
color: white;
background-color: #63a9ff;
outline:none;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
&:hover{
 cursor: pointer;
 opacity: 0.5;
 transition: opacity 0.2s ease-in-out;
}

`;

export const InfoDiv = styled.div`
display: flex;
overflow: hidden;
justify-content: space-between;
background: white;
border-radius: 10px;
margin-bottom: 10px;

`

export const MsgContainer = styled.div`
align-items: center;
display: flex;
flex-direction: column;
padding-left: 50px;
`

export const FormSelect = styled.select`
  font-size: 15px;
  margin-bottom: 10px;
  height: 35px;
  padding: 0;
`;
