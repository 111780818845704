import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Field } from "formik";
import { FaArrowLeft } from "react-icons/fa";
import { handleErr } from "../../helpers/handleErr";
import { ReactDatePicker } from "../Misc/ReactDatePicker";
import { SpecialtyPickerField } from "../Forms/SpecialtyPickerField";
import { TrainingLevelPickerField } from "../Forms/TrainingLevelPickerField";
import { TimePickerField } from "../Forms/TimePickerField";
import { VacancyValidationSchema } from "../Schemas/VacancyValidationSchema";
import { ProviderDashboardContext } from "./ProviderDashboardContext";
import styled from "styled-components";
import FormikErrorFocus from "formik-error-focus";
import moment from "moment";
import {
  FormLabel,
  FormButton,
  FormSection,
  ErrorMsg,
  FormH1,
  FormWrap,
  FormContent,
  FormSelect,
  FormP,
  FormInput,
  BtnWrap,
} from "../Forms/FormElements";

export const Container = styled.div`
  background: #f3f3f3;
  border-radius: 10px;
  padding: 1rem;
  padding-top: 1.5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  
`;

export const BackBtn = styled.button`
  outline: none;
  border: none;
  color: navy;
  margin-bottom: 30px;
  margin-left: 30px;
  background: none;
  font-size: 18px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.25s ease-in;
  }
`;
export const BackIcon = styled(FaArrowLeft)`
  padding-top: 5px;
  padding-right: 5px;
`;

export const DateWrapper = styled.div`
  float: left;
  margin-bottom: 10px;
`;

const dateParser = (date) => {
  if (date.length) return date;
  var year = date.getFullYear();
  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;
  var day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;
  return month + "/" + day + "/" + year;
};



export const DateTimeWrapper = styled.div``;

const VacancyForm = ({initialValues, postFunc, goBack, onComplete, title, vacancyID, providerID, back}) => {
  let { loading, setLoading, getUnfilledVacancies, noPracVacancies } = useContext(ProviderDashboardContext);
  let history = useHistory();

  const submitFormHandler = async (values) => {
    setLoading(true);
    let valuesCpy = JSON.parse(JSON.stringify(values));
    valuesCpy.startDate = dateParser(values.startDate);
    valuesCpy.endDate = dateParser(values.endDate);
    postFunc({info:valuesCpy, vacancyID: vacancyID, providerID:providerID})
      .then((res) => {
        if (!res.data)
          throw new Error("500: Internal Server Error - Please Try Again Later");
        if (res.data && res.data.message === "Success") 
        if (!vacancyID) {
          getUnfilledVacancies();
        }
        setLoading(false);
        onComplete();

      })
      .catch((err) => {
        setLoading(false);
        let message = handleErr(err);
        history.push({ pathname: "/serverError", state: { message: message } });
      });
  };

  if (loading) {
    return <Container></Container>
  }


  return (
    <Container>
       <> {goBack ? 
      <BackBtn onClick={back}>
      <BackIcon />Cancel
      </BackBtn>     
 : null }
      <FormWrap className={loading ? "disabled" : null}>
        <FormContent>
          <FormSection>
            <Formik
              initialValues={initialValues}
              validationSchema={VacancyValidationSchema}
              onSubmit={submitFormHandler}
            >
              {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                <>
                  <FormH1>{title}</FormH1>

                  <FormLabel htmlFor="for">
                    Specialty <span className="required">*</span>
                  </FormLabel>
                  <SpecialtyPickerField name="specialty" />
                  {errors.specialty && touched.specialty && (
                    <ErrorMsg>{errors.specialty}</ErrorMsg>
                  )}
                  <FormLabel htmlFor="for">
                    Level of Training <span className="required">*</span>
                  </FormLabel>
                  <TrainingLevelPickerField name="trainingLevel" />
                  {errors.trainingLevel && touched.trainingLevel && (
                    <ErrorMsg>{errors.trainingLevel}</ErrorMsg>
                  )}

                  <DateTimeWrapper>
                    <FormLabel htmlFor="startDate">
                      Start Date <span className="required">*</span>
                    </FormLabel>
                    <ReactDatePicker
                      minDate={moment().toDate()}
                      name="startDate"
                    />
                    {errors.startDate && touched.startDate && (
                      <>
                        <br></br>

                        <ErrorMsg style={{ marginTop: "-10px" }}>
                          {errors.startDate}
                        </ErrorMsg>
                        <br></br>
                      </>
                    )}

                    <br></br>

                    <FormLabel htmlFor="startDate">
                      End Date <span className="required">*</span>
                    </FormLabel>
                    <ReactDatePicker
                      minDate={
                        values.startDate && moment(values.startDate).toDate()
                      }
                      name="endDate"
                    />

                    {errors.endDate && touched.endDate && (
                      <ErrorMsg>{errors.endDate}</ErrorMsg>
                    )}

                    <br></br>

                    <FormLabel htmlFor="startTime">
                      Hours <span className="required">*</span>
                    </FormLabel>

                    <FormP style={{ marginTop: "5px" }}>Start</FormP>
                    <TimePickerField name="startTime" />
                    {errors.startTime && touched.startTime && (
                      <ErrorMsg>{errors.startTime}</ErrorMsg>
                    )}
                    <FormP>End</FormP>
                    <TimePickerField name="endTime" />
                    {errors.endTime && touched.endTime && (
                      <ErrorMsg>{errors.endTime}</ErrorMsg>
                    )}
                  </DateTimeWrapper>

                  <FormLabel htmlFor="startDate">
                    On Call <span className="required">*</span>
                  </FormLabel>
                  <FormLabel>Yes</FormLabel>

                  <FormInput
                    type="radio"
                    id="yes"
                    name="onCall"
                    value="1"
                    onChange={(value) => setFieldValue("onCall", "1")}
                    checked={values.onCall === "1"}
                  />
                  <FormLabel>No</FormLabel>
                  <FormInput
                    type="radio"
                    id="no"
                    name="onCall"
                    value="0"
                    onChange={(value) => setFieldValue("onCall", "0")}
                    checked={values.onCall === "0"}
                  />

                  {errors.onCall && touched.onCall && (
                    <ErrorMsg>{errors.onCall}</ErrorMsg>
                  )}

                  <FormLabel htmlFor="startDate">
                    Rate per hour <span className="required">*</span>
                  </FormLabel>
                  <Field
                    name="rate"
                    className="formInput"
                    placeholder="$"
                    maxLength={40}
                  />
                  {errors.rate && touched.rate && (
                    <ErrorMsg className="error">{errors.rate}</ErrorMsg>
                  )}

                  <FormLabel htmlFor="startDate">
                    Accommodation <span className="required">*</span>
                  </FormLabel>

                  <Field name="accommodation">
                    {({ field, form: { touched, errors }, meta }) => (
                      <>
                        <FormSelect {...field} style={{ width: "100%" }}>
                          <option value="" label="Select accommodation... " />
                          <option value="notProvided" label="Not provided" />
                          <option value="provided" label="Provided" />
                          <option value="allowance" label="Allowance" />
                        </FormSelect>
                        {meta.touched && meta.error && (
                          <ErrorMsg className="error">{meta.error}</ErrorMsg>
                        )}
                      </>
                    )}
                  </Field>

                  {values && values.accommodation === "allowance" ? (
                    <>
                      <FormLabel htmlFor="for">
                        Allowance amount <span className="required">*</span>
                      </FormLabel>
                      <Field name="accAllowance" className="formInput" />
                      {errors.accAllowance && touched.accAllowance && (
                        <ErrorMsg className="error">
                          {errors.accAllowance}
                        </ErrorMsg>
                      )}
                    </>
                  ) : null}

                  <FormLabel htmlFor="startDate">
                    Flights/Travel <span className="required">*</span>
                  </FormLabel>

                  <Field name="travel">
                    {({ field, form: { touched, errors }, meta }) => (
                      <>
                        <FormSelect {...field} style={{ width: "100%" }}>
                          <option value="" label="Select travel options... " />
                          <option value="notProvided" label="Not provided" />
                          <option value="provided" label="Provided" />
                          <option value="allowance" label="Allowance" />
                        </FormSelect>
                        {meta.touched && meta.error && (
                          <ErrorMsg className="error">{meta.error}</ErrorMsg>
                        )}
                      </>
                    )}
                  </Field>

                  {values && values.travel === "allowance" ? (
                    <>
                      <FormLabel htmlFor="for">
                        Allowance amount <span className="required">*</span>
                      </FormLabel>
                      <Field name="travelAllowance" className="formInput" />
                      {errors.travelAllowance && touched.travelAllowance && (
                        <ErrorMsg className="error">
                          {errors.travelAllowance}
                        </ErrorMsg>
                      )}
                    </>
                  ) : null}

                  <BtnWrap>
                    <FormButton
                      type="submit"
                      onClick={handleSubmit}
                      className={loading ? "disabled" : null}
                    >
                      Submit
                    </FormButton>
                  </BtnWrap>
                  <FormikErrorFocus
                    offset={0}
                    align={"middle"}
                    focusDelay={100}
                    ease={"out-bounce"}
                    duration={200}
                  />
                </>
              )}
            </Formik>
          </FormSection>
        </FormContent>
      </FormWrap>
      </>
    </Container>
  );
};

export default VacancyForm;
